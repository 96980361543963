import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    getTransferMoneyHistory,
    transferMoney
} from "../../../redux/actions/game/gameActions";
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {getProfileDetails} from "../../../redux/actions/auth/authActions";
import formatDateTime from "../../../helper/DateTimeConvert";

export default function TransferMoney() {
    const dispatch = useDispatch<any>();
    const { getTransferMoneyHistoryData } = useSelector((state: any) => state.game);
    const { userProfileData } = useSelector((state: any) => state.auth);
    const [formData, setFormData] = useState({
        phone: "",
        money: "",
    });
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Check if the input is a valid number and does not exceed 2 digits
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await dispatch(transferMoney(formData));

            if (response && response.status === 200) {
                await Swal.fire("Success", "Transfer Successfully", "success");
                dispatch(getTransferMoneyHistory(currentPage, limit, searchTerm));
                dispatch(getProfileDetails());
            } else {
                toast.error(response.message || "Failed to update settings");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while updating the bet settings.");
        } finally {
            setLoading(false);
        }
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Fetch bet settings data when the component mounts
    useEffect(() => {
        dispatch(getProfileDetails());
        dispatch(getTransferMoneyHistory(currentPage, limit, searchTerm));
    }, [dispatch, currentPage, limit, searchTerm]);

    return (
        <div
            className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">Transfer Money</h1>
                    <h1 className="text-3xl font-bold">Wallet : {userProfileData?.user.wallet}</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="sm:grid sm:grid-cols-2 gap-4">
                        {[
                            {label: "Enter Transfer Number", name: "phone", type: "number"},
                            {label: "Enter Money", name: "money", type: "number"},
                        ].map((input) => (
                            <div key={input.name} className="col-lg-4 mb-2">
                                <label className="">{input.label}</label>
                                <input
                                    type={input.type}
                                    name={input.name}
                                    value={formData[input.name as keyof typeof formData]} // Explicitly typing the key
                                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                    placeholder={input.label}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                        >
                            {loading ? "Transferring..." : "Transfer"}
                        </button>
                    </div>
                </form>
            </div>
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search History</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by phone"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">Order Id</th>
                            <th className="px-4 py-3 border-b">Name</th>
                            <th className="px-4 py-3 border-b">Phone (Transfer To)</th>
                            <th className="px-4 py-3 border-b">Type</th>
                            <th className="px-4 py-3 border-b">Amount</th>
                            <th className="px-4 py-3 border-b">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getTransferMoneyHistoryData === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No admin users found.
                                </td>
                            </tr>
                        ) : (
                            getTransferMoneyHistoryData?.data.map((user: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{user.orderId}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.nameTo}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.phoneTo}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.typeTo}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.amount}</td>
                                    <td className="px-4 py-3 border-b text-center">{formatDateTime(user.createdAt)}</td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={getTransferMoneyHistoryData?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={getTransferMoneyHistoryData?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    );
}
