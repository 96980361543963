import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk'; // Example middleware
import rootReducer from "./reducers/route";

const middleware = [thunk];

// @ts-ignore
const store = createStore(
    rootReducer,
    applyMiddleware(...middleware),
);

export default store;
