import React, {useCallback, useEffect, useState} from "react";
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {toast} from "react-toastify";
import {get_pending_recharge, recharge_update_status} from "../../../api";
import Swal from "sweetalert2";

export default function RechargePending() {
    const [data, setData] = useState<any | null >(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const limit = 15;

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Wrapped getAllRecharge in useCallback to avoid re-creating the function on every render
    const getAllRecharge = useCallback(async () => {
        try {
            const response = await get_pending_recharge(currentPage, limit, searchTerm);
            setData(response); // Assume response contains { data: [...], totalPages: ... }
        } catch (error: any) {
            console.error(error);
            toast.error('Failed to fetch recharge data');
        }
    }, [currentPage, searchTerm]); // Included necessary dependencies in useCallback

    // Fetch recharge data when the component mounts and when searchTerm or currentPage changes
    useEffect(() => {
        const fetchData = async () => {
            await getAllRecharge(); // Ensure the async function is awaited
        };
        fetchData();
    }, [getAllRecharge]); // getAllRecharge is now a stable dependency due to useCallback

    // Function to handle status update
    const handleStatusUpdate = async (id: string, currentStatus: string) => {
        try {
            setLoading(true);
            const response = await recharge_update_status({ id, status: currentStatus });
            console.log(response);
            if (response && response.message === 'Status updated successfully') {
                await Swal.fire('Success', 'Recharge Approved successfully', 'success');
                await getAllRecharge(); // Refresh data after status update
            } else if (response && response.message === 'Rejected successfully') {
                await Swal.fire('Success', 'Recharge Rejected successfully', 'error');
                await getAllRecharge(); // Refresh data after status update
            } else {
                toast.error(response.message || 'Failed to update status');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the user status.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">All Pending Recharge</h1>
                </div>
            </div>
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search Recharges</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by phone, type, UTR, OrderId, Amount"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">OrderId</th>
                            <th className="px-4 py-3 border-b">Phone</th>
                            <th className="px-4 py-3 border-b">UTR</th>
                            <th className="px-4 py-3 border-b">Amount</th>
                            <th className="px-4 py-3 border-b">DollarRate</th>
                            <th className="px-4 py-3 border-b">Type</th>
                            <th className="px-4 py-3 border-b">PayTo</th>
                            <th className="px-4 py-3 border-b">CreateDate</th>
                            <th className="px-4 py-3 border-b">Status</th>
                            <th className="px-4 py-3 border-b">Accept</th>
                            <th className="px-4 py-3 border-b">Reject</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No admin users found.
                                </td>
                            </tr>
                        ) : (
                            data?.data?.map((user: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{user.orderId}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.phone}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.utr}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.amount}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.dollarRate}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.type}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.payTo}</td>
                                    <td className="px-4 py-3 border-b text-center">{new Date(user.createDate).toLocaleString()}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.status}</td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            disabled={loading}
                                            className={`font-semibold rounded-full px-2 text-center text-white bg-green-600 hover:text`}
                                            onClick={() => handleStatusUpdate(user._id, 'Success')}
                                        >
                                            Approve
                                        </button>
                                    </td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            className={`font-semibold rounded-full px-2 text-center text-white bg-red-600 hover:text`}
                                            onClick={() => handleStatusUpdate(user._id, 'Failed')}
                                        >
                                            Reject
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={data?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={data?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    );
}
