// Footer.tsx
import React from 'react';

const Footer: React.FC = () => {
    return (
        <div className="text-center text-xs mt-4">
            <p className="hidden md:block">
                <a href="https://www.instagram.com/apjdevelopment/" target="_blank" rel="noopener noreferrer" className="text-blue-400">APJ Development</a> © 2024 | All Rights Reserved | Designed by <a href="https://www.instagram.com/apjdevelopment/" target="_blank" rel="noopener noreferrer" className="text-blue-400">APJ Development</a> | <a href="mailto:apjdevelopment321@gmail.com" className="text-blue-400">apjdevelopment321@gmail.com</a>
            </p>
            <div className="block md:hidden">
                <p>
                    <a href="https://www.instagram.com/apjdevelopment/" target="_blank" rel="noopener noreferrer" className="text-blue-400">APJ Development</a> © 2024
                    All Rights Reserved
                </p>
                <p className="mt-1">
                    Designed by <a href="https://www.instagram.com/apjdevelopment/" target="_blank" rel="noopener noreferrer" className="text-blue-400">APJ Development</a>
                    <br /> <a href="mailto:apjdevelopment321@gmail.com" className="text-blue-400">apjdevelopment321@gmail.com</a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
