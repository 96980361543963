import { Dispatch } from 'redux';
import {
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    FORGET_PASSWORD_SUCCESS,
    FORGET_PASSWORD_FAILURE,
    VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS,
    VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAILURE,
    SET_FORGET_PASSWORD_SUCCESS,
    SET_FORGET_PASSWORD_FAILURE,
    GET_PROFILE_DETAILS_SUCCESS,
    GET_PROFILE_DETAILS_FAILURE,
    UPDATE_PROFILE_DETAILS_SUCCESS,
    UPDATE_PROFILE_DETAILS_FAILURE,
    GET_LOGIN_ACTIVITY_SUCCESS,
    GET_LOGIN_ACTIVITY_FAILURE,
    LOGOUT_FROM_OTHER_DEVICE_SUCCESS,
    LOGOUT_FROM_OTHER_DEVICE_FAILURE,
    GENERATE_2FA_SECRET_SUCCESS,
    GENERATE_2FA_SECRET_FAILURE,
    GET_USER_TYPE_PERMISSION_SUCCESS,
    GET_USER_TYPE_PERMISSION_FAILURE, SAVE_FCM_TOKEN_SUCCESS, SAVE_FCM_TOKEN_FAILURE,
} from './types';
import {
    apiLoginUser,
    apiForgotPassword,
    apiValidateForgotPasswordToken,
    apiForgotSetPassword,
    apiGetProfileDetails,
    apiUpdateProfileDetails,
    apiChangePassword,
    apiLogout,
    apiGetLoginActivity,
    apiLogoutFromOtherDevice,
    apiGenerate2FASecret,
    apiVerify2FAEnableOtp,
    apiDisable2FA,
    apiGetUserTypePermission, apiSaveFCMToken,
} from '../../../api';
import Cookies from "js-cookie";
import {getLocation} from "../../../helper/getLocationandIp";
import {getAuthToken} from "../../../helper/getAuthToken";

export const loginUser = (data: any) => async (dispatch: Dispatch) => {
    try {
        const location = await getLocation();
        const userData = {...data, location};
        console.log('store', userData);
        const response = await apiLoginUser(userData);
        const {token, tokenType} = response;
        console.log('authToken', token);
        if (tokenType === "Cookie") {
            Cookies.set('authToken', token, { path: '/' });
        } else if (tokenType === "Session") {
            sessionStorage.setItem('authToken', token);
        }
        dispatch({type: LOGIN_SUCCESS, payload: { login: true } });
        return { status: 200, };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: LOGIN_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage, };
    }
};

export const forgotPassword = (data:any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiForgotPassword(data);
        console.log('store', response);
        dispatch({ type: FORGET_PASSWORD_SUCCESS, payload: { success: true } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: FORGET_PASSWORD_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const validateForgotPasswordToken = (data:any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiValidateForgotPasswordToken(data);
        console.log('store', response);
        dispatch({ type: VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS, payload: { success: true } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const setForgotPassword = (data:any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiForgotSetPassword(data);
        console.log('store', response);
        dispatch({ type: SET_FORGET_PASSWORD_SUCCESS, payload: { success: true } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: SET_FORGET_PASSWORD_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getProfileDetails = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetProfileDetails();
        console.log('store', response);
        dispatch({ type: GET_PROFILE_DETAILS_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_PROFILE_DETAILS_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const updateProfileDetails = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiUpdateProfileDetails(data);
        console.log('store', response);
        dispatch({ type: UPDATE_PROFILE_DETAILS_SUCCESS, payload: { success: true } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: UPDATE_PROFILE_DETAILS_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const changePassword = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiChangePassword(data);
        dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: response});
        return { status: 200, data: response };
    } catch (error: any) {
        console.error('Error while changing password:', error);
        const status = error.response ? error.response.status : 500;
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        dispatch({type: CHANGE_PASSWORD_FAILURE, payload: { error: errorMessage }});
        return { status, message: errorMessage };
    }
};

export const logout = () => async (dispatch: Dispatch) => {
    try {
        const token = getAuthToken();
        const response = await apiLogout(token);
        dispatch({type: CHANGE_PASSWORD_SUCCESS, payload: response});
        dispatch({ type: LOGIN_SUCCESS, payload: { login: false } });
        dispatch({ type: LOGIN_FAILURE, payload: { error: 'Session timeout logout' } });
        return { status: 200, data: response };
    } catch (error: any) {
        console.error('Error while Logout:', error);
        const status = error.response ? error.response.status : 500;
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        dispatch({type: CHANGE_PASSWORD_FAILURE, payload: { error: errorMessage }});
        return { status, message: errorMessage };
    }
};

export const getLoginActivity = (page: number, limit: number) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetLoginActivity(page, limit);
        console.log('store', response);
        dispatch({ type: GET_LOGIN_ACTIVITY_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_LOGIN_ACTIVITY_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const logoutFromOtherDevice = () => async (dispatch: Dispatch) => {
    try {
        const token = getAuthToken();
        const response = await apiLogoutFromOtherDevice(token);
        console.log('store', response);
        dispatch({ type: LOGOUT_FROM_OTHER_DEVICE_SUCCESS, payload: { success: true, } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: LOGOUT_FROM_OTHER_DEVICE_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const generate2FASecret = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGenerate2FASecret();
        console.log('store', response);
        dispatch({ type: GENERATE_2FA_SECRET_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GENERATE_2FA_SECRET_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const verify2FAEnableOtp = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiVerify2FAEnableOtp(data);
        console.log('store', response);
        dispatch({ type: GENERATE_2FA_SECRET_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GENERATE_2FA_SECRET_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const disable2FA = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiDisable2FA();
        console.log('store', response);
        dispatch({ type: GENERATE_2FA_SECRET_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GENERATE_2FA_SECRET_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getPermissions = (type: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetUserTypePermission(type);
        console.log('store', response);
        dispatch({ type: GET_USER_TYPE_PERMISSION_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_USER_TYPE_PERMISSION_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const saveFCMToken = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiSaveFCMToken(data);
        // console.log('store', response);
        dispatch({ type: SAVE_FCM_TOKEN_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: SAVE_FCM_TOKEN_FAILURE, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};
