
export const AddAdminUserValidation = (formData: { name: string; phone: string; userType: string, password: string; }): string | null => {
    const { name, phone, userType, password } = formData;

    if (!name) {
        return 'Please enter your Name.';
    }

    if (!phone) {
        return 'Please enter your Phone Number.';
    }

    if (!userType) {
        return 'Please select a User Type.';
    }

    if (password.length < 6) {
        return 'Password must be at least 6 characters long.';
    }

    const passwordComplexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!passwordComplexityRegex.test(password)) {
        return 'Password must include at least one uppercase letter, one number, and one special character. Example: Jhon@123';
    }

    return null;
};
