// user types
export const ADD_USER_TYPES_SUCCESS = 'ADD_USER_TYPES_SUCCESS';
export const ADD_USER_TYPES_FAIL = 'ADD_USER_TYPES_FAIL';
export const GET_USER_TYPES_SUCCESS = 'GET_USER_TYPES_SUCCESS';
export const GET_USER_TYPES_FAIL = 'GET_USER_TYPES_FAIL';
export const GET_ALL_USER_TYPES_SUCCESS = 'GET_ALL_USER_TYPES_SUCCESS';
export const GET_ALL_USER_TYPES_FAIL = 'GET_ALL_USER_TYPES_FAIL';
export const UPDATE_STATUS_USER_TYPES_SUCCESS = 'UPDATE_STATUS_USER_TYPES_SUCCESS';
export const UPDATE_STATUS_USER_TYPES_FAIL = 'UPDATE_STATUS_USER_TYPES_FAIL';
export const SAVE_USER_TYPE_PERMISSION_SUCCESS = 'SAVE_USER_TYPE_PERMISSION_SUCCESS';
export const SAVE_USER_TYPE_PERMISSION_FAIL = 'SAVE_USER_TYPE_PERMISSION_FAIL';
export const GET_ALL_USER_TYPE_PERMISSION_SUCCESS = 'GET_ALL_USER_TYPE_PERMISSION_SUCCESS';
export const GET_ALL_USER_TYPE_PERMISSION_FAIL = 'GET_ALL_USER_TYPE_PERMISSION_FAIL';
export const GET_SPECIFIC_USER_TYPE_PERMISSION_SUCCESS = 'GET_SPECIFIC_USER_TYPE_PERMISSION_SUCCESS';
export const GET_SPECIFIC_USER_TYPE_PERMISSION_FAIL = 'GET_SPECIFIC_USER_TYPE_PERMISSION_FAIL';
export const ADD_NEW_ADMIN_USER_SUCCESS = 'ADD_NEW_ADMIN_USER_SUCCESS';
export const ADD_NEW_ADMIN_USER_FAIL = 'ADD_NEW_ADMIN_USER_FAIL';
export const GET_ALL_ADMIN_USER_SUCCESS = 'GET_ALL_ADMIN_USER_SUCCESS';
export const GET_ALL_ADMIN_USER_FAIL = 'GET_ALL_ADMIN_USER_FAIL';
export const UPDATE_ADMIN_USER_STATUS_SUCCESS = 'UPDATE_ADMIN_USER_STATUS_SUCCESS';
export const UPDATE_ADMIN_USER_STATUS_FAIL = 'UPDATE_ADMIN_USER_STATUS_FAIL';
export const UPDATE_ADMIN_USER_INFO_SUCCESS = 'UPDATE_ADMIN_USER_INFO_SUCCESS';
export const UPDATE_ADMIN_USER_INFO_FAIL = 'UPDATE_ADMIN_USER_INFO_FAIL';
export const GET_ADMIN_USER_INFO_BY_ID_SUCCESS = 'GET_ADMIN_USER_INFO_BY_ID_SUCCESS';
export const GET_ADMIN_USER_INFO_BY_ID_FAIL = 'GET_ADMIN_USER_INFO_BY_ID_FAIL';
export const GET_ADMIN_ACTIVITY_SUCCESS = 'GET_ADMIN_ACTIVITY_SUCCESS';
export const GET_ADMIN_ACTIVITY_FAIL = 'GET_ADMIN_ACTIVITY_FAIL';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAIL = 'GET_DASHBOARD_DATA_FAIL';

//user types
interface AddUserTypesSuccessAction {
    type: typeof ADD_USER_TYPES_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface AddUserTypesFailureAction {
    type: typeof ADD_USER_TYPES_FAIL;
    payload: { error: string };
}

interface GetUserTypesSuccessAction {
    type: typeof GET_USER_TYPES_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetUserTypesFailureAction {
    type: typeof GET_USER_TYPES_FAIL;
    payload: { error: string };
}

interface GetAllUserTypesSuccessAction {
    type: typeof GET_ALL_USER_TYPES_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAllUserTypesFailureAction {
    type: typeof GET_ALL_USER_TYPES_FAIL;
    payload: { error: string };
}

interface UpdateStatusUserTypesSuccessAction {
    type: typeof UPDATE_STATUS_USER_TYPES_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface UpdateStatusUserTypesFailureAction {
    type: typeof UPDATE_STATUS_USER_TYPES_FAIL;
    payload: { error: string };
}

interface SaveUserTypePermissionSuccessAction {
    type: typeof SAVE_USER_TYPE_PERMISSION_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface SaveUserTypePermissionFailureAction {
    type: typeof SAVE_USER_TYPE_PERMISSION_FAIL;
    payload: { error: string };
}

interface GetAllUserTypePermissionSuccessAction {
    type: typeof GET_ALL_USER_TYPE_PERMISSION_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAllUserTypePermissionFailureAction {
    type: typeof GET_ALL_USER_TYPE_PERMISSION_FAIL;
    payload: { error: string };
}

interface GetSpecificUserTypePermissionSuccessAction {
    type: typeof GET_SPECIFIC_USER_TYPE_PERMISSION_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetSpecificUserTypePermissionFailureAction {
    type: typeof GET_SPECIFIC_USER_TYPE_PERMISSION_FAIL;
    payload: { error: string };
}

interface AddAdminNewUserSuccessAction {
    type: typeof ADD_NEW_ADMIN_USER_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface AddAdminNewUserFailureAction {
    type: typeof ADD_NEW_ADMIN_USER_FAIL;
    payload: { error: string };
}

interface GetAllAdminUsersSuccessAction {
    type: typeof GET_ALL_ADMIN_USER_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAllAdminUsersFailureAction {
    type: typeof GET_ALL_ADMIN_USER_FAIL;
    payload: { error: string };
}

interface UpdateStatusAdminUsersSuccessAction {
    type: typeof UPDATE_ADMIN_USER_STATUS_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface UpdateStatusAdminUsersFailureAction {
    type: typeof UPDATE_ADMIN_USER_STATUS_FAIL;
    payload: { error: string };
}

interface UpdateInfoAdminUsersSuccessAction {
    type: typeof UPDATE_ADMIN_USER_INFO_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface UpdateInfoAdminUsersFailureAction {
    type: typeof UPDATE_ADMIN_USER_INFO_FAIL;
    payload: { error: string };
}

interface GetAdminUserInfoByIdSuccessAction {
    type: typeof GET_ADMIN_USER_INFO_BY_ID_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAdminUserInfoByIdFailureAction {
    type: typeof GET_ADMIN_USER_INFO_BY_ID_FAIL;
    payload: { error: string };
}

interface GetAdminUsersActivitySuccessAction {
    type: typeof GET_ADMIN_ACTIVITY_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAdminUsersActivityFailureAction {
    type: typeof GET_ADMIN_ACTIVITY_FAIL;
    payload: { error: string };
}

interface GetDashboardDataSuccessAction {
    type: typeof GET_DASHBOARD_DATA_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetDashboardDataFailureAction {
    type: typeof GET_DASHBOARD_DATA_FAIL;
    payload: { error: string };
}

export type ManageActionTypes =
    | AddUserTypesSuccessAction
    | AddUserTypesFailureAction
    | GetUserTypesSuccessAction
    | GetUserTypesFailureAction
    | GetAllUserTypesSuccessAction
    | GetAllUserTypesFailureAction
    | UpdateStatusUserTypesSuccessAction
    | UpdateStatusUserTypesFailureAction
    | SaveUserTypePermissionSuccessAction
    | SaveUserTypePermissionFailureAction
    | GetAllUserTypePermissionSuccessAction
    | GetAllUserTypePermissionFailureAction
    | GetSpecificUserTypePermissionSuccessAction
    | GetSpecificUserTypePermissionFailureAction
    | AddAdminNewUserSuccessAction
    | AddAdminNewUserFailureAction
    | GetAllAdminUsersSuccessAction
    | GetAllAdminUsersFailureAction
    | UpdateStatusAdminUsersSuccessAction
    | UpdateStatusAdminUsersFailureAction
    | UpdateInfoAdminUsersSuccessAction
    | UpdateInfoAdminUsersFailureAction
    | GetAdminUserInfoByIdSuccessAction
    | GetAdminUserInfoByIdFailureAction
    | GetAdminUsersActivitySuccessAction
    | GetAdminUsersActivityFailureAction
    | GetDashboardDataSuccessAction
    | GetDashboardDataFailureAction
