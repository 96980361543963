import {get, post} from "../../utils";

// console.log('ENV', process.env.REACT_APP_ENV);
// console.log('DEV API', process.env.REACT_APP_DEV_API);
// console.log('PRO API', process.env.REACT_APP_PRO_API);

let API_URL: string;

if (process.env.REACT_APP_ENV === "development") {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}

// console.log('API_URL', API_URL);


export const apiLoginUser = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/auth/login', {
            data
        });
        console.log("user login", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiForgotPassword = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/auth/forgot-password', {
            data
        });
        console.log("forgot data", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiValidateForgotPasswordToken = async ( data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/validate-forgot-password-token', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiForgotSetPassword = async ( data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/forgot-set-password', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiGetProfileDetails = async () => {
    try {
        return await get(API_URL, `/admin/auth/get-profile-details`);
    } catch (error) {
        throw error;
    }
};

export const apiUpdateProfileDetails = async ( data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/update-profile-details', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiChangePassword = async (data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/change-password', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiLogout = async (data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/logout', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiGetLoginActivity = async (page: number, limit: number) => {
    try {
        return await get(API_URL, `/admin/auth/get-login-activity?page=${page}&limit=${limit}`);
    } catch (error) {
        throw error;
    }
};

export const apiLogoutFromOtherDevice = async (data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/logout-all-other-device', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const apiGenerate2FASecret = async () => {
    try {
        return await post(API_URL, '/admin/auth/generate-2fa-secret', {});
    } catch (error) {
        throw error;
    }
};

export const apiVerify2FAEnableOtp = async (data: any) => {
    try {
        console.log('api', data);
        return await post(API_URL, '/admin/auth/verify-2fa-enable-otp', {data});
    } catch (error) {
        throw error;
    }
};

export const apiDisable2FA = async () => {
    try {
        return await post(API_URL, '/admin/auth/disable-2fa', {});
    } catch (error) {
        throw error;
    }
};

export const apiGetUserTypePermission = async (type: string,) => {
    try {
        return await get(API_URL, `/admin/auth/getUserTypePermissions?type=${type}`);
    } catch (error) {
        throw error;
    }
};

export const apiSaveFCMToken = async (data: any,) => {
    try {
        const response = await post(API_URL, '/admin/auth/save-fcm-token', {data});
        console.log('res', response);
        return response;
    } catch (error) {
        throw error;
    }
};
