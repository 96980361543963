import { getAuthToken } from "./getAuthToken";
import { jwtDecode } from "jwt-decode"; // Correct named import for jwt-decode

interface DecodedToken {
    userType?: string;
    [key: string]: any; // Other fields can be present in the token
}

export function findUserTypeFromToken(): string | null {
    const token = getAuthToken();
    if (!token) {
        return null;
    }

    try {
        const decodedToken: DecodedToken = jwtDecode(token);
        return decodedToken.userType || null;
    } catch (error) {
        console.error("Invalid token:", error);
        return null;
    }
}
