import React, { ComponentType, useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { getAuthToken } from '../helper/getAuthToken';
import Cookies from "js-cookie";
import {findUserTypeFromToken} from "../helper/FindUserTypeFromToken";
import NotFound from "./NotFound";
import {useDispatch, useSelector} from "react-redux";
import {getPermissions} from "../redux/actions/auth/authActions";

let API_URL: string;

if (process.env.REACT_APP_ENV === 'development') {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}

const withAuth = (Component: ComponentType<any>, urlId: string) => {
    return (props: any) => {
        const dispatch = useDispatch<any>();
        const { permissionsData } = useSelector((state: any) => state.auth);
        const [isAuthenticated, setIsAuthenticated] = useState<boolean>(true);
        const [hasPermission, setHasPermission] = useState<boolean>(false);

        useEffect(() => {
            const verifyToken = async () => {
                const token = getAuthToken(); // Use utility function to get auth token

                if (token) {
                    try {
                        const userType = findUserTypeFromToken();
                        const url = `${API_URL}admin/auth/validate-login-token`;
                        const response = await fetch(url, {
                            method: 'GET',
                            headers: {
                                'Authorization': `Bearer ${token}`,
                            }
                        });

                        if (response.ok && response.status === 200) {
                            setIsAuthenticated(true);
                            if (userType && userType !== 'Super Admin') {
                                dispatch(getPermissions(userType));
                            } else {
                                setHasPermission(true);
                            }
                        } else {
                            setIsAuthenticated(false);
                            sessionStorage.removeItem('authToken');
                            Cookies.remove('authToken');
                        }
                    } catch (error) {
                        console.error('Error validating token:', error);
                        setIsAuthenticated(false);
                    }
                } else {
                    setIsAuthenticated(false);
                }
            };

            verifyToken();
        }, [dispatch]);

        useEffect(() => {
            const checkPermissions = () => {
                if (permissionsData?.routePermissions) {
                    setHasPermission(permissionsData.routePermissions.includes(urlId));
                }
            };

            checkPermissions();
        }, [permissionsData]);

        if (!isAuthenticated) {
            return <Navigate to="/login" />;
        }

        if (!hasPermission) {
            return <NotFound />;
        }

        return <Component {...props} />;
    };
};

export default withAuth;
