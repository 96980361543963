import React, { useState } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { useNavigate } from 'react-router-dom';

const initialTasks = [
    { id: 1, text: 'Complete project documentation', isCompleted: false, date: new Date(2024, 8, 10) },
    { id: 2, text: 'Review code changes', isCompleted: true, date: new Date(2024, 8, 11) },
    { id: 3, text: 'Attend team meeting', isCompleted: false, date: new Date(2024, 8, 12) },
    { id: 4, text: 'Update project roadmap', isCompleted: true, date: new Date(2024, 8, 13) },
    { id: 5, text: 'Test new features', isCompleted: false, date: new Date(2024, 8, 14) },
];

interface TaskSideBarProps {
    toggleTaskbar: () => void;
}
const TaskSidebar: React.FC<TaskSideBarProps> = ({toggleTaskbar}) => {
    const navigate = useNavigate();
    const [tasks] = useState(initialTasks); // Removed setTasks to resolve ESLint warning
    const [hoveredDate, setHoveredDate] = useState<Date | null>(null);

    const upcomingTasks = tasks.filter(task => !task.isCompleted).slice(0, 5);

    const tileContent = ({ date }: { date: Date }) => {
        const taskOnDate = tasks.find(task => task.date.toDateString() === date.toDateString() && !task.isCompleted);
        return taskOnDate ? <div className="highlight"></div> : null;
    };

    const tileClassName = ({ date, view }: { date: Date, view: string }) => {
        const taskOnDate = tasks.find(task => task.date.toDateString() === date.toDateString() && !task.isCompleted);

        const isToday = date.toDateString() === new Date().toDateString();

        // For month view (showing dates)
        if (view === 'month' && isToday) {
            return 'react-calendar__tile--today';
        }

        // For year view (showing months)
        if (view === 'year' && date.getMonth() === new Date().getMonth() && date.getFullYear() === new Date().getFullYear()) {
            return 'react-calendar__tile--today';
        }

        // For decade view (showing years)
        if (view === 'decade' && date.getFullYear() === new Date().getFullYear()) {
            return 'react-calendar__tile--today';
        }

        // For century view (showing decades)
        if (view === 'century' && date.getFullYear() >= 2021 && date.getFullYear() <= 2030) {
            return 'react-calendar__tile--today';
        }

        return taskOnDate ? 'highlight' : '';
    };

    const getTasksForDate = (date: Date) => {
        return tasks.filter(task => task.date.toDateString() === date.toDateString() && !task.isCompleted);
    };

    const handleDateHover = (date: Date) => {
        setHoveredDate(date);
    };

    return (
        <div className="w-80 bg-white dark:bg-gray-950 shadow-md p-4">
            <div className="flex justify-between items-center mb-4">
                <h2 className="text-xl font-bold">Tasks</h2>
                <button onClick={toggleTaskbar} className="text-blue-500 hover:underline">Close</button>
            </div>
            <div className="mb-6">
                <Calendar
                    tileContent={tileContent}
                    tileClassName={tileClassName}
                    onActiveStartDateChange={({ activeStartDate }) => handleDateHover(activeStartDate!)}
                    className="bg-white dark:bg-gray-950"
                />
                {hoveredDate && (
                    <div className="tooltip">
                        {getTasksForDate(hoveredDate).map(task => (
                            <div key={task.id}>{task.text}</div>
                        ))}
                    </div>
                )}
            </div>
            <div>
                <h3 className="text-lg font-semibold mb-2">Upcoming Tasks</h3>
                <ul className="space-y-2 mb-4">
                    {upcomingTasks.map(task => (
                        <li key={task.id} className="text-sm">{task.text}</li>
                    ))}
                </ul>
                <button onClick={() => navigate('/tasks')} className="block w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 transition duration-300 mb-2">View All</button>
                <button className="block w-full bg-green-500 text-white py-2 rounded-md hover:bg-green-600 transition duration-300">New Event</button>
            </div>
        </div>
    );
};

export default TaskSidebar;
