import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingPage from "./Loading";
import SetPasswordPage from "../app/pages/SetPasswordPage";
import { validateForgotPasswordToken } from "../redux/actions/auth/authActions";
import { useDispatch } from "react-redux";
import Swal from "sweetalert2";

const ProtectedRoute: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [isValidToken, setIsValidToken] = useState<boolean | null>(null);

    useEffect(() => {
        const validateToken = async () => {
            if (!token) {
                navigate('/login');
                setIsValidToken(false);
                return;
            }

            try {
                const data = { token };
                const response = await dispatch(validateForgotPasswordToken(data));
                if (response.status === 200) {
                    setIsValidToken(true);
                } else {
                    setIsValidToken(false);
                }
            } catch (error) {
                setIsValidToken(false);
            }
        };

        validateToken();
    }, [token, navigate, dispatch]);

    useEffect(() => {
        if (isValidToken === false) {
            Swal.fire({
                icon: 'error',
                title: 'Invalid or Expired Link',
                text: 'The reset link is either invalid or has expired. Please request a new one.',
                confirmButtonText: 'Back to Login',
                allowOutsideClick: false,
            }).then(() => {
                navigate('/login');
            });
        }
    }, [isValidToken, navigate]);

    if (isValidToken === null) {
        return <LoadingPage />;
    }

    return <SetPasswordPage />;
};

export default ProtectedRoute;
