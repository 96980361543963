import { combineReducers } from 'redux';
import authReducer from "./auth/authReducer";
import manageReducer from "./manage/manageReducer";
import gameReducer from "./game/gameReducer";

const rootReducer = combineReducers({
    auth: authReducer,
    manage: manageReducer,
    game: gameReducer,
});

export default rootReducer;
