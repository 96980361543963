import React, { useState, useEffect } from 'react';
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {useDispatch, useSelector} from "react-redux";
import {addUserTypes, getUserTypes, updateUserTypes} from "../../../redux/actions/manage/manageActions";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

const AdminUserTypes: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { getUserTypeData } = useSelector((state: any) => state.manage);
    const [formData, setFormData] = useState({ name: '' });
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;

    useEffect(() => {
        dispatch(getUserTypes(currentPage, limit));
    }, [currentPage, dispatch]);

    const handleAddUserType = async () => {
        if (!formData.name) {
            toast.error('Please provide a role name');
            return;
        }

        try {
            const response = await dispatch(addUserTypes(formData));
            if (response.status === 200) {
                await Swal.fire('Success', 'User Type created successfully', 'success');
                dispatch(getUserTypes(currentPage, limit)); // Refresh user types list
            } else {
                toast.error(`${response.message}`);
            }
        } catch (error) {
            toast.error('Failed to add user type');
        }

        setFormData({ name: '' });
    };

    const handlePageChange = async (page: number) => {
        setCurrentPage(page);
    };

    const handleUpdateStatus = async (id: string, status: boolean) => {
        if (!id) {
            toast.error('Invalid user type ID');
            return;
        }

        const data = { id, status };

        try {
            const response = await dispatch(updateUserTypes(data));
            if (response.status === 200) {
                await Swal.fire('Success', 'User Type status updated successfully', 'success');
                dispatch(getUserTypes(currentPage, limit)); // Refresh user types list
            } else {
                toast.error(`${response.message}`);
            }
        } catch (error) {
            toast.error('Failed to update user type status');
        }
    };

    return (
        <div className="container mx-auto p-4 bg-gray-100 dark:bg-gray-950 min-h-screen">
            <h1 className="text-2xl font-bold mb-4">Add User Types</h1>
            <div className="mb-6">
                <label htmlFor="userTypeName" className="block mb-2">Role Name</label>
                <input
                    type="text"
                    id="userTypeName"
                    value={formData.name}
                    maxLength={50}
                    onChange={(e) => setFormData({ name: e.target.value })}
                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full"
                />
                <div className="flex justify-end">
                    <button
                        onClick={handleAddUserType}
                        className="mt-4 font-semibold bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        Add
                    </button>
                </div>
            </div>
            <h2 className="text-xl font-bold mb-4">User Types</h2>
            <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                <thead>
                <tr>
                    <th className="py-2 px-4 border">Sr No.</th>
                    <th className="py-2 px-4 border">Role</th>
                    <th className="py-2 px-4 border">Status</th>
                    <th className="py-2 px-4 border">Action</th>
                </tr>
                </thead>
                <tbody>
                {getUserTypeData?.data.userTypes.map((userType: any, index: number) => (
                    <tr key={index}>
                        <td className="py-2 px-4 border text-center">{((currentPage - 1) * limit) + index + 1}</td>
                        <td className="py-2 px-4 border text-center">{userType.name}</td>
                        <td className={`py-2 px-4 border text-center ${userType.status ? 'text-green-600' : 'text-red-600'}`}>
                            {userType.status ? 'Active' : 'Inactive'}
                        </td>
                        <td className="py-2 px-4 border text-center">
                            <button
                                onClick={() => handleUpdateStatus(userType._id, !userType.status)}
                                className={`text-white px-2 font-semibold text-[14px] rounded-full ${userType.status ? 'bg-red-600' : 'bg-green-600'}`}
                            >
                                {userType.status ? 'Deactivate' : 'Activate'}
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="block sm:hidden">
                <Pagination totalPages={getUserTypeData?.data.totalPages} currentPage={currentPage}
                            onPageChange={handlePageChange}/>
            </div>
            <div className="hidden sm:block">
                <PaginationTwo totalPages={getUserTypeData?.data.totalPages} currentPage={currentPage}
                               onPageChange={handlePageChange}/>
            </div>
        </div>
    );
};

export default AdminUserTypes;
