// apiClient.ts
import axios from 'axios';
import {getAuthToken} from "../helper/getAuthToken";

const createApiClient = (baseURL: string, multipart: boolean) => {
    const instance = axios.create({
        baseURL: baseURL,
        headers: {
            'Content-Type': multipart ? 'multipart/form-data' : 'application/json',
            // Other default headers can be added here
        },
    });


    // Intercept requests to include the token
    instance.interceptors.request.use(
        async config => {
            const token = getAuthToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
            }

            return config;
        },
        error => Promise.reject(error),
    );

    return instance;
};

export const post = async (serviceUrl: string, url: string, data: any) => {
    const apiClient = createApiClient(serviceUrl, false);
    const key = `POST:${url}:${JSON.stringify(data)}`;
    console.log('key', key);
    try {
        const response = await apiClient.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const postFile = async (serviceUrl: string, url: string, data: any) => {
    const apiClient = createApiClient(serviceUrl, true);
    const key = `POST:${url}:${JSON.stringify(data)}`;
    console.log('key', key);
    try {
        const response = await apiClient.post(url, data);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const get = async (serviceUrl: string, url: string) => {
    const apiClient = createApiClient(serviceUrl, false);
    const key = `GET:${url}`;
    console.log('key', key);
    try {
        const response = await apiClient.get(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};

export const del = async (serviceUrl: string, url: string) => {
    const apiClient = createApiClient(serviceUrl, false);
    const key = `DELETE:${url}`;
    console.log('key', key);
    try {
        const response = await apiClient.delete(url);
        return response.data;
    } catch (error) {
        throw error;
    }
};


export default createApiClient;
