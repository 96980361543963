import React, {useEffect, useRef, useState} from 'react';
import { AiOutlineUser } from 'react-icons/ai';
// import { AiOutlineBell, AiOutlineCalendar, AiOutlineQuestionCircle, AiOutlineUser, AiOutlineMessage } from 'react-icons/ai';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import { useDispatch } from "react-redux";
import { logout } from "../../../../redux/actions/auth/authActions";
import { toast } from "react-toastify";
import ThemeToggle from "./ThemeToggle";
import FullScreen from './FullScreen';

interface NavBarProp{
    toggleTaskbar: () => void;
}
const NavBar = ({toggleTaskbar}: NavBarProp) => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<any>();
    // const [notifications, setNotifications] = useState([
    //     { id: 1, text: 'New message from admin', isNew: true },
    //     { id: 2, text: 'Reminder: Complete your profile update', isNew: false },
    //     { id: 3, text: 'Meeting scheduled for tomorrow', isNew: true },
    //     { id: 4, text: 'System maintenance on Sunday', isNew: true },
    //     { id: 5, text: 'Your password will expire soon', isNew: false },
    //     { id: 6, text: 'New policy updates available', isNew: true },
    // ]);
    // const [tasks] = useState([
    //     { id: 1, text: 'Task 1: Complete project documentation' },
    //     { id: 2, text: 'Task 2: Review code changes' },
    //     { id: 3, text: 'Task 3: Attend team meeting' },
    //     { id: 4, text: 'Task 4: Update project roadmap' },
    //     { id: 5, text: 'Task 5: Test new features' },
    // ]);
    // const [chats] = useState([
    //     { id: 1, text: 'Chat with John', isNew: true },
    //     { id: 2, text: 'Chat with Jane', isNew: false },
    //     { id: 3, text: 'Chat with Bob', isNew: true },
    //     { id: 4, text: 'Chat with Alice', isNew: true },
    //     { id: 5, text: 'Chat with Charlie', isNew: false },
    // ]);

    // const [isNotificationOpen, setNotificationOpen] = useState(false);
    const [isProfileOpen, setIsProfileOpen] = useState(false);
    // const [isChatOpen, setChatOpen] = useState(false);

    // const notificationRef = useRef<HTMLDivElement>(null);
    const profileRef = useRef<HTMLDivElement>(null);
    // const chatRef = useRef<HTMLDivElement>(null);

    const handleLogout = async () => {
        try {
            const response = await dispatch(logout());
            if (response.status === 200) {
                sessionStorage.removeItem('authToken');
                Cookies.remove('authToken');
                navigate('/login');
            } else {
                toast.error('Failed to log out. Please try again.');
            }
        } catch (error) {
            console.error('Error during logout:', error);
            toast.error('An error occurred during logout. Please try again.');
        }
    };

    // const markAllAsRead = () => {
    //     const updatedNotifications = notifications.map(notification => ({
    //         ...notification,
    //         isNew: false
    //     }));
    //     setNotifications(updatedNotifications);
    // };

    // const markAsRead = (id: number) => {
    //     const updatedNotifications = notifications.map(notification =>
    //         notification.id === id ? { ...notification, isNew: false } : notification
    //     );
    //     setNotifications(updatedNotifications);
    // };

    const getPageTitle = () => {
        const path = location.pathname.split("/");
        const name = path[path.length - 1].split("-");
        const filterName = name.length >= 3 ? name.filter((n, i) => i > 0) : name;
        return filterName.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    };

    // const handleChatClick = (id: number) => {
    //     navigate(`/chat/${id}`);
    // };
    //
    // const handleQuestionClick = () => {
    //     navigate('/questions');
    // };

    const handleClickOutside = (event: MouseEvent) => {
        // if (notificationRef.current && !notificationRef.current.contains(event.target as Node)) {
        //     setNotificationOpen(false);
        // }
        if (profileRef.current && !profileRef.current.contains(event.target as Node)) {
            setIsProfileOpen(false);
        }
        // if (chatRef.current && !chatRef.current.contains(event.target as Node)) {
        //     setChatOpen(false);
        // }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <nav className="flex justify-between items-center bg-white dark:bg-gray-950 dark:border-b border-gray-600 shadow-md px-4 py-2 transition-all ml-4 fixed sm:relative">
            <h1 className="hidden sm:block text-2xl font-bold text-gray-900 dark:text-white">{getPageTitle()}</h1>
            <div className="flex items-center space-x-4">
                {/* Notifications */}
                {/*<div className="relative" ref={notificationRef}>*/}
                {/*    <div className="relative" onClick={() => setNotificationOpen(!isNotificationOpen)}>*/}
                {/*        <AiOutlineBell className="text-gray-600 dark:text-gray-400 w-6 h-6 cursor-pointer"/>*/}
                {/*        {notifications.some(notification => notification.isNew) && (*/}
                {/*            <span className="absolute -top-1 -right-1 bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-xs text-white">!</span>*/}
                {/*        )}*/}
                {/*    </div>*/}
                {/*    {isNotificationOpen && (*/}
                {/*        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-md rounded-md py-2">*/}
                {/*            <div className="px-4 py-2">*/}
                {/*                <div className="flex justify-between items-center mb-2">*/}
                {/*                    <h2 className="text-sm font-semibold text-gray-900 dark:text-white">Notifications</h2>*/}
                {/*                    <button onClick={markAllAsRead} className="text-xs text-gray-600 dark:text-gray-300 hover:underline">*/}
                {/*                        Mark all as read*/}
                {/*                    </button>*/}
                {/*                </div>*/}
                {/*                {notifications.slice(0, 5).map(notification => (*/}
                {/*                    <div key={notification.id} className="text-sm mb-2 cursor-pointer"*/}
                {/*                         onClick={() => markAsRead(notification.id)}>*/}
                {/*                        {notification.text}*/}
                {/*                        {notification.isNew && <span className="ml-1 bg-blue-500 text-white px-1 rounded-full text-xs">New</span>}*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*/!* Calendar *!/*/}
                {/*<div className="relative">*/}
                {/*    <AiOutlineCalendar className="text-gray-600 dark:text-gray-400 w-6 h-6 cursor-pointer"*/}
                {/*                       onClick={toggleTaskbar}/>*/}
                {/*    {tasks.some(task => task) && (*/}
                {/*        <span className="absolute -top-1 -right-1 bg-yellow-300 rounded-full w-4 h-4 flex items-center justify-center text-xs text-white"></span>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*/!* Messages *!/*/}
                {/*<div className="relative" ref={chatRef}>*/}
                {/*    <AiOutlineMessage className="text-gray-600 dark:text-gray-400 w-6 h-6 cursor-pointer"*/}
                {/*                      onClick={() => setChatOpen(!isChatOpen)}/>*/}
                {/*    {chats.some(chat => chat.isNew) && (*/}
                {/*        <span className="absolute -top-1 -right-1 bg-green-500 rounded-full w-4 h-4 flex items-center justify-center text-xs text-white">!</span>*/}
                {/*    )}*/}
                {/*    {isChatOpen && (*/}
                {/*        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-md rounded-md py-2">*/}
                {/*            <div className="px-4 py-2">*/}
                {/*                <h2 className="text-sm font-semibold text-gray-900 dark:text-white mb-2">Chats</h2>*/}
                {/*                {chats.slice(0, 5).map(chat => (*/}
                {/*                    <div key={chat.id} className="text-sm mb-2 cursor-pointer"*/}
                {/*                         onClick={() => handleChatClick(chat.id)}>*/}
                {/*                        {chat.text}*/}
                {/*                        {chat.isNew && <span className="ml-1 bg-blue-500 text-white px-1 rounded-full text-xs">New</span>}*/}
                {/*                    </div>*/}
                {/*                ))}*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    )}*/}
                {/*</div>*/}
                {/*/!* Questions *!/*/}
                {/*<div className="relative">*/}
                {/*    <AiOutlineQuestionCircle className="text-gray-600 dark:text-gray-400 w-6 h-6 cursor-pointer"*/}
                {/*                             onClick={handleQuestionClick}/>*/}
                {/*</div>*/}
                {/* Theme Toggle */}
                <div className="relative">
                    <ThemeToggle/>
                </div>
                {/* Full Screen Toggle */}
                <div className="relative">
                    <FullScreen/>
                </div>
                {/* User Profile */}
                <div className="relative" ref={profileRef}>
                    <AiOutlineUser className="text-gray-600 dark:text-gray-400 w-6 h-6 cursor-pointer"
                                   onClick={() => setIsProfileOpen(!isProfileOpen)}/>
                    {isProfileOpen && (
                        <div className="absolute right-0 mt-2 w-48 bg-white dark:bg-gray-800 shadow-md rounded-md py-2">
                            <button onClick={() => navigate('/profile-details')}
                                    className="w-full text-left px-4 py-2 text-gray-700 dark:text-gray-300 hover:bg-gray-100 dark:hover:bg-gray-700">
                                Profile Details
                            </button>
                            <button onClick={handleLogout}
                                    className="mx-14 mt-2 px-4 py-2 bg-red-500 text-white rounded-md">
                                Logout
                            </button>
                        </div>
                    )}
                </div>
            </div>
        </nav>
    );
};

export default NavBar;
