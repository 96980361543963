// src/components/common/Modal.tsx

import React, { ReactNode } from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the close icon

interface ModalProps {
    children: ReactNode;
    onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center z-50">
            <div
                className="fixed inset-0 bg-black dark:bg-white opacity-50 z-40"
                onClick={onClose}
            />
            <div className="relative p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg overflow-hidden w-full max-w-lg mx-4 z-50">
                <button
                    onClick={onClose}
                    className="absolute top-2 right-2 hover:text-gray-500"
                    aria-label="Close"
                >
                    <FaTimes size={24} />
                </button>
                <div className="px-4 py-4">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default Modal;
