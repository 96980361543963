import React from 'react';
import { FaUser, FaUsers } from 'react-icons/fa';
import InfoBox from './InfoBox'; // Assuming InfoBox is in the same directory
import { useSelector } from 'react-redux';

const AgentSuperBox = () => {
    const { getDashboardData } = useSelector((state: any) => state.manage);

    // Provide default values if getDashboardData is null or undefined
    const {
        totalPlayersByPinCode = 0,
        todayNewPlayersByPinCode = 0,
    } = getDashboardData?.data || {}; // Use fallback if getDashboardData is null/undefined

    return (
        <div className="flex flex-wrap justify-center bg-gray-100 dark:bg-gray-950 p-6 dark:border-2 border-white rounded-lg shadow-md mt-2">
            <InfoBox icon={FaUsers} label="Total Players" value={totalPlayersByPinCode} />
            <InfoBox icon={FaUser} label="Today Total Players" value={todayNewPlayersByPinCode} />
        </div>
    );
};

export default AgentSuperBox;
