import React, { useState } from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import { AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {useDispatch} from "react-redux";
import {SetPasswordFormValidation} from "../../../helper/validation";
import {toast} from "react-toastify";
import {setForgotPassword} from "../../../redux/actions/auth/authActions";

const SetNewPassword: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const [formData, setFormData] = useState({
        password: '',
        confirmPassword: '',
        token,
    });
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [confirmPasswordVisible, setConfirmPasswordVisible] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleConfirmPasswordVisibility = () => {
        setConfirmPasswordVisible(!confirmPasswordVisible);
    };


    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationMessage = SetPasswordFormValidation(formData);
        if (validationMessage) {
            toast.error(validationMessage);
            return;
        }

        setLoading(true);

        try {
            const response = await dispatch(setForgotPassword(formData));
            console.log("Response", response);

            if (response.status === 200) {
                await Swal.fire('Success', `Password Change Successfully`,'success');
                navigate('/');
            } else {
                toast.error(`${response.message}`);
            }
        } catch (error) {
            console.error('Error submitting set forgot password request:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };


    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-950 p-4">
            <div className="p-8 bg-white dark:bg-gray-950 dark:border-2 border-white shadow-md rounded-md w-full max-w-md">
                        <h2 className="text-3xl font-bold mb-2 text-center">Set New Password</h2>
                        <p className="mb-6 text-center">Enter your new password</p>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="relative flex items-center border-b border-gray-300 py-2 rounded-sm focus-within:ring focus-within:ring-blue-500">
                                <AiOutlineLock className=" mr-3" size={24} />
                                <input
                                    name="password"
                                    type={passwordVisible ? "text" : "password"}
                                    placeholder="New Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="appearance-none bg-transparent border-none w-full py-1 px-2 leading-tight focus:outline-none"
                                />
                                <button type="button" onClick={handlePasswordVisibility} className="absolute right-0 top-0 mt-2 mr-3">
                                    {passwordVisible ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
                                </button>
                            </div>
                            <div className="relative flex items-center border-b border-gray-300 py-2 rounded-sm focus-within:ring focus-within:ring-blue-500">
                                <AiOutlineLock className=" mr-3" size={24} />
                                <input
                                    name="confirmPassword"
                                    type={confirmPasswordVisible ? "text" : "password"}
                                    placeholder="Confirm Password"
                                    value={formData.confirmPassword}
                                    onChange={handleChange}
                                    className="appearance-none bg-transparent border-none w-full py-1 px-2 leading-tight focus:outline-none"
                                />
                                <button type="button" onClick={handleConfirmPasswordVisibility} className="absolute right-0 top-0 mt-2 mr-3">
                                    {confirmPasswordVisible ? <AiOutlineEyeInvisible size={24} /> : <AiOutlineEye size={24} />}
                                </button>
                            </div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-3 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                            >
                                {loading ? 'Processing...' : 'Set Password'}
                            </button>
                        </form>
                <div className="flex mt-4 justify-center">
                    <button
                        onClick={handleBackToLogin}
                        className="mt-2 flex items-center justify-center hover:text-gray-500 transition-colors"
                    >
                        <FiArrowLeft className="mr-2" size={20} />
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SetNewPassword;
