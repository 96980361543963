import React, {useEffect, useState} from 'react';
import { AiOutlineMenu, AiOutlinePoweroff } from 'react-icons/ai';
import { MdClose } from 'react-icons/md';
import { IoIosArrowDropdown, IoIosArrowDropup } from 'react-icons/io';
import { useNavigate, useLocation } from 'react-router-dom';
import Cookies from "js-cookie";
import {useDispatch, useSelector} from "react-redux";
import {getPermissions, logout} from "../../../../redux/actions/auth/authActions";
import {toast} from "react-toastify";
import {MenuItem, menuItems} from "./SideBarData";
import {findUserTypeFromToken} from "../../../../helper/FindUserTypeFromToken";


interface SideBarProps {
    isOpen: boolean;
    toggleSidebar: () => void;
}

const SideBar: React.FC<SideBarProps> = ({ isOpen, toggleSidebar, }) => {
    const [expandedItems, setExpandedItems] = useState<{ [key: string]: boolean }>({});
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch<any>();
    const userType = findUserTypeFromToken();
    const { permissionsData } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if (userType && userType !== 'Super Admin') {
            dispatch(getPermissions(userType));
        }
    }, [dispatch, userType]);

    const handleLogout = async () => {
        try {
            const response = await dispatch(logout());
            if (response.status === 200) {
                sessionStorage.removeItem('authToken');
                Cookies.remove('authToken');
                navigate('/login');
            } else {
                toast.error('Failed to log out. Please try again.');
            }
        } catch (error) {
            console.error('Error during logout:', error);
            toast.error('An error occurred during logout. Please try again.');
        }
    };

    const toggleExpand = (itemName: string) => {
        setExpandedItems(prev => ({ ...prev, [itemName]: !prev[itemName] }));
    };

    const renderMenuItem = (item: MenuItem, isNested: boolean = false) => {
        const isActive = location.pathname === item.path;
        const isExpanded = expandedItems[item.name];

        return (
            <div key={item.name} className={`flex flex-col ${isNested ? 'ml-6' : ''} relative group`}>
                <div
                    className={`flex items-center space-x-4 px-4 py-2 hover:bg-gray-700 cursor-pointer rounded-full ${isActive ? 'bg-gray-700' : ''}`}
                    onClick={() => item.nested ? toggleExpand(item.name) : navigate(item.path)}
                >
                    {item.icon}
                    {isOpen && <span className="font-bold">{item.name}</span>}
                    {item.nested && isOpen && (isExpanded ? <IoIosArrowDropup /> : <IoIosArrowDropdown />)}
                </div>
                {item.nested && item.subItems && (isOpen ? isExpanded : true) && (
                    <>
                        <div className={`flex flex-col ${isOpen && 'bg-gray-800 p-2 mt-2 rounded shadow-lg'}`}>
                            {isOpen && item.subItems.map((subItem: any) => renderMenuItem(subItem, true))}
                        </div>
                        <>
                            {!isOpen && (
                                <div
                                    className="hidden fixed left-16 z-10 bg-gray-500 text-white p-2 rounded shadow-md group-hover:block"
                                    style={{ marginTop: '5px' }}
                                >
                                    <div className="absolute bg-gray-500 w-3 h-3 transform rotate-45  -ml-3"></div>
                                    {
                                        item.subItems.map((subItem: any) => (
                                            <div key={subItem.name}
                                                 className="flex items-center space-x-4 cursor-pointer hover:bg-gray-700 rounded-full p-1"
                                                 onClick={() => navigate(subItem.path)}>
                                                {subItem.icon}
                                                <span className="font-bold">{subItem.name}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            )}
                        </>
                    </>
                )}
                {!isOpen && !item.nested && (
                    <span
                        className="hidden fixed left-20 bg-gray-400 text-white px-2 py-1 rounded shadow-md group-hover:block">{item.name}</span>
                )}
            </div>
        );
    };

    const filterMenuItems = (items: MenuItem[], permissions: string[]) => {
        return items.reduce((acc: MenuItem[], item: MenuItem) => {
            if (permissions.includes(item.id)) {
                if (item.nested && item.subItems) {
                    const filteredSubItems = filterMenuItems(item.subItems, permissions);
                    acc.push({ ...item, subItems: filteredSubItems });
                } else {
                    acc.push(item);
                }
            }
            return acc;
        }, []);
    };

    const filteredMenuItems = userType === 'Super Admin'
        ? menuItems
        : filterMenuItems(menuItems, permissionsData?.permissions || []);

    return (
        <div
            className={`flex flex-col ${isOpen ? 'w-64 min-h-screen h-screen bg-gray-900 fixed' : 'w-10 min-h-12 py-1 bg-gray-950 border-b border-gray-600 sm:bg-gray-900 sm:border-none sm:w-16 sm:min-h-screen sm:h-screen fixed'}  text-white transition-width duration-300 top-0 left-0`}>
            <div className={`flex items-center justify-between ${isOpen ? 'p-4' : 'p-2 sm:p-4'}`}>
                <div className="flex items-center space-x-4">
                    {isOpen ? <MdClose className="w-6 h-6 cursor-pointer" onClick={toggleSidebar} /> :
                        <AiOutlineMenu className="w-6 h-6 cursor-pointer" onClick={toggleSidebar} />}
                    {isOpen && <span className="text-xl font-bold">Menu</span>}
                </div>
            </div>
            <div className={`${!isOpen ? 'sm:flex hidden' : 'flex'} flex-col flex-grow overflow-y-auto relative space-y-4`}>
                {filteredMenuItems.map((item: any) => renderMenuItem(item))}
                <div className="mt-4">
                    <button
                        onClick={handleLogout}
                        className="flex items-center space-x-4 px-4 py-2 hover:bg-gray-700 cursor-pointer w-full rounded-full"
                    >
                        <AiOutlinePoweroff className="w-6 h-6 font-bold" />
                        {isOpen && <span className="font-bold">Logout</span>}
                    </button>
                </div>
                <div className="p-4 mt-auto">
                    {isOpen && (
                        <>
                            <p className="text-center text-xs">
                                <a href="https://www.instagram.com/apjdevelopment/" target="_blank" rel="noopener noreferrer"
                                   className="text-blue-400">APJ Development</a> © 2024
                                <br/>
                                All Rights Reserved
                            </p>
                            <p className="text-center text-xs mt-2">
                                Designed by <a href="https://www.instagram.com/apjdevelopment/" target="_blank"
                                               rel="noopener noreferrer" className="text-blue-400">APJ Development</a>
                                <br/>
                                <a href="mailto:apjdevelopment321@gmail.com"
                                   className="text-blue-400">apjdevelopment321@gmail.com</a>
                            </p>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default SideBar;
