import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    addAdminUser,
    getAllAdminUser,
    getAllUserTypes,
    updateAdminUser,
    updateAdminUserStatus
} from '../../../redux/actions/manage/manageActions';
import { toast } from 'react-toastify';
import { AddAdminUserValidation } from "../../../helper/validation";
import Swal from "sweetalert2";
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import Modal from '../common/PopUp/Modal';
import {getProfileDetails} from "../../../redux/actions/auth/authActions";

const AddAdminUsers: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { getAllUserTypeData, getAllAdminsData } = useSelector((state: any) => state.manage);
    const { userProfileData } = useSelector((state: any) => state.auth);
    const [formData, setFormData] = useState({ name: '', phone: '', userType: '', password: '', address: '', pinCode: '' });
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [updateFormData, setUpdateFormData] = useState({ id: '', name: '', phone: '', userType: '', address: '', pinCode: '' });
    const limit = 15;

    useEffect(() => {
        dispatch(getProfileDetails());
        dispatch(getAllUserTypes());
        dispatch(getAllAdminUser(currentPage, limit, searchTerm));
    }, [currentPage, dispatch, searchTerm]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationMessage = AddAdminUserValidation(formData);
        if (validationMessage) {
            toast.error(validationMessage);
            return;
        }

        setLoading(true);

        try {
            const response = await dispatch(addAdminUser(formData));
            if (response && response.status === 200) {
                await Swal.fire('Success', 'Admin user added successfully', 'success');
                dispatch(getAllAdminUser(currentPage, limit, searchTerm)); // Refresh the admin users list
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while adding the admin user.');
        } finally {
            setLoading(false);
        }
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleStatusUpdate = async (id: string, currentStatus: string) => {
        try {
            const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
            const response = await dispatch(updateAdminUserStatus({ id, status: newStatus }));
            if (response && response.status === 200) {
                await Swal.fire('Success', 'User status updated successfully', 'success');
                dispatch(getAllAdminUser(currentPage, limit, searchTerm)); // Refresh the admin users list
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the user status.');
        }
    };

    const handleUpdateClick = (user: any) => {
        setUpdateFormData({ id: user._id, name: user.name, phone: user.phone, userType: user.userType, address: user.address.completeAddress, pinCode: user.address.pinCode});
        setShowUpdateModal(true);
    };

    const handleUpdateChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        setUpdateFormData({
            ...updateFormData,
            [e.target.name]: e.target.value,
        });
    };

    const handleUpdateSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        try {
            const response = await dispatch(updateAdminUser(updateFormData));
            if (response && response.status === 200) {
                await Swal.fire('Success','Admin user updated successfully', 'success');
                dispatch(getAllAdminUser(currentPage, limit, searchTerm)); // Refresh the admin users list
                setShowUpdateModal(false);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the admin user.');
        }
    };

    const handleInfoClick = (id: string) => {
        window.location.href = `/admin/user/${id}`;
    };

    return (
        <div className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <h1 className="text-3xl font-bold mb-6 text-center">Add New User</h1>
            <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
                    <div className="space-y-2">
                        <label htmlFor="name" className="block text-lg font-medium ">Name</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="email" className="block text-lg font-medium ">Phone</label>
                        <input
                            type="number"
                            id="phone"
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                </div>
                <div className="space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6">
                    <div className="space-y-2">
                        <label htmlFor="userType" className="block text-lg font-medium ">User Type</label>
                        <select
                            id="userType"
                            name="userType"
                            value={formData.userType}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        >
                            <option value="">Select User Type</option>
                            {getAllUserTypeData?.map((userType: any, index: number) => (
                                <option key={index} value={userType.name}>{userType.name}</option>
                            ))}
                        </select>
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="password" className="block text-lg font-medium ">Password</label>
                        <input
                            type="text"
                            id="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="password" className="block text-lg font-medium ">Address</label>
                        <input
                            type="text"
                            id="address"
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                    <div className="space-y-2">
                        <label htmlFor="password" className="block text-lg font-medium ">PinCode</label>
                        <input
                            type="text"
                            id="pinCode"
                            name="pinCode"
                            value={formData.pinCode}
                            onChange={handleChange}
                            className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                            required
                        />
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        type="submit"
                        disabled={loading}
                        className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                    >
                        {loading ? 'Adding...' : 'Add'}
                    </button>
                </div>
            </form>

            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search Users</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by phone, user type"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">Name</th>
                            <th className="px-4 py-3 border-b">Phone</th>
                            <th className="px-4 py-3 border-b">Address</th>
                            <th className="px-4 py-3 border-b">PinCode</th>
                            <th className="px-4 py-3 border-b">Wallet</th>
                            <th className="px-4 py-3 border-b">User Type</th>
                            <th className="px-4 py-3 border-b">Status</th>
                            {userProfileData?.user.userType === 'Super Admin' && (
                                <>
                                    <th className="px-4 py-3 border-b">Action</th>
                                    <th className="px-4 py-3 border-b">Update</th>
                                    <th className="px-4 py-3 border-b">Info</th>
                                </>
                            )}
                        </tr>
                        </thead>
                        <tbody>
                        {getAllAdminsData === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No admin users found.
                                </td>
                            </tr>
                        ) : (
                            getAllAdminsData?.data.map((user: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{user.name}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.phone}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.address.completeAddress}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.address.pinCode}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.wallet}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.userType}</td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <span
                                            className={`font-semibold ${user.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>
                                            {user.status === 'active' ? 'Active' : 'Inactive'}
                                        </span>
                                    </td>
                                    {userProfileData?.user.userType === 'Super Admin' && (
                                        <>
                                            <td className="px-4 py-3 border-b text-center">
                                                <button
                                                    className={`font-semibold rounded-full px-2 text-center text-white ${user.status === 'active' ? 'bg-red-600' : 'bg-green-600'} hover:text`}
                                                    onClick={() => handleStatusUpdate(user._id, user.status)}
                                                >
                                                    {user.status === 'active' ? 'Deactivate' : 'Activate'}
                                                </button>
                                            </td>
                                            <td className="px-4 py-3 border-b text-center">
                                                <button
                                                    className="bg-blue-600 font-semibold rounded-full px-2 text-center text-white hover:text-blue-900"
                                                    onClick={() => handleUpdateClick(user)}
                                                >
                                                    Update
                                                </button>
                                            </td>
                                            <td className="px-4 py-3 border-b text-center">
                                                <button
                                                    className="bg-gray-600 font-semibold rounded-full px-2 text-white hover:text-green-900"
                                                    onClick={() => handleInfoClick(user._id)}
                                                >
                                                    Info
                                                </button>
                                            </td>
                                        </>
                                    )}
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={getAllAdminsData?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={getAllAdminsData?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>

            {/* Update Modal */}
            {showUpdateModal && (
                <Modal onClose={() => setShowUpdateModal(false)}>
                    <form onSubmit={handleUpdateSubmit} className="space-y-6">
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <label htmlFor="updateName" className="block text-lg font-medium ">Name</label>
                                <input
                                    type="text"
                                    id="updateName"
                                    name="name"
                                    value={updateFormData.name}
                                    onChange={handleUpdateChange}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="updateEmail" className="block text-lg font-medium ">Email</label>
                                <input
                                    type="number"
                                    id="updateEmail"
                                    name="phone"
                                    value={updateFormData.phone}
                                    onChange={handleUpdateChange}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="updateUserType" className="block text-lg font-medium ">User Type</label>
                                <select
                                    id="updateUserType"
                                    name="userType"
                                    value={updateFormData.userType}
                                    onChange={handleUpdateChange}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                >
                                    <option value="">Select User Type</option>
                                    {getAllUserTypeData?.map((userType: any, index: number) => (
                                        <option key={index} value={userType.name}>{userType.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="password" className="block text-lg font-medium ">Address</label>
                                <input
                                    type="text"
                                    id="address"
                                    name="address"
                                    value={updateFormData.address}
                                    onChange={handleUpdateChange}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="password" className="block text-lg font-medium ">PinCode</label>
                                <input
                                    type="text"
                                    id="pinCode"
                                    name="pinCode"
                                    value={updateFormData.pinCode}
                                    onChange={handleUpdateChange}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                            >
                                {loading ? "Updating..." : "Update"}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
};

export default AddAdminUsers;
