// user types
export const ADD_BET_SETTING_SUCCESS = 'ADD_BET_SETTING_SUCCESS';
export const ADD_BET_SETTING_FAIL = 'ADD_BET_SETTING_FAIL';
export const GET_ALL_BET_SETTING_SUCCESS = 'GET_ALL_BET_SETTING_SUCCESS';
export const GET_ALL_BET_SETTING_FAIL = 'GET_ALL_BET_SETTING_FAIL';
export const ADD_ALL_GAME_SETTING_SUCCESS = 'ADD_ALL_GAME_SETTING_SUCCESS';
export const ADD_ALL_GAME_SETTING_FAIL = 'ADD_ALL_GAME_SETTING_FAIL';
export const GET_ALL_GAME_SETTING_SUCCESS = 'GET_ALL_GAME_SETTING_SUCCESS';
export const GET_ALL_GAME_SETTING_FAIL = 'GET_ALL_GAME_SETTING_FAIL';
export const TRANSFER_MONEY_SUCCESS = 'TRANSFER_MONEY_SUCCESS';
export const TRANSFER_MONEY_FAIL = 'TRANSFER_MONEY_FAIL';
export const GET_TRANSFER_MONEY_HISTORY_SUCCESS = 'GET_TRANSFER_MONEY_HISTORY_SUCCESS';
export const GET_TRANSFER_MONEY_HISTORY_FAIL = 'GET_TRANSFER_MONEY_HISTORY_FAIL';
export const GET_USER_BY_PIN_CODE_SUCCESS = 'GET_USER_BY_PIN_CODE_SUCCESS';
export const GET_USER_BY_PIN_CODE_FAIL = 'GET_USER_BY_PIN_CODE_FAIL';
export const GET_ALL_USERS_SUCCESS = 'GET_ALL_USERS_SUCCESS';
export const GET_ALL_USERS_FAIL = 'GET_ALL_USERS_FAIL';

interface AddBetSettingSuccessAction {
    type: typeof ADD_BET_SETTING_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface AddBetSettingFailureAction {
    type: typeof ADD_BET_SETTING_FAIL;
    payload: { error: string };
}

interface GetBetSettingSuccessAction {
    type: typeof GET_ALL_BET_SETTING_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetBetSettingFailureAction {
    type: typeof GET_ALL_BET_SETTING_FAIL;
    payload: { error: string };
}

interface AddAllGameSettingSuccessAction {
    type: typeof ADD_ALL_GAME_SETTING_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface AddAllGameSettingFailureAction {
    type: typeof ADD_ALL_GAME_SETTING_FAIL;
    payload: { error: string };
}

interface GetAllGameSettingSuccessAction {
    type: typeof GET_ALL_GAME_SETTING_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAllGameSettingFailureAction {
    type: typeof GET_ALL_GAME_SETTING_FAIL;
    payload: { error: string };
}

interface TransferMoneySuccessAction {
    type: typeof TRANSFER_MONEY_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface TransferMoneyFailureAction {
    type: typeof TRANSFER_MONEY_FAIL;
    payload: { error: string };
}

interface GetTransferMoneyHistorySuccessAction {
    type: typeof GET_TRANSFER_MONEY_HISTORY_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetTransferMoneyHistoryFailureAction {
    type: typeof GET_TRANSFER_MONEY_HISTORY_FAIL;
    payload: { error: string };
}

interface GetUserInfoByPinCodeSuccessAction {
    type: typeof GET_USER_BY_PIN_CODE_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetUserInfoByPinCodeFailureAction {
    type: typeof GET_USER_BY_PIN_CODE_FAIL;
    payload: { error: string };
}

interface GetAllUserInfoSuccessAction {
    type: typeof GET_ALL_USERS_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetAllUserInfoFailureAction {
    type: typeof GET_ALL_USERS_FAIL;
    payload: { error: string };
}

export type GameActionTypes =
    | AddBetSettingSuccessAction
    | AddBetSettingFailureAction
    | GetBetSettingSuccessAction
    | GetBetSettingFailureAction
    | AddAllGameSettingSuccessAction
    | AddAllGameSettingFailureAction
    | GetAllGameSettingSuccessAction
    | GetAllGameSettingFailureAction
    | TransferMoneySuccessAction
    | TransferMoneyFailureAction
    | GetTransferMoneyHistorySuccessAction
    | GetTransferMoneyHistoryFailureAction
    | GetUserInfoByPinCodeSuccessAction
    | GetUserInfoByPinCodeFailureAction
    | GetAllUserInfoSuccessAction
    | GetAllUserInfoFailureAction
