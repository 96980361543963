import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import {
    addAllGameSetting,
    getAllGameSetting
} from "../../../redux/actions/game/gameActions";

// Define a type for the formData keys

export default function AllGameCommonSetting() {
    const dispatch = useDispatch<any>();
    const { getAllGameSettingData } = useSelector((state: any) => state.game);
    const [formData, setFormData] = useState({
        welcomeBonus: "",
        upiId: "",
        upiUserName: "",
        upiMinRec: "",
        upiMaxRec: "",
        ifsc: "",
        bankAccountNumber: "",
        bankName: "",
        bankMinRec: "",
        bankMaxRec: "",
        usdt: "",
        usdtMinRec: "",
        usdtMaxRec: "",
        otpMail: "",
        otpMailType: "",
        otpEMailServiceHost: "",
        otpEmailPass: "",
        otpMailSecure: "",
        otpEmailPort: "",
        dollarRate: "",
        withUpiMin: "",
        withUpiMax: "",
        withBankMin: "",
        withBankMax: "",
        withUsdtMin: "",
        withUsdtMax: "",
        withDrawFee: "",
        dailyWithdrawLimit: "",
        dailyWithdrawTime: "",
        helpEmail: "",
        telegramLink: "",
        level1: "",
        level2: "",
        level3: "",
        betFee: "",
    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await dispatch(addAllGameSetting(formData));

            if (response && response.status === 200) {
                await Swal.fire("Success", "Bet Setting Updated Successfully", "success");
                dispatch(getAllGameSetting()); // Refresh the settings data
            } else {
                toast.error(response.message || "Failed to update settings");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while updating the bet settings.");
        } finally {
            setLoading(false);
        }
    };

    // Load the bet setting data into form on component mount
    useEffect(() => {
        if (getAllGameSettingData && getAllGameSettingData.length > 0) {
            setFormData(getAllGameSettingData[0]);
        }
    }, [getAllGameSettingData]);

    // Fetch bet settings data when the component mounts
    useEffect(() => {
        dispatch(getAllGameSetting());
    }, [dispatch]);

    return (
        <div className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">All Game Setting</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="sm:grid sm:grid-cols-3 gap-4">
                        {[
                            {label: "Welcome Bonus", name: "welcomeBonus", type: "number"},
                            {label: "Game UPI ID", name: "upiId", type: "text"},
                            {label: "Game UPI User Bank Name", name: "upiUserName", type: "text"},
                            {label: "Game UPI Min Rec Amt", name: "upiMinRec", type: "number"},
                            {label: "Game UPI Max Rec Amt", name: "upiMaxRec", type: "number"},
                            {label: "Bank IFSC Code", name: "ifsc", type: "text"},
                            {label: "Bank Account Number", name: "bankAccountNumber", type: "number"},
                            {label: "Game Bank Name", name: "bankName", type: "text"},
                            {label: "Game Bank Min Rec Amt", name: "bankMinRec", type: "number"},
                            {label: "Game Bank Max Rec Amt", name: "bankMaxRec", type: "number"},
                            {label: "Game USDT WALLET NUMBER", name: "usdt", type: "text"},
                            {label: "Game USDT Min Rec Amt", name: "usdtMinRec", type: "number"},
                            {label: "Game Usdt Max Rec Amt", name: "usdtMaxRec", type: "number"},
                        ].map((input) => (
                            <div key={input.name} className="col-lg-4 mb-2">
                                <label className="">{input.label}</label>
                                <input
                                    type={input.type}
                                    name={input.name}
                                    value={formData[input.name as keyof typeof formData]} // Explicitly typing the key
                                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                    placeholder={input.label}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                        <div className="col-lg-4 mb-2">
                            <label className="">OTP Email Type</label>
                            <select
                                onChange={handleChange}
                                name="otpMailType"
                                value={formData.otpMailType}
                                className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                required
                            >
                                <option value="">Select type</option>
                                <option value="Service">Service</option>
                                <option value="Host">Host</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mb-2">
                            <label className="">OTP Email Provider</label>
                            <select
                                onChange={handleChange}
                                name="otpEMailServiceHost"
                                value={formData.otpEMailServiceHost}
                                className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                required
                            >
                                <option value="">Select type</option>
                                <option value="gmail">Gmail</option>
                                <option value="yahoo">yahoo</option>
                                <option value="hotmail">hotmail</option>
                                <option value="icloud">icloud</option>
                                <option value="aol">aol</option>
                                <option value="zoho">zoho</option>
                                <option value="yandex">yandex</option>
                                <option value="mail.ru">mail.ru</option>
                                <option value="protonmail">protonmail</option>
                                <option value="gmx">gmx</option>
                                <option value="Mailgun">Mailgun</option>
                                <option value="SendGrid">SendGrid</option>
                                <option value="Postmark">Postmark</option>
                                <option value="smtp.gmail.com">G Suite (Google Workspace) Host</option>
                                <option value="smtp.office365.com">Microsoft Office 365 Host</option>
                                <option value="smtp.zoho.com">Zoho Mail Host</option>
                                <option value="127.0.0.1">ProtonMail (Bridge required) Host</option>
                                <option value="smtp.mailgun.org">Mailgun Host</option>
                                <option value="smtp.sendgrid.net">SendGrid Host</option>
                                <option value="smtp.postmarkapp.com">Postmark Host</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mb-2">
                            <label className="">OTP Email Port</label>
                            <select
                                onChange={handleChange}
                                name="otpEmailPort"
                                value={formData.otpEmailPort}
                                className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                required
                            >
                                <option value="">Select type</option>
                                <option value="465">465</option>
                                <option value="587">587</option>
                                <option value="1025">1025</option>
                            </select>
                        </div>
                        <div className="col-lg-4 mb-2">
                            <label className="">OTP Email Secure</label>
                            <select
                                onChange={handleChange}
                                name="otpMailSecure"
                                value={formData.otpMailSecure}
                                className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                required
                            >
                                <option value="">Select type</option>
                                <option value="true">True //use SSl</option>
                                <option value="false">False //use TLS</option>
                            </select>
                        </div>
                        {[
                            {label: "OTP Email", name: "otpMail", type: "text"},
                            {label: "OTP EMAIL PASS", name: "otpEmailPass", type: "text"},
                            {label: "Dollar Rate", name: "dollarRate", type: "number"},
                            {label: "WITHDRAW UPI Min", name: "withUpiMin", type: "number"},
                            {label: "WITHDRAW UPI Max", name: "withUpiMax", type: "number"},
                            {label: "WITHDRAW Bank Min", name: "withBankMin", type: "number"},
                            {label: "WITHDRAW Bank Max", name: "withBankMax", type: "number"},
                            {label: "WITHDRAW USDT Min", name: "withUsdtMin", type: "number"},
                            {label: "WITHDRAW USDT Max", name: "withUsdtMax", type: "number"},
                            {label: "WITHDRAW Fee %", name: "withDrawFee", type: "number"},
                            {label: "Daily Withdraw Limit", name: "dailyWithdrawLimit", type: "number"},
                            {label: "Daily Withdraw Time(e.g., 10AM - 8PM)", name: "dailyWithdrawTime", type: "text"},
                            {label: "Help Email", name: "helpEmail", type: "email"},
                            {label: "Telegram Link", name: "telegramLink", type: "text"},
                            {label: "Level 1 Commission %", name: "level1", type: "number"},
                            {label: "Level 2 Commission %", name: "level2", type: "number"},
                            {label: "Level 3 Commission %", name: "level3", type: "number"},
                            {label: "Bet Fee %", name: "betFee", type: "number"},
                        ].map((input) => (
                            <div key={input.name} className="col-lg-4 mb-2">
                                <label className="">{input.label}</label>
                                <input
                                    type={input.type}
                                    name={input.name}
                                    value={formData[input.name as keyof typeof formData]} // Explicitly typing the key
                                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                    placeholder={input.label}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                        >
                            {loading ? "Updating..." : "Update"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
