import React, { createContext, useContext, useEffect, useState } from 'react';
import io, { Socket } from 'socket.io-client';
import { getAuthToken } from '../helper/getAuthToken';

let API_URL: string;

if (process.env.REACT_APP_ENV === "development") {
    API_URL = process.env.REACT_APP_SOCKET_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_SOCKET_PRO_API as string;
}

interface SocketContextProps {
    socket: Socket | null;
}

const SocketContext = createContext<SocketContextProps>({
    socket: null,
});

export const SocketProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const [socket, setSocket] = useState<Socket | null>(null);
    const token = getAuthToken();

    useEffect(() => {
        if (token) {
            const newSocket = io(API_URL, {
                query: { token: token }, // Send the token as userId in query params
            });
            setSocket(newSocket);

            newSocket.on('connect', () => {
                console.log('Connected to server with token:', token);
            });

            return () => {
                newSocket.close();
            };
        }
    }, [token]);

    return (
        <SocketContext.Provider value={{ socket }}>
            {children}
        </SocketContext.Provider>
    );
};

export const useSocket = () => useContext(SocketContext);
