import React, { createContext, useEffect, ReactNode } from 'react';

// Define the shape of your context (in this case, it may be empty if you don't use location data)
interface LocationContextProps {}

// Create a context with an undefined initial value
export const LocationContext = createContext<LocationContextProps | undefined>(undefined);

const getLocationPermission = async (): Promise<void> => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                error => {
                    console.error('Error fetching location or permission denied:', error);
                    resolve(); // Resolve regardless of permission to avoid hanging the promise
                }
            );
        } else {
            console.error('Geolocation not supported by this browser');
            resolve(); // Resolve if geolocation is not supported
        }
    });
};

export const LocationProvider: React.FC<{ children: ReactNode }> = ({ children }) => {

    useEffect(() => {
        // Request location permission on component mount
        getLocationPermission();
    }, []);

    return (
        <LocationContext.Provider value={{ }}>
            {children}
        </LocationContext.Provider>
    );
};
