import React from "react";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineSetting, AiOutlineUserAdd} from "react-icons/ai";
import {IoPersonAddOutline} from "react-icons/io5";
import {TbLayoutGridAdd} from "react-icons/tb";
import {RiUserSettingsLine} from "react-icons/ri";
import {MdHistory, MdOutlinePendingActions} from "react-icons/md";
import {FaMoneyBillWave, FaUsers} from "react-icons/fa";
import {FaMoneyBillTransfer, FaUsersRectangle} from "react-icons/fa6";
import {FcCancel, FcOk} from "react-icons/fc";

export interface MenuItem {
    id: string;
    name: string;
    icon: React.ReactNode;
    path: string;
    nested?: boolean;
    subItems?: MenuItem[];
}

export const menuItems: MenuItem[] = [
    { id: '1', name: 'Dashboard', icon: React.createElement(RxDashboard, { className: "w-6 h-6 font-bold" }), path: '/dashboard', nested: false },
    { id: '2', name: 'Admin Manage', icon: React.createElement(RiUserSettingsLine, { className: "w-6 h-6 font-bold" }), path: '', nested: true ,
        subItems: [
            { id: '2-1', name: 'Add Role', icon: React.createElement(IoPersonAddOutline, { className: "w-6 h-6 font-bold" }), path: '/add-user-type' },
            { id: '2-2', name: 'Add Permission', icon: React.createElement(TbLayoutGridAdd, { className: "w-6 h-6 font-bold" }), path: '/add-role-permission' },
            { id: '2-3', name: 'Add User', icon: React.createElement(AiOutlineUserAdd, { className: "w-6 h-6 font-bold" }), path: '/add-user' },
            { id: '2-4', name: 'Activity', icon: React.createElement(MdHistory, { className: "w-6 h-6 font-bold" }), path: '/admins-activities' },
            { id: '2-5', name: 'Transfer Money', icon: React.createElement(FaMoneyBillTransfer, { className: "w-6 h-6 font-bold" }), path: '/transfer-money' },
        ]
    },
    { id: '3', name: 'Users', icon: React.createElement(FaUsers, { className: "w-6 h-6 font-bold" }), path: '/get-users', nested: false },
    { id: '4', name: 'All Users', icon: React.createElement(FaUsersRectangle, { className: "w-6 h-6 font-bold" }), path: '/get-all-users', nested: false },
    {
        id: '5', name: 'Recharge', icon: React.createElement(FaMoneyBillWave, { className: "w-6 h-6 font-bold" }), path: '', nested: true,
        subItems: [
            { id: '5-1', name: 'Pending Recharge', icon: React.createElement(MdOutlinePendingActions, { className: "w-6 h-6 font-bold" }), path: '/recharge/pending' },
            { id: '5-2', name: 'Approved Recharge', icon: React.createElement(FcOk, { className: "w-6 h-6 font-bold" }), path: '/recharge/accept' },
            { id: '5-3', name: 'Rejected Recharge', icon: React.createElement(FcCancel, { className: "w-6 h-6 font-bold" }), path: '/recharge/reject' },
        ]
    },
    {
        id: '6', name: 'Withdraw', icon: React.createElement(FaMoneyBillTransfer, { className: "w-6 h-6 font-bold" }), path: '', nested: true,
        subItems: [
            { id: '6-1', name: 'Pending Withdraw', icon: React.createElement(MdOutlinePendingActions, { className: "w-6 h-6 font-bold" }), path: '/withdraw/pending' },
            { id: '6-2', name: 'Approved Withdraw', icon: React.createElement(FcOk, { className: "w-6 h-6 font-bold" }), path: '/withdraw/accept' },
            { id: '6-3', name: 'Rejected Withdraw', icon: React.createElement(FcCancel, { className: "w-6 h-6 font-bold" }), path: '/withdraw/reject' },
        ]
    },
    {
        id: '7', name: 'Settings', icon: React.createElement(AiOutlineSetting, { className: "w-6 h-6 font-bold" }), path: '', nested: true,
        subItems: [
            { id: '7-1', name: 'Bet Setting', icon: React.createElement(AiOutlineSetting, { className: "w-6 h-6 font-bold" }), path: '/settings/betSetting' },
            { id: '7-2', name: 'All Game Setting', icon: React.createElement(AiOutlineSetting, { className: "w-6 h-6 font-bold" }), path: '/settings/allGameSetting' },
        ]
    },
];
