import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineUser, AiOutlineLock, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { FiLogIn } from 'react-icons/fi';
import {useDispatch} from "react-redux";
import {loginUser} from "../../../redux/actions/auth/authActions";
import {toast} from "react-toastify";
import {LoginForm2FAValidation, LoginFormValidation} from "../../../helper/validation";

const Login: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [formData, setFormData] = useState({
        phone: '',
        password: '',
        otp: ['', '', '', '', '', ''],
        rememberMe: false,
    });
    const [passwordVisible, setPasswordVisible] = useState<boolean>(false);
    const [showOtpSection, setShowOtpSection] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value, checked, type } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    const handlePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };

    const handleOtpChange = (index: number, value: string) => {
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...formData.otp];
            newOtp[index] = value;
            setFormData({ ...formData, otp: newOtp });

            if (value !== '' && index < formData.otp.length - 1) {
                (document.getElementById(`otp-${index + 1}`) as HTMLInputElement).focus();
            } else if (value === '' && index > 0) {
                (document.getElementById(`otp-${index - 1}`) as HTMLInputElement).focus();
            }
        }
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationMessage = showOtpSection
            ? LoginForm2FAValidation(formData)
            : LoginFormValidation(formData);

        if (validationMessage) {
            toast.error(validationMessage);
            return;
        }

        setLoading(true);

        const { otp } = formData;
        const updatedLoginData = { ...formData, otp: otp.join('') };

        try {
            const response = await dispatch(loginUser(updatedLoginData));
            console.log("Response", response);

            if (response.status === 200) {
                toast.success('Login Successfully');
                navigate('/dashboard');
            } else if (response.message === '2FA Enabled') {
                setShowOtpSection(true);
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error('Error submitting request:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-950 p-4">
            <div className="p-8 bg-white dark:bg-gray-950 dark:border-2 border-white shadow-md rounded-2xl w-full max-w-md">
                {!showOtpSection ? (
                    <>
                        <h1 className='text-4xl font-bold mb-2 text-center'>Welcome,</h1>
                        <h3 className='text-xl font-bold mb-6 text-center'>Sign in to continue!</h3>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div
                                className="relative flex items-center border-b border-gray-300 py-2 rounded-sm focus-within:ring focus-within:ring-blue-500">
                                <AiOutlineUser className=" mr-3" size={24}/>
                                <input
                                    type="number"
                                    name="phone"
                                    placeholder="Phone"
                                    value={formData.phone}
                                    onChange={handleChange}
                                    className="appearance-none bg-transparent border-none w-full py-1 px-2 leading-tight focus:outline-none"
                                />
                            </div>
                            <div
                                className="relative flex items-center border-b border-gray-300 py-2 rounded-sm focus-within:ring focus-within:ring-blue-500">
                                <AiOutlineLock className=" mr-3" size={24}/>
                                <input
                                    type={passwordVisible ? "text" : "password"}
                                    name="password"
                                    placeholder="Password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    className="appearance-none bg-transparent border-none w-full  py-1 px-2 leading-tight focus:outline-none"
                                />
                                <button type="button" onClick={handlePasswordVisibility}
                                        className="absolute right-0 top-0 mt-2 mr-3 ">
                                    {passwordVisible ? <AiOutlineEyeInvisible size={24}/> : <AiOutlineEye size={24}/>}
                                </button>
                            </div>
                            <div className="flex items-center justify-between">
                                <label className="flex items-center">
                                    <input
                                        type="checkbox"
                                        name="rememberMe"
                                        checked={formData.rememberMe}
                                        onChange={handleChange}
                                        className="mr-2"
                                    />
                                    <span className="">Remember Me</span>
                                </label>
                                {/*<a href="/forgot-password" className="text-blue-500 hover:underline">Forgot*/}
                                {/*    Password?</a>*/}
                            </div>
                            <button
                                type="submit"
                                disabled={loading}
                                className="flex items-center justify-center w-full px-3 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                            >
                                <FiLogIn className="mr-2" size={20}/>
                                {loading ? 'Logging...' : 'Login'}
                            </button>
                        </form>
                    </>
                ) : (
                    <>
                        <h2 className="text-3xl font-bold mb-2 text-center text-gray-800 dark:text-white">Authentication Code</h2>
                        <p className="mb-6 text-center text-gray-600 dark:text-white">Please enter your 2FA authentication code</p>
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="flex justify-center space-x-2">
                                {formData.otp.map((digit, index) => (
                                    <input
                                        key={index}
                                        id={`otp-${index}`}
                                        type="text"
                                        value={digit}
                                        onChange={(e) => handleOtpChange(index, e.target.value)}
                                        maxLength={1}
                                        className="w-12 h-12 text-center dark:bg-gray-950 text-xl border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                    />
                                ))}
                            </div>
                            <button
                                type="submit"
                                className="flex items-center justify-center w-full px-3 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                                disabled={loading}
                            >
                                {loading ? 'Logging...' : 'Continue'}
                            </button>
                        </form>
                    </>
                )}
            </div>
        </div>
    );
};

export default Login;
