import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlineMail } from 'react-icons/ai';
import { FiArrowLeft } from 'react-icons/fi';
import {useDispatch} from "react-redux";
import {forgotPassword} from "../../../redux/actions/auth/authActions";
import Swal from "sweetalert2";
import {toast} from "react-toastify";
import {ForgotPasswordFormValidation} from "../../../helper/validation";

const ForgotPassword: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const [formData, setFormData] = useState({
        email: '',
    });
    const [loading, setLoading] = useState<boolean>(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        const validationMessage = ForgotPasswordFormValidation(formData);
        if (validationMessage) {
            toast.error(validationMessage);
            return;
        }

        setLoading(true);

        try {
            const response = await dispatch(forgotPassword(formData));
            console.log("Response", response);

            if (response.status === 200) {
                await Swal.fire('Success', `Password reset link has been sent to ${formData.email}`, 'success');
                navigate('/');
            } else {
                toast.error(`${response.message}`);
            }
        } catch (error) {
            console.error('Error submitting forgot password request:', error);
            toast.error('An error occurred. Please try again.');
        } finally {
            setLoading(false);
        }
    };

    const handleBackToLogin = () => {
        navigate('/login');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-950 p-4">
            <div className="p-8 bg-white dark:bg-gray-950 dark:border-2 border-white shadow-md rounded-md w-full max-w-md">
                <h2 className="text-3xl font-bold mb-2 text-center ">Forgot Password</h2>
                <p className="mb-6 text-center ">Enter your email to receive a password reset link</p>
                <form onSubmit={handleSubmit} className="space-y-6">
                    <div className="relative flex items-center border-b border-gray-300 py-2 rounded-sm focus-within:ring focus-within:ring-blue-500">
                        <AiOutlineMail className=" mr-3" size={24} />
                        <input
                            name='email'
                            type="email"
                            placeholder="Enter your email"
                            value={formData.email}
                            onChange={handleChange}
                            className="appearance-none bg-transparent border-none w-full  py-1 px-2 leading-tight focus:outline-none"
                        />
                    </div>
                    <button
                        type="submit"
                        disabled={loading}
                        className="flex items-center justify-center w-full px-3 py-3 bg-blue-500 text-white rounded-md hover:bg-blue-600 transition-colors"
                    >
                        {loading ? 'Processing...' : 'Reset Password'}
                    </button>
                </form>
                <div className="flex mt-4 justify-center">
                    <button
                        onClick={handleBackToLogin}
                        className="mt-2 flex items-center justify-center hover:text-gray-500 transition-colors"
                    >
                        <FiArrowLeft className="mr-2" size={20} />
                        Back to Login
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
