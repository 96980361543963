// src/Routes/PublicRoute.tsx
import React, {ComponentType, useEffect, useState} from 'react';
import { Navigate } from 'react-router-dom';
import {getAuthToken} from "../helper/getAuthToken";
import Cookies from "js-cookie";

let API_URL: string;

if (process.env.REACT_APP_ENV === 'development') {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}
const PublicRoute = ({ component: Component, ...rest }: { component: ComponentType<any> }) => {
    const [isAuthenticated, setIsAuthenticated] = useState<boolean>(false);

    useEffect(() => {
        const verifyToken = async () => {
            const token = getAuthToken(); // Use utility function to get auth token

            if (token) {
                try {
                    const url = `${API_URL}admin/auth/validate-login-token`;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    if (response.ok && response.status === 200) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        sessionStorage.removeItem('authToken');
                        Cookies.remove('authToken');
                    }
                } catch (error) {
                    console.error('Error validating token:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
        };

        verifyToken();
    }, []);

    if (isAuthenticated) {
        return <Navigate to="/dashboard" />;
    }

    return <Component {...rest} />;
};

export default PublicRoute;
