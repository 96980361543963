//for login page
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

//for forget page
export const FORGET_PASSWORD_SUCCESS = 'FORGET_PASSWORD_SUCCESS';
export const FORGET_PASSWORD_FAILURE = 'FORGET_PASSWORD_FAILURE';
export const VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS = 'VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS';
export const VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE = 'VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE';
export const SET_FORGET_PASSWORD_SUCCESS = 'SET_FORGET_PASSWORD_SUCCESS';
export const SET_FORGET_PASSWORD_FAILURE = 'SET_FORGET_PASSWORD_FAILURE';

//get profile details and update
export const GET_PROFILE_DETAILS_SUCCESS = 'GET_PROFILE_DETAILS_SUCCESS';
export const GET_PROFILE_DETAILS_FAILURE = 'GET_PROFILE_DETAILS_FAILURE';
export const UPDATE_PROFILE_DETAILS_SUCCESS = 'UPDATE_PROFILE_DETAILS_SUCCESS';
export const UPDATE_PROFILE_DETAILS_FAILURE = 'UPDATE_PROFILE_DETAILS_FAILURE';

//for change password and email
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

// logout
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const GET_LOGIN_ACTIVITY_SUCCESS = 'GET_LOGIN_ACTIVITY_SUCCESS';
export const GET_LOGIN_ACTIVITY_FAILURE = 'GET_LOGIN_ACTIVITY_FAILURE';

export const LOGOUT_FROM_OTHER_DEVICE_SUCCESS = 'LOGOUT_FROM_OTHER_DEVICE_SUCCESS';
export const LOGOUT_FROM_OTHER_DEVICE_FAILURE = 'LOGOUT_FROM_OTHER_DEVICE_FAILURE';

//2FA
export const GENERATE_2FA_SECRET_SUCCESS = 'GENERATE_2FA_SECRET_SUCCESS';
export const GENERATE_2FA_SECRET_FAILURE = 'GENERATE_2FA_SECRET_FAILURE';
export const VERIFY_2FA_ENABLE_OTP_SUCCESS = 'VERIFY_2FA_ENABLE_OTP_SUCCESS';
export const VERIFY_2FA_ENABLE_OTP_FAILURE = 'VERIFY_2FA_ENABLE_OTP_FAILURE';
export const DISABLE_2FA_SUCCESS = 'DISABLE_2FA_SUCCESS';
export const DISABLE_2FA_FAILURE = 'DISABLE_2FA_FAILURE';
export const GET_USER_TYPE_PERMISSION_SUCCESS = 'GET_USER_TYPE_PERMISSION_SUCCESS';
export const GET_USER_TYPE_PERMISSION_FAILURE = 'GET_USER_TYPE_PERMISSION_FAILURE';
export const SAVE_FCM_TOKEN_SUCCESS = 'SAVE_FCM_TOKEN_SUCCESS';
export const SAVE_FCM_TOKEN_FAILURE = 'SAVE_FCM_TOKEN_FAILURE';

//login page
interface LoginSuccessAction {
    type: typeof LOGIN_SUCCESS;
    payload: {
        login: boolean | null;
    };
}

interface LoginFailureAction {
    type: typeof LOGIN_FAILURE;
    payload: { error: string };
}

//forget page
interface ForgetPasswordSuccessAction {
    type: typeof FORGET_PASSWORD_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface ForgetPasswordFailureAction {
    type: typeof FORGET_PASSWORD_FAILURE;
    payload: { error: string };
}

interface ValidateForgetPasswordTokenSuccessAction {
    type: typeof VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface ValidateForgetPasswordTokenFailureAction {
    type: typeof VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE;
    payload: { error: string };
}

interface SetForgetPasswordSuccessAction {
    type: typeof SET_FORGET_PASSWORD_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface SetForgetPasswordFailureAction {
    type: typeof SET_FORGET_PASSWORD_FAILURE;
    payload: { error: string };
}

interface GetProfileDetailSuccessAction {
    type: typeof GET_PROFILE_DETAILS_SUCCESS;
    payload: {
        success: boolean;
        data: any[];
    };
}

interface GetProfileDetailFailureAction {
    type: typeof GET_PROFILE_DETAILS_FAILURE;
    payload: { error: string };
}

interface UpdateProfileDetailSuccessAction {
    type: typeof UPDATE_PROFILE_DETAILS_SUCCESS;
    payload: {
        success: boolean;
    };
}

interface UpdateProfileDetailFailureAction {
    type: typeof UPDATE_PROFILE_DETAILS_FAILURE;
    payload: { error: string };
}

//change password
interface ChangePasswordSuccessAction {
    type: typeof CHANGE_PASSWORD_SUCCESS;
    payload: { success: boolean };
}

interface ChangePasswordFailureAction {
    type: typeof CHANGE_PASSWORD_FAILURE;
    payload: { error: string };
}

// logout
interface LogoutSuccessAction {
    type: typeof LOGOUT_SUCCESS;
    payload: { success: boolean };
}

interface LogoutFailureAction {
    type: typeof LOGOUT_FAILURE;
    payload: { error: string };
}

interface GetLoginActivitySuccessAction {
    type: typeof GET_LOGIN_ACTIVITY_SUCCESS;
    payload: {
        success: boolean,
        data: any[]
    };
}

interface GetLoginActivityFailureAction {
    type: typeof GET_LOGIN_ACTIVITY_FAILURE;
    payload: { error: string };
}

interface LogoutFromOtherDeviceSuccessAction {
    type: typeof LOGOUT_FROM_OTHER_DEVICE_SUCCESS;
    payload: { success: boolean };
}

interface LogoutFromOtherDeviceFailureAction {
    type: typeof LOGOUT_FROM_OTHER_DEVICE_FAILURE;
    payload: { error: string };
}

// 2FA

interface Generate2FASecretSuccessAction {
    type: typeof GENERATE_2FA_SECRET_SUCCESS;
    payload: {
        success: boolean,
        data: any[]
    };
}

interface Generate2FASecretFailureAction {
    type: typeof GENERATE_2FA_SECRET_FAILURE;
    payload: { error: string };
}

interface Verify2FAEnableOtpSuccessAction {
    type: typeof VERIFY_2FA_ENABLE_OTP_SUCCESS;
    payload: {
        success: boolean,
    };
}

interface Verify2FAEnableOtpFailureAction {
    type: typeof VERIFY_2FA_ENABLE_OTP_FAILURE;
    payload: { error: string };
}

interface Disable2FASuccessAction {
    type: typeof DISABLE_2FA_SUCCESS;
    payload: {
        success: boolean,
    };
}

interface Disable2FAFailureAction {
    type: typeof DISABLE_2FA_FAILURE;
    payload: { error: string };
}

interface GetUserTypePermissionSuccessAction {
    type: typeof GET_USER_TYPE_PERMISSION_SUCCESS;
    payload: {
        success: boolean,
        data: any[]
    };
}

interface GetUserTypePermissionFailureAction {
    type: typeof GET_USER_TYPE_PERMISSION_FAILURE;
    payload: { error: string };
}

interface SaveFcmTokenSuccessAction {
    type: typeof SAVE_FCM_TOKEN_SUCCESS;
    payload: {
        success: boolean,
        data: any[]
    };
}

interface SaveFcmTokenFailureAction {
    type: typeof SAVE_FCM_TOKEN_FAILURE;
    payload: { error: string };
}

export type AuthActionTypes =
    | LoginSuccessAction
    | LoginFailureAction
    //for forget page
    | ForgetPasswordSuccessAction
    | ForgetPasswordFailureAction
    | ValidateForgetPasswordTokenSuccessAction
    | ValidateForgetPasswordTokenFailureAction
    | SetForgetPasswordSuccessAction
    | SetForgetPasswordFailureAction
    // for profile detail
    | GetProfileDetailSuccessAction
    | GetProfileDetailFailureAction
    | UpdateProfileDetailSuccessAction
    | UpdateProfileDetailFailureAction
    //for change password and email
    | ChangePasswordSuccessAction
    | ChangePasswordFailureAction
    // logout
    | LogoutSuccessAction
    | LogoutFailureAction
    | GetLoginActivitySuccessAction
    | GetLoginActivityFailureAction
    | LogoutFromOtherDeviceSuccessAction
    | LogoutFromOtherDeviceFailureAction
    // 2FA
    | Generate2FASecretSuccessAction
    | Generate2FASecretFailureAction
    | Verify2FAEnableOtpSuccessAction
    | Verify2FAEnableOtpFailureAction
    | Disable2FASuccessAction
    | Disable2FAFailureAction

    | GetUserTypePermissionSuccessAction
    | GetUserTypePermissionFailureAction
    | SaveFcmTokenSuccessAction
    | SaveFcmTokenFailureAction
