import {get, post} from "../../utils";

// console.log('ENV', process.env.REACT_APP_ENV);
// console.log('DEV API', process.env.REACT_APP_DEV_API);
// console.log('PRO API', process.env.REACT_APP_PRO_API);

let API_URL: string;

if (process.env.REACT_APP_ENV === "development") {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}

// console.log('API_URL', API_URL);

export const apiAddUserTypes = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/add-user-types', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetUserTypes = async (page: number, limit: number) => {
    try {
        return await get(API_URL, `/admin/manage/get-user-types?page=${page}&limit=${limit}`);
    } catch (error) {
        throw error;
    }
};

export const apiGetUserTypesList = async () => {
    try {
        return await get(API_URL, `/admin/manage/get-user-types-list`);
    } catch (error) {
        throw error;
    }
};

export const apiUpdateStatusUserTypes = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/update-user-type-status', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiSaveUserTypePermission = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/saveUserTypePermissions', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetAllUserTypesPermission = async (page: number, limit: number) => {
    try {
        return await get(API_URL, `/admin/manage/getAllUserTypePermissions?page=${page}&limit=${limit}`);
    } catch (error) {
        throw error;
    }
};

export const apiAddAdminUser = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/add-user', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetAllAdminUsers = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/manage/get-users?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const apiUpdateStatusAdminUser = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/admin-update-status', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiUpdateAdminUser = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/manage/update-admin-user', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetAdminUserInfoById = async (data: any) => {
    try {
        return await get(API_URL, `/admin/manage/get-admin-user-by-id?id=${data.id}&loginPage=${data.loginPage}&loginLimit=${data.loginLimit}&adminPage=${data.adminPage}&adminLimit=${data.adminLimit}&transactionPage=${data.transactionPage}&transactionLimit=${data.transactionLimit}&getHistoryPage=${data.getHistoryPage}&getHistoryLimit=${data.getHistoryLimit}&getBetHistoryPage=${data.getBetHistoryPage}&getBetHistoryLimit=${data.getBetHistoryLimit}`);
    } catch (error) {
        throw error;
    }
};

export const apiGetAdminsActivity = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/manage/get-admins-activities?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const apiGetDashboardData = async () => {
    try {
        return await get(API_URL, `/admin/manage/get-dashboard-Data`);
    } catch (error) {
        throw error;
    }
};
