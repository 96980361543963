// Function to reverse geocode the coordinates to get a human-readable address
import axios from "axios";
import getRealIp from "./getRealIp";

interface ReverseGeocodeResult {
    country: string;
    state: string;
    city: string;
    pinCode: string;
    complete_display_address: string;
}

const reverseGeocode = async (lat: number, long: number): Promise<ReverseGeocodeResult> => {
    try {
        const response = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`);
        const data = response.data;

        return {
            country: data.address.country || 'Unknown Country',
            state: data.address.state || 'Unknown State',
            city: data.address.city || data.address.state_district || data.address.county || 'Unknown City',
            pinCode: data.address.postcode || `000000`,
            complete_display_address: data.display_name || 'Unknown Location',
        };
    } catch (error) {
        console.error('Error reverse geocoding:', error);
        return {
            country: 'Unknown Country',
            state: 'Unknown State',
            city: 'Unknown City',
            pinCode: `000000`,
            complete_display_address: 'Unknown Location',
        };
    }
};

// Function to get location based on IP address
const getLocationFromIp = async (ip: any): Promise<{ ip: string, lat: number, long: number, address: ReverseGeocodeResult }> => {
    try {
        const response = await axios.get(`https://ipapi.co/${ip}/json/`);
        const data = response.data;
        return {
            ip,
            lat: data.latitude || 0,
            long: data.longitude || 0,
            address: await reverseGeocode(data.latitude || 0, data.longitude || 0),
        };
    } catch (error) {
        console.error('Error fetching location from IP:', error);
        return { ip: 'Unknown IP', lat: 0, long: 0, address: { country: 'Unknown Country', state: 'Unknown State', city: 'Unknown City', pinCode: 'Unknown Pin Code', complete_display_address: 'Unknown Location' } };
    }
};

// Function to get location from geolocation or IP
export const getLocation = async (): Promise<{ip: any, lat: number, long: number, address: any }> => {
    return new Promise((resolve) => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                async position => {
                    const ip = await getRealIp();
                    const lat = position.coords.latitude;
                    const long = position.coords.longitude;
                    const address = await reverseGeocode(lat, long);
                    resolve({ip, lat, long, address });
                },
                async error => {
                    console.error('Error fetching location:', error);
                    const ip = await getRealIp();
                    const locationFromIp = await getLocationFromIp(ip);
                    resolve(locationFromIp);
                }
            );
        } else {
            console.error('Geolocation not supported by this browser');
            const ip = getRealIp();
            const locationFromIp = getLocationFromIp(ip);
            resolve(locationFromIp);        }
    });
};
