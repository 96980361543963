import React, { useState } from 'react';

const chatData = [
    { id: 1, name: 'John Doe', message: 'Hello, I need help with my account.', isNew: true },
    { id: 2, name: 'Jane Smith', message: 'Can you assist with the payment issue?', isNew: false },
    { id: 3, name: 'Bob Johnson', message: 'I have a question about my subscription.', isNew: true },
    { id: 4, name: 'Alice Williams', message: 'How can I upgrade my plan?', isNew: false },
    { id: 5, name: 'Charlie Brown', message: 'Can I change my email address?', isNew: true },
];

const Chat: React.FC = () => {
    const [selectedChat, setSelectedChat] = useState<number | null>(null);

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">Chat</h1>
            <div className="flex space-x-6">
                <div className="w-1/3 bg-white shadow-md rounded-lg p-4">
                    <h2 className="text-xl font-semibold mb-4">Chats</h2>
                    <ul className="space-y-4">
                        {chatData.map(chat => (
                            <li
                                key={chat.id}
                                className={`cursor-pointer p-4 rounded-lg transition duration-300 ${
                                    chat.isNew ? 'bg-blue-50' : 'hover:bg-gray-50'
                                } ${selectedChat === chat.id ? 'bg-gray-200' : ''}`}
                                onClick={() => setSelectedChat(chat.id)}
                            >
                                <h3 className="text-lg font-bold">{chat.name}</h3>
                                <p className="text-gray-600">{chat.message}</p>
                                {chat.isNew && <span className="text-xs text-blue-500">New</span>}
                            </li>
                        ))}
                    </ul>
                </div>
                <div className="flex-grow bg-white shadow-md rounded-lg p-4">
                    {selectedChat ? (
                        <div>
                            <h2 className="text-xl font-semibold mb-4">Chat with {chatData.find(chat => chat.id === selectedChat)?.name}</h2>
                            <div className="space-y-4">
                                {/* Display chat messages here */}
                                <p className="text-gray-600">Message history...</p>
                            </div>
                        </div>
                    ) : (
                        <p className="text-gray-600">Select a chat to view messages</p>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Chat;
