import {useDispatch, useSelector} from "react-redux";
import React, {useEffect, useState} from "react";
import {getAdminsActivities} from "../../../../redux/actions/manage/manageActions";
import Pagination from "../../common/Pagination/pagination";
import PaginationTwo from "../../common/Pagination/paginationDesktop";

const AdminsActivities = () => {
    const dispatch = useDispatch<any>();
    const { getAdminsActivity } = useSelector((state: any) => state.manage);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;

    useEffect(() => {
        dispatch(getAdminsActivities(currentPage, limit, searchTerm));
    }, [currentPage, dispatch, searchTerm]);

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    return (
        <div
            className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search Activity</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by Action , Details"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">Sr No.</th>
                            <th className="px-4 py-3 border-b">Actions</th>
                            <th className="px-4 py-3 border-b">Details</th>
                            <th className="px-4 py-3 border-b">Date</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getAdminsActivity === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No activity found.
                                </td>
                            </tr>
                        ) : (
                            getAdminsActivity?.data.map((activity: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{((currentPage - 1) * limit) + index + 1}</td>
                                    <td className="px-4 py-3 border-b text-center">{activity.action}</td>
                                    <td className="px-4 py-3 border-b text-center">{activity.details}</td>
                                    <td className="px-4 py-3 border-b text-center">{new Date(activity.createdAt).toLocaleString()}</td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={getAdminsActivity?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={getAdminsActivity?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>

        </div>
    )
}

export default AdminsActivities;
