import React from "react";

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

export default function Pagination({ totalPages, currentPage, onPageChange }: PaginationProps) {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <ul className="flex justify-end mt-5">
            <li
                className={`${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""} mx-1`}
                onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
            >
                <button className={`${currentPage === 1 ? "bg-gray-300 text-black" : "bg-blue-600 text-white"} font-semibold py-2 px-3 rounded transition duration-300`}>
                    Previous
                </button>
            </li>
            {currentPage !== 1 &&
                <li className="mx-1" onClick={() => currentPage !== 1 && onPageChange(1)}>
                    <button className="bg-[#e0e0e0] text-[#333333] font-semibold py-2 px-3 rounded transition duration-300">
                        1
                    </button>
                </li>
            }
            <li className="mx-1">
                <button className="bg-blue-600 text-white font-semibold py-2 px-3 rounded transition duration-300">
                    {currentPage}
                </button>
            </li>
            {currentPage !== totalPages &&
                <li className="mx-1" onClick={() => currentPage !== totalPages && onPageChange(totalPages)}>
                    <button className="bg-[#e0e0e0] text-[#333333] font-semibold py-2 px-3 rounded transition duration-300">
                        {totalPages}
                    </button>
                </li>
            }
            <li
                className={`${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""} mx-1`}
                onClick={() => currentPage !== totalPages && onPageChange(currentPage + 1)}
            >
                <button className={`${currentPage === totalPages ? "bg-gray-300 text-black" : "bg-blue-600 text-white"} font-semibold py-2 px-3 rounded transition duration-300`}>
                    Next
                </button>
            </li>
        </ul>
    );
}
