import {
    ADD_NEW_ADMIN_USER_FAIL,
    ADD_NEW_ADMIN_USER_SUCCESS,
    ADD_USER_TYPES_FAIL,
    ADD_USER_TYPES_SUCCESS,
    GET_ADMIN_ACTIVITY_FAIL,
    GET_ADMIN_ACTIVITY_SUCCESS,
    GET_ADMIN_USER_INFO_BY_ID_FAIL,
    GET_ADMIN_USER_INFO_BY_ID_SUCCESS,
    GET_ALL_ADMIN_USER_FAIL,
    GET_ALL_ADMIN_USER_SUCCESS,
    GET_ALL_USER_TYPE_PERMISSION_FAIL,
    GET_ALL_USER_TYPE_PERMISSION_SUCCESS,
    GET_ALL_USER_TYPES_FAIL,
    GET_ALL_USER_TYPES_SUCCESS,
    GET_DASHBOARD_DATA_FAIL,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_SPECIFIC_USER_TYPE_PERMISSION_FAIL,
    GET_SPECIFIC_USER_TYPE_PERMISSION_SUCCESS,
    GET_USER_TYPES_FAIL,
    GET_USER_TYPES_SUCCESS,
    ManageActionTypes,
    SAVE_USER_TYPE_PERMISSION_FAIL,
    SAVE_USER_TYPE_PERMISSION_SUCCESS,
    UPDATE_ADMIN_USER_INFO_FAIL,
    UPDATE_ADMIN_USER_INFO_SUCCESS,
    UPDATE_ADMIN_USER_STATUS_FAIL,
    UPDATE_ADMIN_USER_STATUS_SUCCESS,
    UPDATE_STATUS_USER_TYPES_FAIL,
    UPDATE_STATUS_USER_TYPES_SUCCESS
} from "../../actions/manage/types";

export interface ManageState {
    error: string | null;
    addUserType: boolean | null;
    getUserTypeData: any[] | null;
    getAllUserTypeData: any[] | null;
    updateUserTypeStatus: boolean | null;
    saveUserTypePermission: boolean | null;
    getAllUserTypesPermissionData: any[] | null;
    getSpecificUserTypePermissionData: any[] | null;
    addNewAdmin: boolean | null;
    getAllAdminsData: any[] | null;
    updateAdminStatus: boolean | null;
    updateAdminInfo: boolean | null;
    getAdminInfoByIdData: any[] | null;
    getAdminsActivity: any[] | null;
    getDashboardData: any[] | null;
}

const initialState: ManageState = {
    error: null,
    addUserType: null,
    getUserTypeData: null,
    getAllUserTypeData: null,
    updateUserTypeStatus: null,
    saveUserTypePermission: null,
    getAllUserTypesPermissionData: null,
    getSpecificUserTypePermissionData: null,
    addNewAdmin: null,
    getAllAdminsData: null,
    updateAdminStatus: null,
    updateAdminInfo: null,
    getAdminInfoByIdData: null,
    getAdminsActivity: null,
    getDashboardData: null,
};

const manageReducer = (state = initialState, action: ManageActionTypes): ManageState => {
    switch (action.type) {
        case ADD_USER_TYPES_SUCCESS:
            return {
                ...state,
                addUserType: action.payload.success,
                error: null,
            };
        case ADD_USER_TYPES_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_USER_TYPES_SUCCESS:
            return {
                ...state,
                getUserTypeData: action.payload.data,
                error: null,
            };
        case GET_USER_TYPES_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_USER_TYPES_SUCCESS:
            return {
                ...state,
                getAllUserTypeData: action.payload.data,
                error: null,
            };
        case GET_ALL_USER_TYPES_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case UPDATE_STATUS_USER_TYPES_SUCCESS:
            return {
                ...state,
                updateUserTypeStatus: action.payload.success,
                error: null,
            };
        case UPDATE_STATUS_USER_TYPES_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case SAVE_USER_TYPE_PERMISSION_SUCCESS:
            return {
                ...state,
                saveUserTypePermission: action.payload.success,
                error: null,
            };
        case SAVE_USER_TYPE_PERMISSION_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_USER_TYPE_PERMISSION_SUCCESS:
            return {
                ...state,
                getAllUserTypesPermissionData: action.payload.data,
                error: null,
            };
        case GET_ALL_USER_TYPE_PERMISSION_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_SPECIFIC_USER_TYPE_PERMISSION_SUCCESS:
            return {
                ...state,
                getSpecificUserTypePermissionData: action.payload.data,
                error: null,
            };
        case GET_SPECIFIC_USER_TYPE_PERMISSION_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case ADD_NEW_ADMIN_USER_SUCCESS:
            return {
                ...state,
                addNewAdmin: action.payload.success,
                error: null,
            };
        case ADD_NEW_ADMIN_USER_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_ADMIN_USER_SUCCESS:
            return {
                ...state,
                getAllAdminsData: action.payload.data,
                error: null,
            };
        case GET_ALL_ADMIN_USER_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case UPDATE_ADMIN_USER_STATUS_SUCCESS:
            return {
                ...state,
                updateAdminStatus: action.payload.success,
                error: null,
            };
        case UPDATE_ADMIN_USER_STATUS_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case UPDATE_ADMIN_USER_INFO_SUCCESS:
            return {
                ...state,
                updateAdminInfo: action.payload.success,
                error: null,
            };
        case UPDATE_ADMIN_USER_INFO_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ADMIN_USER_INFO_BY_ID_SUCCESS:
            return {
                ...state,
                getAdminInfoByIdData: action.payload.data,
                error: null,
            };
        case GET_ADMIN_USER_INFO_BY_ID_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ADMIN_ACTIVITY_SUCCESS:
            return {
                ...state,
                getAdminsActivity: action.payload.data,
                error: null,
            };
        case GET_ADMIN_ACTIVITY_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_DASHBOARD_DATA_SUCCESS:
            return {
                ...state,
                getDashboardData: action.payload.data,
                error: null,
            };
        case GET_DASHBOARD_DATA_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default manageReducer;
