import React from 'react';
import { FaUser, FaUsers, FaWallet, FaMoneyBillWave, FaMoneyCheck, FaTrophy, FaTimesCircle } from 'react-icons/fa';
import InfoBox from './InfoBox'; // Assuming InfoBox is in the same directory
import { useSelector } from 'react-redux';

const AdminDetails = () => {
    const { getDashboardData } = useSelector((state: any) => state.manage);

    // Provide default values if getDashboardData is null or undefined
    const {
        totalPlayers = 0,
        totalAgents = 0,
        totalSuper = 0,
        totalUserBalanceAmount = 0,
        totalAgentBalanceAmount = 0,
        totalSuperBalanceAmount = 0,
        todayNewPlayers = 0,
        todayNewAgents = 0,
        todayUserTotalRecharge = 0,
        todayUserTotalWithdraw = 0,
        todayAgentTotalRecharge = 0,
        todayAgentTotalWithdraw = 0,
        todaySuperTotalRecharge = 0,
        todaySuperTotalWithdraw = 0,
        totalWinAmount = 0,
        totalLossAmount = 0,
        todayTotalWinAmount = 0,
        todayTotalLossAmount = 0
    } = getDashboardData?.data || {}; // Use fallback if getDashboardData is null/undefined
    console.log('getDashboardData', getDashboardData);

    return (
        <div className="flex flex-wrap justify-center bg-gray-100 dark:bg-gray-950 p-6 dark:border-2 border-white rounded-lg shadow-md">
            <InfoBox icon={FaUser} label="Total Players" value={totalPlayers} />
            <InfoBox icon={FaUsers} label="Total Agents" value={totalAgents} />
            <InfoBox icon={FaUsers} label="Total Super Users" value={totalSuper} />
            <InfoBox icon={FaWallet} label="Total User Balance" value={`₹ ${totalUserBalanceAmount.toFixed(2)}`} />
            <InfoBox icon={FaWallet} label="Total Agent Balance" value={`₹ ${totalAgentBalanceAmount.toFixed(2)}`} />
            <InfoBox icon={FaWallet} label="Total Super Balance" value={`₹ ${totalSuperBalanceAmount.toFixed(2)}`} />
            <InfoBox icon={FaMoneyBillWave} label="Today New Players" value={todayNewPlayers} />
            <InfoBox icon={FaMoneyBillWave} label="Today New Agents" value={todayNewAgents} />
            <InfoBox icon={FaMoneyCheck} label="Today Users Recharge" value={`₹ ${todayUserTotalRecharge.toFixed(2)}`} />
            <InfoBox icon={FaMoneyCheck} label="Today Users Withdraw" value={`₹ ${todayUserTotalWithdraw.toFixed(2)}`} />
            <InfoBox icon={FaMoneyCheck} label="Today Agents Recharge" value={`₹ ${todayAgentTotalRecharge.toFixed(2)}`} />
            <InfoBox icon={FaMoneyCheck} label="Today Agents Withdraw" value={`₹ ${todayAgentTotalWithdraw.toFixed(2)}`} />
            <InfoBox icon={FaMoneyCheck} label="Today Supers Recharge" value={`₹ ${todaySuperTotalRecharge.toFixed(2)}`} />
            <InfoBox icon={FaMoneyCheck} label="Today Supers Withdraw" value={`₹ ${todaySuperTotalWithdraw.toFixed(2)}`} />
            <InfoBox icon={FaTrophy} label="Total Wins" value={`₹ ${totalWinAmount.toFixed(2)}`} />
            <InfoBox icon={FaTimesCircle} label="Total Losses" value={`₹ ${totalLossAmount.toFixed(2)}`} />
            <InfoBox icon={FaTrophy} label="Today Total Wins" value={`₹ ${todayTotalWinAmount.toFixed(2)}`} />
            <InfoBox icon={FaTimesCircle} label="Today Total Losses" value={`₹ ${todayTotalLossAmount.toFixed(2)}`} />
        </div>
    );
};

export default AdminDetails;
