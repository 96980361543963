import {Dispatch} from "redux";
import {
    ADD_ALL_GAME_SETTING_FAIL,
    ADD_ALL_GAME_SETTING_SUCCESS,
    ADD_BET_SETTING_FAIL,
    ADD_BET_SETTING_SUCCESS,
    GET_ALL_BET_SETTING_FAIL,
    GET_ALL_BET_SETTING_SUCCESS,
    GET_ALL_GAME_SETTING_FAIL,
    GET_ALL_GAME_SETTING_SUCCESS, GET_ALL_USERS_FAIL, GET_ALL_USERS_SUCCESS,
    GET_TRANSFER_MONEY_HISTORY_FAIL,
    GET_TRANSFER_MONEY_HISTORY_SUCCESS,
    GET_USER_BY_PIN_CODE_FAIL,
    GET_USER_BY_PIN_CODE_SUCCESS,
    TRANSFER_MONEY_FAIL,
    TRANSFER_MONEY_SUCCESS
} from "./types";
import {
    apiAddAllGameSetting,
    apiAddBetSetting,
    apiGetAllBetSetting,
    apiGetAllGameSetting, apiGetAllUsers, apiGetTransferMoneyHistory, apiGetUserByPinCode, apiTransferMoney
} from "../../../api";

export const addAllBetSetting = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiAddBetSetting(data);
        console.log('store', response);
        dispatch({ type: ADD_BET_SETTING_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: ADD_BET_SETTING_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllBetSetting = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAllBetSetting();
        console.log('store', response);
        dispatch({ type: GET_ALL_BET_SETTING_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_BET_SETTING_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const addAllGameSetting = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiAddAllGameSetting(data);
        console.log('store', response);
        dispatch({ type: ADD_ALL_GAME_SETTING_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: ADD_ALL_GAME_SETTING_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllGameSetting = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAllGameSetting();
        console.log('store', response);
        dispatch({ type: GET_ALL_GAME_SETTING_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_GAME_SETTING_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const transferMoney = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiTransferMoney(data);
        console.log('store', response);
        dispatch({ type: TRANSFER_MONEY_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: TRANSFER_MONEY_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getTransferMoneyHistory = (page: number, limit: number, search: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetTransferMoneyHistory(page, limit, search);
        console.log('store', response);
        dispatch({ type: GET_TRANSFER_MONEY_HISTORY_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_TRANSFER_MONEY_HISTORY_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getUsersByPinCode = (page: number, limit: number, search: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetUserByPinCode(page, limit, search);
        console.log('store', response);
        dispatch({ type: GET_USER_BY_PIN_CODE_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_USER_BY_PIN_CODE_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllUsers = (page: number, limit: number, search: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAllUsers(page, limit, search);
        console.log('store', response);
        dispatch({ type: GET_ALL_USERS_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_USERS_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};
