import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { addAllBetSetting, getAllBetSetting } from "../../../redux/actions/game/gameActions";

// Define a type for the formData keys
type BetSettingKeys = "zero" | "one" | "two" | "three" | "four" | "five" | "six" | "seven" | "eight" | "nine" | "ace" | "jack" | "queen" | "king" | "col1" | "col2" | "col3" | "col4";

export default function BetAmountSetting() {
    const dispatch = useDispatch<any>();
    const { getBetSettingData } = useSelector((state: any) => state.game);
    const [formData, setFormData] = useState<Record<BetSettingKeys, string>>({
        zero: "",
        one: "",
        two: "",
        three: "",
        four: "",
        five: "",
        six: "",
        seven: "",
        eight: "",
        nine: "",
        ace: "",
        jack: "",
        queen: "",
        king: "",
        col1: "",
        col2: "",
        col3: "",
        col4: "",

    });
    const [loading, setLoading] = useState(false);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        // Check if the input is a valid number and does not exceed 2 digits
        if (value.length <= 2) {
            setFormData((prev) => ({
                ...prev,
                [name]: value,
            }));
        }
    };


    // Handle form submission
    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await dispatch(addAllBetSetting(formData));

            if (response && response.status === 200) {
                await Swal.fire("Success", "Bet Setting Updated Successfully", "success");
                dispatch(getAllBetSetting()); // Refresh the settings data
            } else {
                toast.error(response.message || "Failed to update settings");
            }
        } catch (error) {
            console.error("Error:", error);
            toast.error("An error occurred while updating the bet settings.");
        } finally {
            setLoading(false);
        }
    };

    // Load the bet setting data into form on component mount
    useEffect(() => {
        if (getBetSettingData && getBetSettingData.length > 0) {
            setFormData(getBetSettingData[0]);
        }
    }, [getBetSettingData]);

    // Fetch bet settings data when the component mounts
    useEffect(() => {
        dispatch(getAllBetSetting());
    }, [dispatch]);

    return (
        <div className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">Bet Setting</h1>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="sm:grid sm:grid-cols-3 gap-4">
                        {[
                            {label: "Bet Amount For 0", name: "zero"},
                            {label: "Bet Amount For 1", name: "one"},
                            {label: "Bet Amount For 2", name: "two"},
                            {label: "Bet Amount For 3", name: "three"},
                            {label: "Bet Amount For 4", name: "four"},
                            {label: "Bet Amount For 5", name: "five"},
                            {label: "Bet Amount For 6", name: "six"},
                            {label: "Bet Amount For 7", name: "seven"},
                            {label: "Bet Amount For 8", name: "eight"},
                            {label: "Bet Amount For 9", name: "nine"},
                            {label: "Bet Amount For Ace(A)", name: "ace"},
                            {label: "Bet Amount For J", name: "jack"},
                            {label: "Bet Amount For Q", name: "queen"},
                            {label: "Bet Amount For K", name: "king"},
                            {label: "Bet Amount For Colour 1", name: "col1"},
                            {label: "Bet Amount For Colour 2", name: "col2"},
                            {label: "Bet Amount For Colour 3", name: "col3"},
                            {label: "Bet Amount For Colour 4", name: "col4"},
                        ].map((input) => (
                            <div key={input.name} className="col-lg-4 mb-2">
                                <label className="">{input.label}</label>
                                <input
                                    type="number"
                                    name={input.name}
                                    value={formData[input.name as BetSettingKeys]} // Explicitly typing the key
                                    className="w-full px-4 py-2 border rounded-md dark:bg-gray-950"
                                    placeholder={input.label}
                                    onChange={handleChange}
                                    min={0}
                                    required
                                />
                            </div>
                        ))}
                    </div>
                    <div className="flex justify-end">
                        <button
                            type="submit"
                            disabled={loading}
                            className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                        >
                            {loading ? "Updating..." : "Update"}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}
