import {get, post} from "../../utils";

// console.log('ENV', process.env.REACT_APP_ENV);
// console.log('DEV API', process.env.REACT_APP_DEV_API);
// console.log('PRO API', process.env.REACT_APP_PRO_API);

let API_URL: string;

if (process.env.REACT_APP_ENV === "development") {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}

// console.log('API_URL', API_URL);

export const apiAddBetSetting = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/setting/update-all-bet-setting', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetAllBetSetting = async () => {
    try {
        return await get(API_URL, `/admin/setting/get-all-bet-setting`);
    } catch (error) {
        throw error;
    }
};

export const apiAddAllGameSetting = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/setting/update-all-setting', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetAllGameSetting = async () => {
    try {
        return await get(API_URL, `/admin/setting/get-all-setting`);
    } catch (error) {
        throw error;
    }
};

export const apiTransferMoney = async ( data: any) => {
    try {
        console.log('api', data);
        const response = await post(API_URL, '/admin/setting/transfer-money', {
            data
        });
        console.log("Response", response)
        return response;
    } catch (error) {
        throw error;
    }
};

export const apiGetTransferMoneyHistory = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/transfer-money-history?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const apiGetUserByPinCode = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-users-byPinCode?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const apiGetAllUsers = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-all-users?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const get_pending_recharge = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-pending-recharge?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};


export const get_success_recharge = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-success-recharge?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};


export const get_failed_recharge = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-failed-recharge?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};

export const recharge_update_status = async ( data: any) => {
    try {
        return await post(API_URL, '/admin/setting/update-recharge-status', {
            data
        });
    } catch (error) {
        throw error;
    }
};

export const get_pending_withdraw = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-pending-withdraw?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};


export const get_success_withdraw = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-success-withdraw?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};


export const get_failed_withdraw = async (page: number, limit: number, search: string) => {
    try {
        return await get(API_URL, `/admin/setting/get-failed-withdraw?page=${page}&limit=${limit}&search=${search}`);
    } catch (error) {
        throw error;
    }
};


export const withdraw_update_status = async ( data: any) => {
    try {
        return await post(API_URL, '/admin/setting/update-withdraw-status', {
            data
        });
    } catch (error) {
        throw error;
    }
};