// utils.ts
import Cookies from "js-cookie";

export function getAuthToken(): string | null {
    // Check in localStorage (session storage)
    const tokenFromStorage = sessionStorage.getItem('authToken');
    if (tokenFromStorage) {
        return tokenFromStorage;
    }

    // Check in cookies
    const tokenFromCookie = Cookies.get('authToken');

    return tokenFromCookie || null;
}
