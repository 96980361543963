import validatePhone from "../../PhoneValidation";
import COUNTRY_CODES from "../../../Countrycodes";

export const UpdateProfileDetailValidation = (userData: any) => {
    const { name, countryCode, phone, dob, address } = userData;
    const { country, state, city, pinCode } = address;

    if (!name || name.trim().length < 3) {
        return 'Name must be at least 3 characters long.';
    }

    if (!countryCode || !COUNTRY_CODES.some(code => code.code === countryCode)) {
        return 'Please select a valid country code.';
    }

    if (!phone) {
        return 'Please enter a phone number.';
    }

    if (!validatePhone(phone, countryCode)) {
        return 'Please enter a valid phone number for the selected country.';
    }

    if (!dob) {
        return 'Please enter your date of birth.';
    }
    const date = new Date(dob);
    if (isNaN(date.getTime())) {
        return 'Please enter a valid date of birth.';
    }

    if (!country || country.trim().length < 2) {
        return 'Please enter a valid country.';
    }

    if (!state || state.trim().length < 2) {
        return 'Please enter a valid state.';
    }

    if (!city || city.trim().length < 2) {
        return 'Please enter a valid city.';
    }

    if (!pinCode || !/^\d{5,6}$/.test(pinCode)) {
        return 'Please enter a valid pin code.';
    }

    return null;
};

export const ChangePasswordValidation = (passwordData: { oldPassword: string; newPassword: string; confirmPassword: string; }): string | null => {
    const { oldPassword, newPassword, confirmPassword } = passwordData;

    if (!oldPassword) {
        return 'Please enter your old password.';
    }

    if (!newPassword) {
        return 'Please enter a new password.';
    }

    if (newPassword.length < 6) {
        return 'New password must be at least 6 characters long.';
    }

    if (newPassword !== confirmPassword) {
        return 'New password and confirmation do not match.';
    }

    // Optional: Check password complexity
    const passwordComplexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!passwordComplexityRegex.test(newPassword)) {
        return 'Password must include at least one uppercase letter, one number, and one special character. Example: Jhon@123';
    }

    if (oldPassword === newPassword) {
        return 'New password must be different from the old password.';
    }

    return null;
};
