import React from 'react';

const LoadingPage: React.FC = () => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-transparent">
            <div className="flex flex-col justify-center items-center p-8 text-center">
                <div className="loader mb-4"></div>
                <h2 className="text-3xl font-bold text-gray-800 dark:text-white">Loading...</h2>
            </div>
        </div>
    );
};

export default LoadingPage;
