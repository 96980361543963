
export const LoginFormValidation = (formData: { phone: string, password: string }): string | null => {
    if (!formData.phone) {
        return 'Please Enter Phone.';
    }

    if (!formData.password) {
        return 'Please Enter Password.';
    }

    return null;
}

export const LoginForm2FAValidation = (formData: { phone: string, password: string, otp: any }): string | null => {
    if (!formData.phone) {
        return 'Please Enter Phone.';
    }

    if (!formData.password) {
        return 'Please Enter Password.';
    }

    if (!formData.otp) {
        return 'Please Enter Password.';
    }

    if (formData.otp.join('').length !== 6) {
        return 'Please enter a valid Code.';
    }

    return null;
}
