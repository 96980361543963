import React, {useEffect, useState} from 'react';
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {useDispatch, useSelector} from "react-redux";
import {getLoginActivity, logoutFromOtherDevice} from "../../../redux/actions/auth/authActions";
import {toast} from "react-toastify";
import Swal from "sweetalert2";


const LoginActivity: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { loginActivityData } = useSelector((state: any) => state.auth);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;
    const [detailsVisible, setDetailsVisible] = useState<{ [key: string]: boolean }>({});

    const startIndex = (currentPage - 1 )* limit;
    useEffect(() => {
        dispatch(getLoginActivity(currentPage, limit));
    }, [currentPage, dispatch]);

    // Function to handle page change
    const handlePageChange = async (page: number) => {
        setCurrentPage(page);
    };

    const toggleDetails = (id: string) => {
        setDetailsVisible((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const formatDateTime = (dateString: string) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    const handleLogoutFromAllDevices = async () => {
        try {
            const response = await dispatch(logoutFromOtherDevice());
            await dispatch(getLoginActivity(currentPage, limit));
            if (response.status === 200) {
                await Swal.fire('Success', 'Logged out from all other devices', 'success');
            }
        } catch (error) {
            console.error('Error logging out from other devices:', error);
            toast.error('Failed to logout from other devices');
        }
    };

    return (
        <div className="container mx-auto p-4 bg-gray-100 dark:bg-gray-950 min-h-screen">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-semibold">Login Activity</h1>
                <button onClick={handleLogoutFromAllDevices} className="bg-red-500 text-white py-2 px-4 rounded">
                    Logout from all other devices
                </button>
            </div>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-950 shadow-md rounded text-center">
                    <thead>
                    <tr>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border border-gray-200 ">Sr No.</th>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border border-gray-200 ">Sign in</th>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border border-gray-200 ">Date</th>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border border-gray-200 ">Location</th>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border border-gray-200 ">Status</th>
                    </tr>
                    </thead>
                    <tbody>
                    {loginActivityData?.loginData.map((activity: any, index: number) => (
                        <React.Fragment key={index}>
                            <tr>
                                <td className="py-3 px-6 border border-gray-200">{startIndex + index + 1}</td>
                                <td className="py-3 px-6 border border-gray-200">
                                    <div className="flex flex-col md:flex-row gap-4">
                                        Sign in successfully on {activity.device}
                                        <button onClick={() => toggleDetails(activity._id)}
                                                className="text-blue-600 underline">
                                            {detailsVisible[activity._id] ? 'Hide details' : 'Show details'}
                                        </button>
                                    </div>
                                </td>
                                <td className="py-3 px-6 border border-gray-200">{formatDateTime(activity.createdAt)}</td>
                                <td className="py-3 px-6 border border-gray-200">Near {activity.address.city}, {activity.address.state} {activity.address.country}, {activity.address.pinCode}</td>
                                <td className={`py-3 px-6 border border-gray-200 ${activity.status ? 'text-green-500' : 'text-red-600'}`}>
                                    {activity.status ? 'Active' : 'Inactive'}
                                </td>
                            </tr>
                            {detailsVisible[activity._id] && (
                                <tr>
                                    <td colSpan={5}
                                        className=" justify-between py-3 px-6 border border-gray-200 bg-gray-100 dark:bg-gray-950">
                                        <div className="flex justify-between px-8">
                                            <p>IP Address: {activity.ipAddress.replace(/\.\d+$/, '.***')}</p>
                                            <p>Device: {activity.device}</p>
                                            <p>Browser: {activity.browser}</p>
                                        </div>
                                    </td>
                                </tr>
                            )}
                        </React.Fragment>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="block sm:hidden">
                <Pagination totalPages={loginActivityData?.totalPages} currentPage={currentPage}
                            onPageChange={handlePageChange}/>
            </div>
            <div className="hidden sm:block">
                <PaginationTwo totalPages={loginActivityData?.totalPages} currentPage={currentPage}
                               onPageChange={handlePageChange}/>
            </div>
        </div>
    );
};

export default LoginActivity;
