import React, { useEffect } from 'react';

const SecurityProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    useEffect(() => {
        if (process.env.REACT_APP_ENV === 'production') {
            // Disable console methods
            console.log = () => {};
            console.warn = () => {};
            console.error = () => {};
            console.info = () => {};
            console.debug = () => {};

            // Disable context menu
            const disableContextMenu = (event: MouseEvent) => {
                event.preventDefault();
            };
            window.addEventListener('contextmenu', disableContextMenu);

            // Disable specific keyboard shortcuts
            const disableShortcuts = (event: KeyboardEvent) => {
                if (
                    event.key === 'F12' ||
                    (event.ctrlKey && event.shiftKey && event.key === 'I') ||
                    (event.ctrlKey && event.shiftKey && event.key === 'J') ||
                    (event.ctrlKey && event.shiftKey && event.key === 'C') ||
                    (event.ctrlKey && event.key === 'U')
                ) {
                    event.preventDefault();
                }
            };
            window.addEventListener('keydown', disableShortcuts);

            // Cleanup functions for removing event listeners
            return () => {
                window.removeEventListener('contextmenu', disableContextMenu);
                window.removeEventListener('keydown', disableShortcuts);
            };
        }
    }, []);

    return <>{children}</>;
};

export default SecurityProvider;
