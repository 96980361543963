import {
    AuthActionTypes,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    DISABLE_2FA_FAILURE,
    DISABLE_2FA_SUCCESS,
    FORGET_PASSWORD_FAILURE,
    FORGET_PASSWORD_SUCCESS,
    GENERATE_2FA_SECRET_FAILURE,
    GENERATE_2FA_SECRET_SUCCESS,
    GET_LOGIN_ACTIVITY_FAILURE,
    GET_LOGIN_ACTIVITY_SUCCESS,
    GET_PROFILE_DETAILS_FAILURE,
    GET_PROFILE_DETAILS_SUCCESS,
    GET_USER_TYPE_PERMISSION_FAILURE,
    GET_USER_TYPE_PERMISSION_SUCCESS,
    LOGIN_FAILURE,
    LOGIN_SUCCESS,
    LOGOUT_FAILURE,
    LOGOUT_FROM_OTHER_DEVICE_FAILURE,
    LOGOUT_FROM_OTHER_DEVICE_SUCCESS,
    LOGOUT_SUCCESS,
    SAVE_FCM_TOKEN_FAILURE,
    SAVE_FCM_TOKEN_SUCCESS,
    SET_FORGET_PASSWORD_FAILURE,
    SET_FORGET_PASSWORD_SUCCESS,
    UPDATE_PROFILE_DETAILS_FAILURE,
    UPDATE_PROFILE_DETAILS_SUCCESS,
    VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE,
    VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS,
    VERIFY_2FA_ENABLE_OTP_FAILURE,
    VERIFY_2FA_ENABLE_OTP_SUCCESS,
} from '../../actions/auth/types';

export interface AuthState {
    error: string | null;
    login: boolean | null;
    forgetPassword: boolean | null;
    forgetTokenValidate: boolean | null;
    setForgetPassword: boolean | null;
    userProfileData: any[] | null;
    updateUserProfile: boolean | null;
    passwordChanged: boolean | null;
    logout: boolean | null;
    loginActivityData: any[] | null;
    logoutAllOtherDevice: boolean | null;
    generate2FASecretData: any[] | null;
    verify2FAEnableOtpData: boolean | null;
    disable2FA: boolean | null;
    permissionsData: any[] | null;
    saveFCMToken: boolean | null;
}

const initialState: AuthState = {
    error: null,
    login: null,
    forgetPassword: null,
    forgetTokenValidate: null,
    setForgetPassword: null,
    userProfileData: null,
    updateUserProfile: null,
    passwordChanged: null,
    logout: null,
    loginActivityData: null,
    logoutAllOtherDevice: null,
    generate2FASecretData: null,
    verify2FAEnableOtpData: null,
    disable2FA: null,
    permissionsData: null,
    saveFCMToken: null,
};


const authReducer = (state = initialState, action: AuthActionTypes): AuthState => {
    switch (action.type) {
        case LOGIN_SUCCESS:
            return {
                ...state,
                login: action.payload.login,
                error: null,
            };
        case LOGIN_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                forgetPassword: action.payload.success,
                error: null,
            };
        case FORGET_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case VALIDATE_FORGET_PASSWORD_TOKEN_SUCCESS:
            return {
                ...state,
                forgetTokenValidate: action.payload.success,
                error: null,
            };
        case VALIDATE_FORGET_PASSWORD_TOKEN_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case SET_FORGET_PASSWORD_SUCCESS:
            return {
                ...state,
                setForgetPassword: action.payload.success,
                error: null,
            };
        case SET_FORGET_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                userProfileData: action.payload.data,
                error: null,
            };
        case GET_PROFILE_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case UPDATE_PROFILE_DETAILS_SUCCESS:
            return {
                ...state,
                updateUserProfile: action.payload.success,
                error: null,
            };
        case UPDATE_PROFILE_DETAILS_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                passwordChanged: action.payload.success,
                error: null
            };
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case LOGOUT_SUCCESS:
            return {
                ...state,
                logout: action.payload.success,
                error: null
            };
        case LOGOUT_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_LOGIN_ACTIVITY_SUCCESS:
            return {
                ...state,
                loginActivityData: action.payload.data,
                error: null
            };
        case GET_LOGIN_ACTIVITY_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case LOGOUT_FROM_OTHER_DEVICE_SUCCESS:
            return {
                ...state,
                logoutAllOtherDevice: action.payload.success,
                error: null
            };
        case LOGOUT_FROM_OTHER_DEVICE_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case GENERATE_2FA_SECRET_SUCCESS:
            return {
                ...state,
                generate2FASecretData: action.payload.data,
                error: null
            };
        case GENERATE_2FA_SECRET_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case VERIFY_2FA_ENABLE_OTP_SUCCESS:
            return {
                ...state,
                verify2FAEnableOtpData: action.payload.success,
                error: null
            };
        case VERIFY_2FA_ENABLE_OTP_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case DISABLE_2FA_SUCCESS:
            return {
                ...state,
                disable2FA: action.payload.success,
                error: null
            };
        case DISABLE_2FA_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_USER_TYPE_PERMISSION_SUCCESS:
            return {
                ...state,
                permissionsData: action.payload.data,
                error: null
            };
        case GET_USER_TYPE_PERMISSION_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case SAVE_FCM_TOKEN_SUCCESS:
            return {
                ...state,
                saveFCMToken: action.payload.success,
                error: null
            };
        case SAVE_FCM_TOKEN_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default authReducer;
