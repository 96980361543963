import {
    ADD_ALL_GAME_SETTING_FAIL,
    ADD_ALL_GAME_SETTING_SUCCESS,
    ADD_BET_SETTING_FAIL,
    ADD_BET_SETTING_SUCCESS,
    GameActionTypes,
    GET_ALL_BET_SETTING_FAIL,
    GET_ALL_BET_SETTING_SUCCESS,
    GET_ALL_GAME_SETTING_FAIL,
    GET_ALL_GAME_SETTING_SUCCESS,
    GET_ALL_USERS_FAIL,
    GET_ALL_USERS_SUCCESS,
    GET_TRANSFER_MONEY_HISTORY_FAIL,
    GET_TRANSFER_MONEY_HISTORY_SUCCESS,
    GET_USER_BY_PIN_CODE_FAIL,
    GET_USER_BY_PIN_CODE_SUCCESS,
    TRANSFER_MONEY_FAIL,
    TRANSFER_MONEY_SUCCESS
} from "../../actions/game/types";

export interface GameState {
    error: string | null;
    addBetSetting: boolean | null;
    getBetSettingData: any[] | null;
    addAllGameSetting: boolean | null;
    getAllGameSettingData: any[] | null;
    transferMoney: boolean | null;
    getTransferMoneyHistoryData: any[] | null;
    getUserByPinCodeData: any[] | null;
    getAllUsersData: any[] | null;
}

const initialState: GameState = {
    error: null,
    addBetSetting: null,
    getBetSettingData: null,
    addAllGameSetting: null,
    getAllGameSettingData: null,
    transferMoney: null,
    getTransferMoneyHistoryData: null,
    getUserByPinCodeData: null,
    getAllUsersData: null,
};

const gameReducer = (state = initialState, action: GameActionTypes): GameState => {
    switch (action.type) {
        case ADD_BET_SETTING_SUCCESS:
            return {
                ...state,
                addBetSetting: action.payload.success,
                error: null,
            };
        case ADD_BET_SETTING_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_BET_SETTING_SUCCESS:
            return {
                ...state,
                getBetSettingData: action.payload.data,
                error: null,
            };
        case GET_ALL_BET_SETTING_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case ADD_ALL_GAME_SETTING_SUCCESS:
            return {
                ...state,
                addAllGameSetting: action.payload.success,
                error: null,
            };
        case ADD_ALL_GAME_SETTING_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_GAME_SETTING_SUCCESS:
            return {
                ...state,
                getAllGameSettingData: action.payload.data,
                error: null,
            };
        case GET_ALL_GAME_SETTING_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case TRANSFER_MONEY_SUCCESS:
            return {
                ...state,
                transferMoney: action.payload.success,
                error: null,
            };
        case TRANSFER_MONEY_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_TRANSFER_MONEY_HISTORY_SUCCESS:
            return {
                ...state,
                getTransferMoneyHistoryData: action.payload.data,
                error: null,
            };
        case GET_TRANSFER_MONEY_HISTORY_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_USER_BY_PIN_CODE_SUCCESS:
            return {
                ...state,
                getUserByPinCodeData: action.payload.data,
                error: null,
            };
        case GET_USER_BY_PIN_CODE_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        case GET_ALL_USERS_SUCCESS:
            return {
                ...state,
                getAllUsersData: action.payload.data,
                error: null,
            };
        case GET_ALL_USERS_FAIL:
            return {
                ...state,
                error: action.payload.error,
            };
        default:
            return state;
    }
};

export default gameReducer;
