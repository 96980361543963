import React, {useEffect, useState} from 'react';
import { menuItems, MenuItem } from '../common/SideBar/SideBarData';
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {
    getAllUserTypes,
    getAllUserTypesPermission,
    saveUserTypesPermission
} from "../../../redux/actions/manage/manageActions";
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import {findUserTypeFromToken} from "../../../helper/FindUserTypeFromToken";
import {PrivateRoutes} from "../../../App";
import { DashboardSections } from '../Dashboard';

const flattenMenuItems = (items: MenuItem[]): MenuItem[] => {
    let flattenedItems: MenuItem[] = [];
    items.forEach(item => {
        flattenedItems.push(item);
        if (item.subItems) {
            flattenedItems = flattenedItems.concat(flattenMenuItems(item.subItems));
        }
    });
    return flattenedItems;
};

const filterMenuItems = (items: MenuItem[], permissions: string[]): MenuItem[] => {
    return items.reduce((acc: MenuItem[], item: MenuItem) => {
        if (permissions.includes(item.id)) {
            if (item.subItems) {
                const filteredSubItems = filterMenuItems(item.subItems, permissions);
                acc.push({ ...item, subItems: filteredSubItems });
            } else {
                acc.push(item);
            }
        }
        return acc;
    }, []);
};

const filterPrivateRoutes = (routes: any[], permissions: string[]): any[] => {
    return routes.filter(route => permissions.includes(route.urlId));
};

const filterDashboardSection = (sections: any[], permissions: string[]): any[] => {
    return sections.filter(section => permissions.includes(section.id));
};

const AdminUserTypePermission: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { getAllUserTypeData, getAllUserTypesPermissionData } = useSelector((state: any) => state.manage);
    const [selectedUserType, setSelectedUserType] = useState('');
    const [permissions, setPermissions] = useState<{ [key: string]: boolean }>({});
    const [routePermissions, setRoutePermissions] = useState<{ [key: string]: boolean }>({});
    const [dashboardPermissions, setDashboardPermissions] = useState<{ [sectionId: string]: boolean }>({});
    const userType = findUserTypeFromToken();
    const { permissionsData } = useSelector((state: any) => state.auth);
    const [loading, setLoading] = useState<boolean>(false);
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;

    useEffect(() => {
        dispatch(getAllUserTypes());
        dispatch(getAllUserTypesPermission(currentPage, limit));
    }, [currentPage, dispatch]);

    const handleUserTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const userType = e.target.value;
        setSelectedUserType(userType);

        const userTypePermissions = getAllUserTypesPermissionData?.data.permissions.find((utp: any) => utp.userType === userType);
        if (userTypePermissions) {
            setPermissions(Object.fromEntries(userTypePermissions.permissions.map((p: string) => [p, true])));
            setRoutePermissions(Object.fromEntries(userTypePermissions.routePermissions.map((r: string) => [r, true])));
            setDashboardPermissions(Object.fromEntries(userTypePermissions.dashboardPermissions.map((r: string) => [r, true])));
        } else {
            setPermissions({});
            setRoutePermissions({});
            setDashboardPermissions({});
        }
    };

    const handlePermissionChange = (menuItemId: string) => {
        setPermissions(prevPermissions => ({
            ...prevPermissions,
            [menuItemId]: !prevPermissions[menuItemId]
        }));
    };

    const handleRoutePermissionChange = (routeId: string) => {
        setRoutePermissions(prevRoutePermissions => ({
            ...prevRoutePermissions,
            [routeId]: !prevRoutePermissions[routeId]
        }));
    };

    const handleDashboardPermissionChange = (sectionId: string) => {
        setDashboardPermissions(prevDashboardPermissions => ({
            ...prevDashboardPermissions,
            [sectionId]: !prevDashboardPermissions[sectionId]
        }));
    };

    const handleSavePermissions = async () => {
        try {
            if (!selectedUserType) {
                toast.error('Please select a user type');
                return;
            }

            const newPermissions = Object.keys(permissions).filter(key => permissions[key]);
            const newRoutePermissions = Object.keys(routePermissions).filter(key => routePermissions[key]);
            const newDashboardPermissions = Object.keys(dashboardPermissions).filter(key => dashboardPermissions[key]);
            setLoading(true);
            const response = await dispatch(saveUserTypesPermission({ userType: selectedUserType, permissions: newPermissions, routePermissions: newRoutePermissions, dashboardPermissions: newDashboardPermissions }));

            if (response.status === 200) {
                await dispatch(getAllUserTypesPermission(currentPage, limit));
                await Swal.fire('Success', 'Permissions saved successfully', 'success');
            } else {
                toast.error(response.message || 'Failed to save permissions');
            }

        } catch (error) {
            console.error('Error saving permissions:', error);
            toast.error('An error occurred while saving permissions');
        } finally {
            setLoading(false);
        }
    };


    const renderMenuItems = (items: MenuItem[]) => (
        items.map(menuItem => (
            <div key={menuItem.id} className="ml-4 mb-2">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id={`permission-${menuItem.id}`}
                        checked={permissions[menuItem.id] || false}
                        onChange={() => handlePermissionChange(menuItem.id)}
                        className="mr-2"
                    />
                    <label htmlFor={`permission-${menuItem.id}`} className="">{menuItem.name}</label>
                </div>
                {menuItem.subItems && renderMenuItems(menuItem.subItems)}
            </div>
        ))
    );

    const renderRoutePermissions = (routes: any[]) => (
        routes.map(route => (
            <div key={route.urlId} className="ml-4 mb-2">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id={`route-${route.urlId}`}
                        checked={routePermissions[route.urlId] || false}
                        onChange={() => handleRoutePermissionChange(route.urlId)}
                        className="mr-2"
                    />
                    <label htmlFor={`route-${route.urlId}`} className="">{route.urlId}</label>
                </div>
            </div>
        ))
    );

    const renderDashboardPermissions = (sections: any[]) => (
      sections.map(section => (
            <div key={section.id} className="ml-4 mb-2">
                <div className="flex items-center">
                    <input
                        type="checkbox"
                        id={`route-${section.id}`}
                        checked={dashboardPermissions[section.id] || false}
                        onChange={() => handleDashboardPermissionChange(section.id)}
                        className="mr-2"
                    />
                    <label htmlFor={`route-${section.id}`} className="">{section.id}</label>
                </div>
            </div>
        ))
    );

    const handlePageChange = async (page: number) => {
        setCurrentPage(page);
    };

    const flattenedMenuItems = flattenMenuItems(menuItems);

    const filteredMenuItems = userType === 'Super Admin'
        ? menuItems
        : filterMenuItems(menuItems, permissionsData?.permissions || []);

    const filteredPrivateRoutes = userType === 'Super Admin'
        ? PrivateRoutes
        : filterPrivateRoutes(PrivateRoutes, permissionsData?.routePermissions || []);

    const filteredDashboardPermission = userType === 'Super Admin'
        ? DashboardSections
        : filterDashboardSection(DashboardSections, permissionsData?.dashboardPermissions || []);

    return (
        <div className="container mx-auto p-4 bg-gray-100 dark:bg-gray-950">
            <h1 className="text-2xl font-bold mb-4">User Type Permissions</h1>
            <div className="mb-6">
                <label htmlFor="userTypeSelect" className="block mb-2">Select User Type</label>
                <select
                    id="userTypeSelect"
                    value={selectedUserType}
                    onChange={handleUserTypeChange}
                    className="border dark:bg-gray-950 border-gray-300 rounded px-4 py-2 w-full"
                >
                    <option value="">Select User Type</option>
                    {getAllUserTypeData?.filter((userType: any) => userType.name !== 'Super Admin')
                        .map((userType: any, index: number) => (
                            <option key={index} value={userType.name}>{userType.name}</option>
                        ))}
                </select>
            </div>
            <div className="mb-6">
                <div className="sm:flex sm:space-x-10">
                    <div>
                        <h2 className="text-xl font-bold mb-4">SideBar Permissions</h2>
                        {renderMenuItems(filteredMenuItems)}
                    </div>
                    <div>
                        <h2 className="text-xl font-bold mt-6 sm:mt-0 mb-4">Pages Permissions</h2>
                        {renderRoutePermissions(filteredPrivateRoutes)}
                    </div>
                    <div>
                        <h2 className="text-xl font-bold mt-6 sm:mt-0 mb-4">Dashboard Permissions</h2>
                        {renderDashboardPermissions(filteredDashboardPermission)}
                    </div>
                </div>
                <div className="flex justify-end">
                    <button
                        onClick={handleSavePermissions}
                        disabled={loading}
                        className="mt-4 bg-blue-500 text-white px-4 py-2 rounded"
                    >
                        {loading ? 'Saving...' : 'Save Permissions'}
                    </button>
                </div>
            </div>
            <h2 className="text-xl font-bold mb-4">User Type Permissions List</h2>
            <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                <thead>
                <tr>
                    <th className="py-2 px-4 border text-center">User Type</th>
                    <th className="py-2 px-4 border text-center">Permissions</th>
                    <th className="py-2 px-4 border text-center">Route Permissions</th>
                    <th className="py-2 px-4 border text-center">Dashboard Permissions</th>
                    <th className="py-2 px-4 border text-center">Actions</th>
                </tr>
                </thead>
                <tbody>
                {getAllUserTypesPermissionData?.data.permissions.map((utp: any, index: number) => (
                    <tr key={index}>
                        <td className="py-2 px-4 border text-center">{utp.userType}</td>
                        <td className="py-2 px-4 border text-center">
                            {flattenedMenuItems
                                .filter(item => utp.permissions.includes(item.id))
                                .map(item => item.name)
                                .join(', ')
                            }
                        </td>
                        <td className="py-2 px-4 border text-center">
                            {PrivateRoutes
                                .filter(route => utp.routePermissions.includes(route.urlId))
                                .map(route => route.urlId)
                                .join(', ')
                            }
                        </td>
                        <td className="py-2 px-4 border text-center">
                            {DashboardSections
                                .filter(section => utp.dashboardPermissions.includes(section.id))
                                .map(section => section.id)
                                .join(', ')
                            }
                        </td>
                        <td className="py-2 px-4 border text-center">
                            <button
                                className="bg-green-500 text-white px-2 py-1 rounded"
                                onClick={() => {
                                    setSelectedUserType(utp.userType);
                                    setPermissions(Object.fromEntries(utp.permissions.map((p: string) => [p, true])));
                                    setRoutePermissions(Object.fromEntries(utp.routePermissions.map((r: string) => [r, true])));
                                    setDashboardPermissions(Object.fromEntries(utp.dashboardPermissions.map((r: string) => [r, true])));
                                }}
                            >
                                Update
                            </button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
            <div className="block sm:hidden">
                <Pagination totalPages={getAllUserTypesPermissionData?.data.totalPages} currentPage={currentPage}
                            onPageChange={handlePageChange}/>
            </div>
            <div className="hidden sm:block">
            <PaginationTwo totalPages={getAllUserTypesPermissionData?.data.totalPages} currentPage={currentPage}
                               onPageChange={handlePageChange}/>
            </div>
        </div>
    );
};

export default AdminUserTypePermission;
