import axios from 'axios';

const getRealIp = async () => {
    try {
        const response = await axios.get('https://ipinfo.io/json');
        return response.data.ip;
    } catch (error) {
        console.error('Error fetching IP address:', error);
        return '';
    }
}

export default getRealIp;
