import React, { useState, useEffect } from 'react';
import QRCode from 'qrcode';
import { FaTrashAlt } from 'react-icons/fa';
import {useDispatch, useSelector} from "react-redux";
import {
    disable2FA,
    generate2FASecret,
    getProfileDetails,
    verify2FAEnableOtp
} from "../../../redux/actions/auth/authActions";
import {toast} from "react-toastify";
import Swal from "sweetalert2";

const Authenticator: React.FC = () => {
    const dispatch = useDispatch<any>();
    const { userProfileData, generate2FASecretData } = useSelector((state: any) => state.auth);
    const [showAuthenticatorPopup, setShowAuthenticatorPopup] = useState(false);
    const [showManualEntry, setShowManualEntry] = useState(false);
    const [showEnterOtp, setShowEnterOtp] = useState(false);
    const [formData, setFormData] = useState({
        qrCodeURL: '',
        secret: '',
        otp: ['', '', '', '', '', ''],
    });

    const handleOtpChange = (index: number, value: string) => {
        if (/^[0-9]$/.test(value) || value === '') {
            const newOtp = [...formData.otp];
            newOtp[index] = value;
            setFormData({ ...formData, otp: newOtp });

            if (value !== '' && index < formData.otp.length - 1) {
                (document.getElementById(`otp-${index + 1}`) as HTMLInputElement).focus();
            } else if (value === '' && index > 0) {
                (document.getElementById(`otp-${index - 1}`) as HTMLInputElement).focus();
            }
        }
    };

    useEffect(() => {
        dispatch(getProfileDetails());
    }, [dispatch]);

    console.log('formData', formData);

    useEffect(() => {
        if (generate2FASecretData) {
            const generateQRCode = async () => {
                try {
                    const qrCode = await QRCode.toDataURL(generate2FASecretData?.data.newSecret);
                    setFormData((prevData) => ({ ...prevData, qrCodeURL: qrCode, secret: generate2FASecretData?.data.secret }));
                } catch (err) {
                    console.error('Failed to generate QR code:', err);
                }
            };

            generateQRCode();
        }
    }, [generate2FASecretData]);

    const handleEnable2FA = async () => {
        try {
            const response = await dispatch(generate2FASecret());
            if (response && response.status === 200) {
                setShowAuthenticatorPopup(true);
            } else {
                toast.error('Failed to generate 2FA secret. Please try again.');
            }
        } catch (error) {
            console.error('Error enabling 2FA:', error);
            toast.error('An error occurred while enabling 2FA.');
        }
    };

    const handleDisable2FA = async () => {
        try {
            const response = await dispatch(disable2FA());
            if (response && response.status === 200) {
                await dispatch(getProfileDetails());
                await Swal.fire('Success', 'Two-factor authentication disabled successfully!', 'success');
            } else {
                toast.error('Failed to disable 2FA. Please try again.');
            }
        } catch (error) {
            console.error('Error disabling 2FA:', error);
            toast.error('An error occurred while disabling 2FA.');
        }
    };

    const handleVerifyOtp = async () => {
        try {
            const userOtp = formData.otp.join('');
            const data = { secret: generate2FASecretData?.data.secret, otp: userOtp };
            const response = await dispatch(verify2FAEnableOtp(data));

            if (response.status === 200) {
                await dispatch(getProfileDetails());
                setShowAuthenticatorPopup(false);
                await Swal.fire('Success', 'Two-factor authentication enabled successfully!', 'success');
            } else {
                toast.error('OTP verification failed');
            }
        } catch (error) {
            console.error('Error verifying OTP:', error);
            toast.error('Failed to verify OTP');
        }
    };

    const timeAgo = (date: string | Date) => {
        const now = new Date();
        const past = typeof date === 'string' ? new Date(date) : date;
        const secondsAgo = Math.floor((now.getTime() - past.getTime()) / 1000);
        const minutesAgo = Math.floor(secondsAgo / 60);
        const hoursAgo = Math.floor(minutesAgo / 60);
        const daysAgo = Math.floor(hoursAgo / 24);
        const yearsAgo = Math.floor(daysAgo / 365);

        if (secondsAgo < 60) return 'A few seconds ago';
        if (minutesAgo < 60) return `${minutesAgo} minute${minutesAgo !== 1 ? 's' : ''} ago`;
        if (hoursAgo < 24) return `${hoursAgo} hour${hoursAgo !== 1 ? 's' : ''} ago`;
        if (daysAgo < 30) return `${daysAgo} day${daysAgo !== 1 ? 's' : ''} ago`;
        if (daysAgo < 365) return `${Math.floor(daysAgo / 30)} month${Math.floor(daysAgo / 30) !== 1 ? 's' : ''} ago`;
        return `${yearsAgo} year${yearsAgo !== 1 ? 's' : ''} ago`;
    };

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-black p-4">
            <div className="max-w-7xl mx-auto ">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold ">Authenticator App</h1>
                </div>

                {/* 2FA Section */}
                <div className="mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">
                    <h2 className="text-2xl font-semibold mb-4">Two-Factor Authentication</h2>
                    <p className="mb-4">
                        Instead of waiting for text messages, get verification codes from an authenticator app. It works even if your phone is offline.
                        <br />
                        <br />
                        First, download Google Authenticator from the{' '}
                        <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">Google Play Store</a> or the{' '}
                        <a href="https://apps.apple.com/us/app/google-authenticator/id388497605" className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">iOS App Store</a>.
                    </p>
                    {!userProfileData?.user.twoFactorEnabled ? (
                        <div className="flex flex-col sm:flex-row gap-4">
                            <button onClick={handleEnable2FA} className="px-4 py-2 bg-green-600 text-white rounded">
                                Enable 2FA
                            </button>
                        </div>
                    ) : (
                        <div>
                            <p className="mb-4 font-bold">Authenticator</p>
                            <p className="mb-4">{timeAgo(userProfileData?.user.twoFactorEnabledDate)}</p>
                            <div className="flex flex-col sm:flex-row gap-4">
                                <button
                                    onClick={handleEnable2FA}
                                    className="px-4 py-2 bg-blue-600 text-white rounded"
                                >
                                    Change Authenticator App
                                </button>
                                <button
                                    onClick={handleDisable2FA}
                                    className="justify-center px-4 py-2 bg-red-600 text-white rounded flex items-center"
                                >
                                    <FaTrashAlt className="mr-2" /> Delete
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* Authenticator Popup */}
            {showAuthenticatorPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white dark:bg-gray-950 dark:border-2 border-white p-6 rounded shadow-lg max-w-lg w-full">
                        <h2 className="text-xl font-semibold mb-4">Add Authenticator App</h2>
                        {!showEnterOtp ? (
                            <>
                                {!showManualEntry ? (
                                    <div>
                                        <p className="mb-4">
                                            You won’t be able to use your old authenticator app for codes or 2-Step Verification for your account.
                                        </p>
                                        <div className="ml-4 mb-4">
                                            <li>In the Google Authenticator app, tap the +</li>
                                            <li>Choose <strong>Scan a QR code</strong></li>
                                            <li>Scan the QR Code below:</li>
                                        </div>
                                        <div className="flex flex-col justify-center items-center">
                                            <div className="bg-gray-200 p-4 rounded mb-4 h-[200px] w-[200px]">
                                                <img src={formData.qrCodeURL} alt="QR Code" className="w-full"/>
                                            </div>
                                            <button
                                                onClick={() => setShowManualEntry(true)}
                                                className="text-blue-600 underline mb-4"
                                            >
                                                Can't scan it?
                                            </button>
                                        </div>
                                        <div className="flex justify-end">
                                            <button
                                                onClick={() => setShowAuthenticatorPopup(false)}
                                                className="mr-2 px-4 py-2 text-blue-600"
                                            >
                                                Cancel
                                            </button>
                                            <button
                                                onClick={() => setShowEnterOtp(true)}
                                                className="px-4 py-2 text-blue-600">
                                                Next
                                            </button>
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <ol className="list-decimal ml-4 mb-4">
                                            <li>In the Google Authenticator app, tap the + then tap <strong>Enter a
                                                setup
                                                key</strong>
                                            </li>
                                            <li>
                                                Enter your email address and this key (spaces don’t matter):
                                            </li>
                                            <div className="bg-gray-200 dark:bg-gray-950 p-4 rounded mb-4">
                                                <p className="text-center">{formData.secret}</p>
                                            </div>
                                            <li>
                                                Make sure <strong>Time based</strong> is selected
                                            </li>
                                            <li>
                                                Tap <strong>Add</strong> to finish
                                            </li>
                                        </ol>
                                        <div className="h-[50px] sm:h-[100px]"></div>
                                        <div className="flex justify-between">
                                            <button
                                                onClick={() => setShowManualEntry(false)}
                                                className="mr-2 px-4 py-2 text-blue-600"
                                            >
                                                Back
                                            </button>
                                            <div className="flex justify-end">
                                                <button
                                                    onClick={() => setShowAuthenticatorPopup(false)}
                                                    className="mr-2 px-4 py-2 text-blue-600"
                                                >
                                                    Cancel
                                                </button>
                                                <button
                                                    onClick={() => setShowEnterOtp(true)}
                                                    className="px-4 py-2 text-blue-600">
                                                    Next
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </>
                        ) : (
                            <div className="flex flex-col justify-between min-h-[400px]">
                                <form>
                                    <label>Enter the 6-digit code you see in the app</label>
                                    <div className="flex justify-center space-x-2 mt-6">
                                        {formData.otp.map((digit, index) => (
                                            <input
                                                key={index}
                                                id={`otp-${index}`}
                                                type="text"
                                                value={digit}
                                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                                maxLength={1}
                                                className="w-12 h-12 text-center text-xl border border-gray-300 dark:bg-gray-950 dark:text-white rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                                            />
                                        ))}
                                    </div>
                                </form>
                                <div className="flex justify-between">
                                    <button
                                        onClick={() => setShowEnterOtp(false)}
                                        className="mr-2 px-4 py-2 text-blue-600"
                                    >
                                        Back
                                    </button>
                                    <div className="flex justify-end">
                                        <button
                                            onClick={() => setShowAuthenticatorPopup(false)}
                                            className="mr-2 px-4 py-2 text-blue-600"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleVerifyOtp}
                                            className="px-4 py-2 text-blue-600">
                                            Verify
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default Authenticator;
