// src/pages/AdminUserInfo.tsx
import React, {useState, useEffect} from 'react';
import {useParams} from 'react-router-dom';
import {useDispatch, useSelector} from 'react-redux';
import {getAdminUserInfoById} from '../../../../redux/actions/manage/manageActions';
import Loading from '../../../../Routes/Loading';
import LoginActivityTableView from "./LoginActivityTableView";
import AdminsActivitiesTableView from "./AdminsActivitiesTableView";
import TransactionTableView from "./TranscitionTableView";
import GetTransactionTableView from "./GetTranscitionTableView";
import GetBetHistoryTableView from "./GetBetHistoryTableView";

const AdminUserInfo: React.FC = () => {
    const dispatch = useDispatch<any>();
    const {id} = useParams<{ id: string }>();

    // State for pagination
    const [loginPage, setLoginPage] = useState<number>(1);
    const [adminPage, setAdminPage] = useState<number>(1);
    const [transactionPage, setTransactionPage] = useState<number>(1);
    const [getHistoryPage, setGetHistoryPage] = useState<number>(1);
    const [getBetHistoryPage, setGetBetHistoryPage] = useState<number>(1);
    const loginLimit = 10;
    const adminLimit = 10;
    const transactionLimit = 10;
    const getHistoryLimit = 10;
    const getBetHistoryLimit = 10;

    const {getAdminInfoByIdData} = useSelector((state: any) => state.manage);

    useEffect(() => {
        if (id) {
            const data = {
                id,
                loginPage,
                loginLimit,
                adminPage,
                adminLimit,
                transactionPage,
                transactionLimit,
                getHistoryPage,
                getHistoryLimit,
                getBetHistoryPage,
                getBetHistoryLimit,
            };
            dispatch(getAdminUserInfoById(data));
        }
    }, [id, loginPage, adminPage, dispatch, getHistoryPage, getHistoryLimit, transactionPage, transactionLimit, getBetHistoryPage, getBetHistoryLimit]);

    const handlePageChange = (page: number) => {
        setLoginPage(page);
    };

    const handleAdminPageChange = (page: number) => {
        setAdminPage(page);
    };

    const handleTransactionPageChange = (page: number) => {
        setTransactionPage(page);
    };

    const handleGetHistoryPageChange = (page: number) => {
        setGetHistoryPage(page);
    };

    const handleGetBetHistoryPageChange = (page: number) => {
        setGetBetHistoryPage(page);
    };

    const formatDateTime = (timestamp: string) => {
        return new Date(timestamp).toLocaleString();
    };

    return (
        <div className="p-4 sm:p-6 bg-gray-50 dark:bg-black container mx-auto">
            {getAdminInfoByIdData ? (
                <div className="space-y-4">
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        <h1 className="text-xl sm:text-2xl font-bold mb-4">Admin User Info</h1>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                            <div>
                                <span className="font-semibold">Name:</span> {getAdminInfoByIdData.user.name}
                            </div>
                            <div>
                                <span className="font-semibold">Wallet:</span> {(getAdminInfoByIdData.user.wallet ?? 0).toFixed(2)}
                            </div>
                            <div>
                                <span className="font-semibold">Role:</span> {getAdminInfoByIdData.user.userType}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Phone:</span> {getAdminInfoByIdData.user.countryCode} {getAdminInfoByIdData.user.phone}
                            </div>
                            <div>
                                <span className="font-semibold">DOB:</span> {getAdminInfoByIdData.user.dob}
                            </div>
                            <div>
                                <span className="font-semibold">Address: </span>
                                {`${getAdminInfoByIdData.user.address.completeAddress}, ${getAdminInfoByIdData.user.address.pinCode}`}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Last Login IP:</span> {getAdminInfoByIdData.user.lastLoginIpAddress}
                            </div>
                            <div>
                                <span className="font-semibold">Status:</span> <span
                                className={`${getAdminInfoByIdData.user.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>{getAdminInfoByIdData.user.status === 'active' ? 'Active' : 'Inactive'}</span>
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Last Activity:</span> {formatDateTime(getAdminInfoByIdData.user.lastActivityTimestamp)}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Last Login:</span> {formatDateTime(getAdminInfoByIdData.user.lastLoginTimestamp)}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Created At:</span> {formatDateTime(getAdminInfoByIdData.user.createdAt)}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold">Updated At:</span> {formatDateTime(getAdminInfoByIdData.user.updatedAt)}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold text-red-500">Total Lose:</span> {getAdminInfoByIdData.totalLoss.toFixed(2)}
                            </div>
                            <div>
                                    <span
                                        className="font-semibold text-green-500">Total Win:</span> {getAdminInfoByIdData.totalWin.toFixed(2)}
                            </div>
                        </div>
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        {/* Login Activities Pagination */}
                        <LoginActivityTableView
                            data={getAdminInfoByIdData?.loginActivities}
                            title={'Login Activities'}
                            currentPage={loginPage}
                            limit={loginLimit}
                            handlePageChange={handlePageChange}
                        />
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        {/* Admin Activities Pagination */}
                        <AdminsActivitiesTableView
                            data={getAdminInfoByIdData?.adminActivities}
                            title={'Admin Activities'}
                            currentPage={adminPage}
                            limit={adminLimit}
                            handlePageChange={handleAdminPageChange}
                        />
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        {/* Transaction Pagination */}
                        <TransactionTableView
                            data={getAdminInfoByIdData?.transactionActivities}
                            title={'Recharge History'}
                            currentPage={transactionPage}
                            limit={transactionLimit}
                            handlePageChange={handleTransactionPageChange}
                        />
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        {/* Get Transaction Pagination */}
                        <GetTransactionTableView
                            data={getAdminInfoByIdData?.getHistoryActivities}
                            title={'Withdraw History'}
                            currentPage={getHistoryPage}
                            limit={getHistoryLimit}
                            handlePageChange={handleGetHistoryPageChange}
                        />
                    </div>
                    <div className="bg-white dark:bg-gray-950 shadow-md rounded-lg p-4 sm:p-6">
                        {/* Get Transaction Pagination */}
                        <GetBetHistoryTableView
                            data={getAdminInfoByIdData?.getBetHistoryActivities}
                            title={'Get Bet History'}
                            currentPage={getBetHistoryPage}
                            limit={getBetHistoryLimit}
                            handlePageChange={handleGetBetHistoryPageChange}
                        />
                    </div>
                </div>
            ) : (
                <Loading/>
            )}
        </div>
    );
};

export default AdminUserInfo;
