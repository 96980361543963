import React, {useCallback, useEffect, useState} from "react";
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {toast} from "react-toastify";
import {get_pending_withdraw, withdraw_update_status} from "../../../api";
import Swal from "sweetalert2";
import Modal from "../common/PopUp/Modal";

export default function WithdrawPending() {
    const [data, setData] = useState<any | null >(null);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;
    const [loading, setLoading] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentId, setCurrentId] = useState(null);
    const [utr, setUtr] = useState('');
    const [payBy, setPayBy] = useState('');

    const handleShow = (id: any) => {
        setCurrentId(id);
        setShowUpdateModal(true);
    };

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Wrapped getAllRecharge in useCallback to avoid re-creating the function on every render
    const getAllRecharge = useCallback(async () => {
        try {
            const response = await get_pending_withdraw(currentPage, limit, searchTerm);
            setData(response); // Assume response contains { data: [...], totalPages: ... }
        } catch (error: any) {
            console.error(error);
            toast.error('Failed to fetch recharge data');
        }
    }, [currentPage, searchTerm]); // Included necessary dependencies in useCallback

    // Fetch recharge data when the component mounts and when searchTerm or currentPage changes
    useEffect(() => {
        const fetchData = async () => {
            await getAllRecharge(); // Ensure the async function is awaited
        };
        fetchData();
    }, [getAllRecharge]); // getAllRecharge is now a stable dependency due to useCallback

    // Function to handle status update
    const handleStatusUpdate = async (id: string, currentStatus: string) => {
        try {
            const response = await withdraw_update_status({ id, status: currentStatus });
            if (response && response.message === 'Status updated successfully') {
                await Swal.fire('Success', 'Recharge Rejected successfully', 'error');
                await getAllRecharge(); // Refresh data after status update
            } else {
                toast.error(response.message || 'Failed to update status');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the wiithdraw status.');
        }
    };

    const handleAccept = async () => {
        try {
            if(currentId && utr && payBy) {
                setLoading(true);
                const response = await withdraw_update_status({ id:currentId, status: 'Success', utr, payBy  });
                console.log(response);
                if (response && response.message === 'Status updated successfully') {
                    await Swal.fire('Success', 'Withdraw Approved successfully', 'success');
                    await getAllRecharge(); // Refresh data after status update
                } else {
                    toast.error(response.message || 'Failed to update status');
                }
                setPayBy('');
                setUtr('');
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the user status.');
        }
    };

    return (
        <div
            className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">All Pending Withdraws</h1>
                </div>
            </div>
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search Withdraws</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by phone, type, UTR, OrderId, Amount"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">OrderId</th>
                            <th className="px-4 py-3 border-b">Phone</th>
                            <th className="px-4 py-3 border-b">UTR</th>
                            <th className="px-4 py-3 border-b">UPI</th>
                            <th className="px-4 py-3 border-b">BANK</th>
                            <th className="px-4 py-3 border-b">USDT</th>
                            <th className="px-4 py-3 border-b">Amount</th>
                            <th className="px-4 py-3 border-b">Fee</th>
                            <th className="px-4 py-3 border-b">DollarRate</th>
                            <th className="px-4 py-3 border-b">Type</th>
                            <th className="px-4 py-3 border-b">PayBy</th>
                            <th className="px-4 py-3 border-b">CreateDate</th>
                            <th className="px-4 py-3 border-b">Status</th>
                            <th className="px-4 py-3 border-b">Accept</th>
                            <th className="px-4 py-3 border-b">Reject</th>
                        </tr>
                        </thead>
                        <tbody>
                        {data === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No admin users found.
                                </td>
                            </tr>
                        ) : (
                            data?.data?.map((user: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{user.orderId}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.phone}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.utr}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.upiId}</td>
                                    <td className="px-4 py-3 border-b text-center">
                                        {user?.bank && (
                                            <>
                                                <li>{user.bank?.ifsc}</li>
                                                <li>{user.bank?.accountNumber}</li>
                                                <li>{user.bank?.bankName}</li>
                                            </>
                                        )}
                                    </td>
                                    <td className="px-4 py-3 border-b text-center">{user.usdt}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.amount}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.fee}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.dollarRate}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.type}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.payBy}</td>
                                    <td className="px-4 py-3 border-b text-center">{new Date(user.createDate).toLocaleString()}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.status}</td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            className={`font-semibold rounded-full px-2 text-center text-white bg-green-600 hover:text`}
                                            onClick={() => handleShow(user._id)}
                                        >
                                            Approve
                                        </button>
                                    </td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            className={`font-semibold rounded-full px-2 text-center text-white bg-red-600 hover:text`}
                                            onClick={() => handleStatusUpdate(user._id, 'Failed')}
                                        >
                                            Reject
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={data?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={data?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>
            {/* Update Modal */}
            {showUpdateModal && (
                <Modal onClose={() => setShowUpdateModal(false)}>
                    <form onSubmit={handleAccept} className="space-y-6">
                        <div className="space-y-4">
                            <div className="space-y-2">
                                <label htmlFor="updateName" className="block text-lg font-medium ">UTR</label>
                                <input
                                    type="text"
                                    id="updateName"
                                    name="name"
                                    placeholder="Enter UTR"
                                    value={utr}
                                    onChange={(e) => setUtr(e.target.value)}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                            <div className="space-y-2">
                                <label htmlFor="updateEmail" className="block text-lg font-medium ">PayBy</label>
                                <input
                                    type="text"
                                    id="phone"
                                    name="phone"
                                    placeholder="Enter PayBy"
                                    value={payBy}
                                    onChange={(e) => setPayBy(e.target.value)}
                                    className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                                    required
                                />
                            </div>
                        </div>
                        <div className="flex justify-end">
                            <button
                                type="submit"
                                disabled={loading}
                                className="bg-blue-600 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-700 focus:outline-none focus:bg-blue-700"
                            >
                                {loading ? "Updating..." : "OK"}
                            </button>
                        </div>
                    </form>
                </Modal>
            )}
        </div>
    );
}
