// src/pages/AdminsActivitiesTableView.tsx
import Pagination from "../../common/Pagination/pagination";
import PaginationTwo from "../../common/Pagination/paginationDesktop";
import React from "react";

const columns = [
    'Order Id',
    'UTR',
    'Pay To',
    'Type',
    'Amount',
    'Dollar Rate',
    'Status',
    'Date',
];

interface AdminsActivitiesTableViewProps {
    data: any;
    title: string;
    currentPage: number;
    limit: number;
    handlePageChange: (page: number) => void;
}

const TransactionTableView: React.FC<AdminsActivitiesTableViewProps> = ({ data, title, currentPage, limit, handlePageChange }) => {
    return (
        <div className="mt-6">
            <h2 className="text-xl font-semibold">{title}</h2>
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300 text-center">
                    <thead className="bg-gray-100">
                    <tr>
                        <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border-b border-gray-200 text-center">Sr No.</th>
                        {columns.map((d, i) => (
                            <th className="py-3 px-6 bg-gray-100 dark:bg-gray-950 border-b border-gray-200 text-center" key={i}>{d}</th>
                        ))}
                    </tr>
                    </thead>
                    <tbody>
                    {data?.data.map((activity: any, index: number) => (
                        <tr key={activity._id}>
                            <td className="py-3 px-6 border-b border-gray-200">{((currentPage - 1) * limit) + index + 1}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.orderId}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.utr}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.payTo}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.type}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.amount}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.dollarRate}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{activity.status}</td>
                            <td className="py-3 px-6 border-b border-gray-200">{new Date(activity.createDate).toLocaleString()}</td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div className="block sm:hidden mt-6">
                <Pagination
                    totalPages={data?.totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
            <div className="hidden sm:block mt-6">
                <PaginationTwo
                    totalPages={data?.totalPages}
                    currentPage={currentPage}
                    onPageChange={handlePageChange}
                />
            </div>
        </div>
    )
};

export default TransactionTableView;
