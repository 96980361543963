// src/components/ThemeToggle.tsx
import React from 'react';
import { useTheme } from '../../../../context/ThemeContext';
import { FaSun, FaAdjust } from 'react-icons/fa';
import {BsMoonStarsFill} from "react-icons/bs";

const ThemeToggle: React.FC = () => {
    const { theme, setTheme } = useTheme();

    const handleToggle = () => {
        if (theme === 'light') {
            setTheme('dark');
        } else if (theme === 'dark') {
            setTheme('system');
        } else {
            setTheme('light');
        }
    };

    return (
        <button onClick={handleToggle} className="p-2 bg-gray-200 dark:bg-gray-700 rounded">
            {theme === 'light' ? (
                <FaSun className="text-yellow-500" />
            ) : theme === 'dark' ? (
                <BsMoonStarsFill className="text-black dark:text-white" />
            ) : (
                <FaAdjust className="text-blue-500 dark:text-white" />
            )}
        </button>
    );
};

export default ThemeToggle;
