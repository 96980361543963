import React from "react";

interface PaginationProps {
    totalPages: number;
    currentPage: number;
    onPageChange: (page: number) => void;
}

const PaginationTwo: React.FC<PaginationProps> = ({ totalPages, currentPage, onPageChange }) => {
    const pageNumbers = [];
    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    return (
        <ul className="flex justify-end mt-5">
            <li
                className={`${currentPage === 1 ? "opacity-50 cursor-not-allowed" : ""} mx-1`}
                onClick={() => currentPage !== 1 && onPageChange(currentPage - 1)}
            >
                <button className={`${currentPage === 1 ? "bg-gray-300 text-black" : "bg-blue-600 text-white"} font-semibold py-2 px-3 rounded transition duration-300`}>
                    Previous
                </button>
            </li>
            {pageNumbers.map(page => (
                <li key={page} className="mx-1" onClick={() => onPageChange(page)}>
                    <button className={`${page === currentPage ? "bg-blue-600 text-white" : "bg-gray-300 text-black"} font-semibold py-2 px-3 rounded transition duration-300`}>
                        {page}
                    </button>
                </li>
            ))}
            <li
                className={`${currentPage === totalPages ? "opacity-50 cursor-not-allowed" : ""} mx-1`}
                onClick={() => currentPage !== totalPages && onPageChange(currentPage + 1)}
            >
                <button className={`${currentPage === totalPages ? "bg-gray-300 text-black" : "bg-blue-600 text-white"} font-semibold py-2 px-3 rounded transition duration-300`}>
                    Next
                </button>
            </li>
        </ul>
    );
};

export default PaginationTwo;
