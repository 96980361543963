import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NotFound from "./Routes/NotFound";
import ProtectedRoute from "./Routes/ProtectedRoute";
import HomeRedirect from "./Routes/HomeRedirect";
import PrivateRoute from "./Routes/PrivateRoute";
import Questions from "./app/components/Help/Questions";
import Chat from "./app/components/Help/Chat";
import TaskPage from "./app/components/Help/Task";
import PublicRoute from "./Routes/PublicRoute";
import LoginPage from "./app/pages/LoginPage";
import ForgotPasswordPage from "./app/pages/ForgotPasswordPage";
import DashboardPage from "./app/pages/DashboardPage";
import ProfileDetails from "./app/components/Auth/ProfileDetails";
import Authenticator from "./app/components/Auth/Authenticator";
import LoginActivity from "./app/components/Auth/LoginActivity";
import AdminUserTypes from "./app/components/AdminUsers/AdminUserTypes";
import AdminUserTypePermission from "./app/components/AdminUsers/AdminUserTypePermission";
import AddAdminUsers from "./app/components/AdminUsers/AddAdminUsers";
import AdminUserInfo from "./app/components/AdminUsers/Info/AdminUserInfo";
import AdminsActivities from "./app/components/AdminUsers/Activity/AdminsActivities";
import BetAmountSetting from "./app/components/gameSetting/betAmountSetting";
import AllGameCommonSetting from "./app/components/gameSetting/AllGameCommonSetting";
import TransferMoney from "./app/components/AllOtherDetaiils/TransferMoney";
import UserDetailsPinCode from "./app/components/AllOtherDetaiils/UserDetailsPinCode";
import UserDetails from "./app/components/AllOtherDetaiils/UserDetails";
import RechargePending from "./app/components/recharge/rechargePending";
import RechargeAccept from "./app/components/recharge/rechargeAccept";
import RechargeReject from "./app/components/recharge/rechargeReject";
import WithdrawPending from "./app/components/withdraw/withdrawPending";
import WithdrawAccept from "./app/components/withdraw/withdrawAccept";
import WithdrawReject from "./app/components/withdraw/withdrawReject";

export const PrivateRoutes = [
    {url: '/dashboard', component: DashboardPage, urlId: 'dashboard'},
    {url: '/add-user-type', component: AdminUserTypes, urlId: 'addUserType'},
    {url: '/add-role-permission', component: AdminUserTypePermission, urlId: 'addRolePermission'},
    {url: '/add-user', component: AddAdminUsers, urlId: 'addUser'},
    {url: '/admin/user/:id', component: AdminUserInfo, urlId: 'adminUserInfo'},
    {url: '/admins-activities', component: AdminsActivities, urlId: 'adminActivities'},
    {url: '/profile-details', component: ProfileDetails, urlId: 'profileDetails'},
    {url: '/login-activity', component: LoginActivity, urlId: 'loginActivity'},
    {url: '/transfer-money', component: TransferMoney, urlId: 'transferMoney'},
    {url: '/authenticator', component: Authenticator, urlId: 'authenticator'},
    {url: '/get-users', component: UserDetailsPinCode, urlId: 'UsersByPinCode'},
    {url: '/get-all-users', component: UserDetails, urlId: 'allUsers'},
    {url: '/tasks', component: TaskPage, urlId: 'tasks'},
    {url: '/questions', component: Questions, urlId: 'questions'},
    {url: '/chat/:id', component: Chat, urlId: 'chat'},
    {url: '/recharge/pending', component: RechargePending, urlId: 'rechargePending'},
    {url: '/recharge/accept', component: RechargeAccept, urlId: 'rechargeAccept'},
    {url: '/recharge/reject', component: RechargeReject, urlId: 'rechargeReject'},
    {url: '/withdraw/pending', component: WithdrawPending, urlId: 'withdrawPending'},
    {url: '/withdraw/accept', component: WithdrawAccept, urlId: 'withdrawAccept'},
    {url: '/withdraw/reject', component: WithdrawReject, urlId: 'withdrawReject'},
    {url: '/settings/betSetting', component: BetAmountSetting, urlId: 'betSetting' },
    {url: '/settings/allGameSetting', component: AllGameCommonSetting, urlId: 'allGameSetting' },
];

const PublicRoutes = [
    {url: '/login', component: LoginPage},
    {url: '/forgot-password', component: ForgotPasswordPage},
    {url: '/set-password', component: ProtectedRoute},
]

const App: React.FC = () => {
    return (
        <Router>
            <div className="bg-white dark:bg-black transition-colors duration-300">
                <Routes>
                    <Route path="/" element={<HomeRedirect />} />
                    {PrivateRoutes.map((route: any, index: number) => (
                        <Route
                            key={index}
                            path={route.url}
                            element={<PrivateRoute component={route.component} urlId={route.urlId} />}
                        />
                    ))}
                    {PublicRoutes.map((route: any, index: number) => (
                        <Route
                            key={index}
                            path={route.url}
                            element={<PublicRoute component={route.component} />}
                        />
                    ))}
                    <Route path="*" element={<NotFound />} />
                </Routes>
                <ToastContainer />
            </div>
        </Router>
    );
};


export default App;
