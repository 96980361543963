import React, { useEffect } from 'react';
import { Chart, registerables } from 'chart.js';
// import AdminSystemPerformance from './Dashboard/AdminSystemPerformance';
import { findUserTypeFromToken } from '../../helper/FindUserTypeFromToken';
import { useDispatch, useSelector } from 'react-redux';
import { getPermissions } from '../../redux/actions/auth/authActions';
import AdminDetails from './Dashboard/AdminDetails';
import {getDashboardData} from "../../redux/actions/manage/manageActions";
import AgentSuperBox from "./Dashboard/AgentSuperBox";

Chart.register(...registerables);
export const DashboardSections = [
    // { id: 'systemPerformanceView', component: AdminSystemPerformance, label: 'System Performance' },
    { id: 'dashboardView', component: AdminDetails, label: 'Dashboard View' }, // Add more components as needed
    { id: 'agentView', component: AgentSuperBox, label: 'Other View' }, // Add more components as needed
];

const Dashboard: React.FC = () => {
    const dispatch = useDispatch<any>();
    const userType = findUserTypeFromToken();
    const { permissionsData } = useSelector((state: any) => state.auth);

    useEffect(() => {
        if (userType && userType !== 'Super Admin') {
            dispatch(getPermissions(userType));
        }
        dispatch(getDashboardData());
    }, [dispatch, userType]);

    // Filter sections based on permissions if user is not Super Admin
    const filteredSections = userType === 'Super Admin'
      ? DashboardSections
      : DashboardSections.filter(section => permissionsData?.dashboardPermissions?.includes(section.id));

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-gray-950 p-4">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">Dashboard</h1>
                </div>
                {/* Render allowed sections */}
                {filteredSections.map(section => (
                  <div key={section.id} id={section.id}>
                      <section.component />
                  </div>
                ))}
            </div>
        </div>
    );
};

export default Dashboard;
