import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllUsers,
} from "../../../redux/actions/game/gameActions";
import Pagination from "../common/Pagination/pagination";
import PaginationTwo from "../common/Pagination/paginationDesktop";
import {updateAdminUserStatus} from "../../../redux/actions/manage/manageActions";
import Swal from "sweetalert2";
import {toast} from "react-toastify";

export default function UserDetails() {
    const dispatch = useDispatch<any>();
    const { getAllUsersData } = useSelector((state: any) => state.game);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const limit = 15;

    const handlePageChange = (page: number) => {
        setCurrentPage(page);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    // Fetch bet settings data when the component mounts
    useEffect(() => {
        dispatch(getAllUsers(currentPage, limit, searchTerm));
    }, [dispatch, currentPage, limit, searchTerm]);

    const handleInfoClick = (id: string) => {
        window.location.href = `/admin/user/${id}`;
    };

    const handleStatusUpdate = async (id: string, currentStatus: string) => {
        try {
            const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
            const response = await dispatch(updateAdminUserStatus({ id, status: newStatus }));
            if (response && response.status === 200) {
                await Swal.fire('Success', 'User status updated successfully', 'success');
                dispatch(getAllUsers(currentPage, limit, searchTerm));
            } else {
                toast.error(response.message);
            }
        } catch (error) {
            console.error(error);
            toast.error('An error occurred while updating the user status.');
        }
    };
    return (
        <div
            className="container mx-auto p-6 bg-gray-50 dark:bg-gray-950 dark:border-2 border-white rounded-lg shadow-lg">
            <div className="col-12">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold">All Clients Details</h1>
                </div>
            </div>
            <div className="mt-10">
                <h2 className="text-2xl font-bold mb-6 text-center">Search Clients</h2>
                <div className="mb-6">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        placeholder="Search by phone, user type, pinCode"
                        className="border border-gray-300 dark:bg-gray-950 rounded px-4 py-2 w-full focus:outline-none focus:border-blue-500"
                    />
                </div>
                <div className="overflow-x-auto">
                    <table className="min-w-full bg-white dark:bg-gray-950 border border-gray-300">
                        <thead className="bg-gray-100 dark:bg-gray-950">
                        <tr>
                            <th className="px-4 py-3 border-b">Name</th>
                            <th className="px-4 py-3 border-b">Phone</th>
                            <th className="px-4 py-3 border-b">Address</th>
                            <th className="px-4 py-3 border-b">PinCode</th>
                            <th className="px-4 py-3 border-b">Wallet</th>
                            <th className="px-4 py-3 border-b">User Type</th>
                            <th className="px-4 py-3 border-b">Status</th>
                            <th className="px-4 py-3 border-b">Action</th>
                            <th className="px-4 py-3 border-b">Info</th>
                        </tr>
                        </thead>
                        <tbody>
                        {getAllUsersData === null ? (
                            <tr>
                                <td colSpan={9} className="px-4 py-3 text-center">
                                    No admin users found.
                                </td>
                            </tr>
                        ) : (
                            getAllUsersData?.data.map((user: any, index: number) => (
                                <tr key={index} className="hover:bg-gray-50 dark:hover:bg-gray-800">
                                    <td className="px-4 py-3 border-b text-center">{user.name}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.phone}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.address.completeAddress}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.address.pinCode}</td>
                                    <td className="px-4 py-3 border-b text-center">{(user.wallet).toFixed(2)}</td>
                                    <td className="px-4 py-3 border-b text-center">{user.userType}</td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <span
                                            className={`font-semibold ${user.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>
                                            {user.status === 'active' ? 'Active' : 'Inactive'}
                                        </span>
                                    </td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            className={`font-semibold rounded-full px-2 text-center text-white ${user.status === 'active' ? 'bg-red-600' : 'bg-green-600'} hover:text`}
                                            onClick={() => handleStatusUpdate(user._id, user.status)}
                                        >
                                            {user.status === 'active' ? 'Deactivate' : 'Activate'}
                                        </button>
                                    </td>
                                    <td className="px-4 py-3 border-b text-center">
                                        <button
                                            className="bg-gray-600 font-semibold rounded-full px-2 text-white hover:text-green-900"
                                            onClick={() => handleInfoClick(user._id)}
                                        >
                                            Info
                                        </button>
                                    </td>
                                </tr>
                            ))
                        )}
                        </tbody>
                    </table>
                </div>

                <div className="block sm:hidden mt-6">
                    <Pagination totalPages={getAllUsersData?.totalPages} currentPage={currentPage}
                                onPageChange={handlePageChange}/>
                </div>
                <div className="hidden sm:block mt-6">
                    <PaginationTwo totalPages={getAllUsersData?.totalPages} currentPage={currentPage}
                                   onPageChange={handlePageChange}/>
                </div>
            </div>
        </div>
    );
}
