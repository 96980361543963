import React, {useState} from 'react';
import withAuth from './withAuth';
import SideBar from "../app/components/common/SideBar/SideBar";
import NavBar from "../app/components/common/NavBar/NavBar";
import Footer from "../app/components/common/Footer/Footer";
import {SocketProvider} from "../context/SocketContext";
import TaskSidebar from "../app/components/common/SideBar/TaskSideBar";

const PrivateRoute = ({component: Component, urlId}: {
    component: React.ComponentType<any>,
    urlId: string
}) => {
    const WrappedComponent = withAuth(Component, urlId);
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const [isTaskSidebarOpen, setIsTaskSidebarOpen] = useState(false); // State to control TaskSidebar visibility

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
    };

    const toggleTaskSidebar = () => {
        setIsTaskSidebarOpen(!isTaskSidebarOpen);
    };

    return (
        <SocketProvider>
            <div className="flex">
                <SideBar isOpen={isSidebarOpen} toggleSidebar={toggleSidebar}/>
                <div className={`flex-grow transition-all duration-300 ${isSidebarOpen ? 'ml-64' : 'sm:ml-16'}`}>
                    <div className={`${isSidebarOpen ? '' : 'ml-6 sm:ml-0'}`}>
                        <NavBar toggleTaskbar={toggleTaskSidebar}/>
                    </div>
                    {isTaskSidebarOpen && (
                        <div className="fixed right-0 top-0 bg-gray-100 dark:bg-gray-800 shadow-lg z-50 mt-12 sm:mt-0">
                            <TaskSidebar toggleTaskbar={toggleTaskSidebar} />
                        </div>
                    )}
                    <div className="flex p-4 min-h-screen">
                        <div className="flex-grow p-4">
                            <WrappedComponent />
                            <Footer />
                        </div>
                    </div>
                </div>
            </div>
        </SocketProvider>
    );
};

export default PrivateRoute;
