import validateEmail from "../../EmailValidation";

export const ForgotPasswordFormValidation = (formData: { email: string }): string | null => {
    if (!formData.email) {
        return 'All fields are required.';
    }

    if (!validateEmail(formData.email)) {
        return 'Please enter a valid email address.';
    }

    return null;
}

export const SetPasswordFormValidation = (formData: { password: string, confirmPassword: string, token: any }): string | null => {
    const { password, confirmPassword, token } = formData;

    // Check if all fields are filled
    if (!password || !confirmPassword || !token) {
        return 'All fields are required.';
    }

    // Check if password length is sufficient
    if (password.length < 6) {
        return 'Password must be at least 6 characters long.';
    }

    // Check if passwords match
    if (password !== confirmPassword) {
        return 'Passwords do not match.';
    }

    // Optional: Check password complexity
    const passwordComplexityRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/;
    if (!passwordComplexityRegex.test(password)) {
        return 'Password must include at least one uppercase letter, one number, and one special character. Example: Jhon@123';
    }

    return null;
};
