import React, { useState } from 'react';
import { BiExitFullscreen, BiFullscreen } from 'react-icons/bi';
const FullScreen: React.FC = () => {
  const [fullScreen, setFullScreen] = useState(false);

  const handleToggle = () => {
    if (!fullScreen) {
      enterFullScreen();
    } else {
      exitFullScreen();
    }
  };

  const enterFullScreen = () => {
    const element = document.documentElement; // Fullscreen the whole page

    if (element.requestFullscreen) {
      element.requestFullscreen();
    }

    setFullScreen(true);
  };

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }

    setFullScreen(false);
  };

  return (
    <button onClick={handleToggle} className="p-2 bg-gray-200 dark:bg-gray-700 rounded">
      {!fullScreen ? (
        <BiFullscreen className="text-black dark:text-white" />
      ) : (
        <BiExitFullscreen className="text-blue-500 dark:text-white" />
      )}
    </button>
  );
};

export default FullScreen;
