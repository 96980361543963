import React, {useEffect, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import {getAuthToken} from "../helper/getAuthToken";
import Cookies from "js-cookie";
import Loading from "./Loading";

let API_URL: string;

if (process.env.REACT_APP_ENV === 'development') {
    API_URL = process.env.REACT_APP_DEV_API as string;
} else {
    API_URL = process.env.REACT_APP_PRO_API as string;
}
const HomeRedirect: React.FC = () => {
    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState<boolean | null>(null); // Use null for loading state

    useEffect(() => {
        const verifyToken = async () => {
            const token = getAuthToken(); // Use utility function to get auth token

            if (token) {
                try {
                    const url = `${API_URL}admin/auth/validate-login-token`;
                    const response = await fetch(url, {
                        method: 'GET',
                        headers: {
                            'Authorization': `Bearer ${token}`,
                        }
                    });

                    if (response.ok && response.status === 200) {
                        setIsAuthenticated(true);
                    } else {
                        setIsAuthenticated(false);
                        sessionStorage.removeItem('authToken');
                        Cookies.remove('authToken');
                    }
                } catch (error) {
                    console.error('Error validating token:', error);
                    setIsAuthenticated(false);
                }
            } else {
                setIsAuthenticated(false);
            }
        };

        verifyToken();
    }, []);

    useEffect(() => {
        if (isAuthenticated === true) {
            navigate('/dashboard');
        } else if (isAuthenticated === false) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    // Show loading indicator while isAuthenticated is null
    if (isAuthenticated === null) {
        return <Loading />;
    }

    // Return null if navigation is handled by useEffect
    return null;
};

export default HomeRedirect;
