import {Dispatch} from "redux";
import {
    ADD_NEW_ADMIN_USER_FAIL,
    ADD_NEW_ADMIN_USER_SUCCESS,
    ADD_USER_TYPES_FAIL,
    ADD_USER_TYPES_SUCCESS, GET_ADMIN_ACTIVITY_FAIL,
    GET_ADMIN_ACTIVITY_SUCCESS,
    GET_ADMIN_USER_INFO_BY_ID_FAIL,
    GET_ADMIN_USER_INFO_BY_ID_SUCCESS,
    GET_ALL_ADMIN_USER_FAIL,
    GET_ALL_ADMIN_USER_SUCCESS,
    GET_ALL_USER_TYPE_PERMISSION_FAIL,
    GET_ALL_USER_TYPE_PERMISSION_SUCCESS,
    GET_ALL_USER_TYPES_FAIL,
    GET_ALL_USER_TYPES_SUCCESS, GET_DASHBOARD_DATA_FAIL, GET_DASHBOARD_DATA_SUCCESS,
    GET_USER_TYPES_FAIL,
    GET_USER_TYPES_SUCCESS,
    SAVE_USER_TYPE_PERMISSION_FAIL,
    SAVE_USER_TYPE_PERMISSION_SUCCESS,
    UPDATE_ADMIN_USER_INFO_FAIL,
    UPDATE_ADMIN_USER_INFO_SUCCESS,
    UPDATE_ADMIN_USER_STATUS_FAIL,
    UPDATE_ADMIN_USER_STATUS_SUCCESS,
    UPDATE_STATUS_USER_TYPES_FAIL,
    UPDATE_STATUS_USER_TYPES_SUCCESS
} from "./types";
import {
    apiAddAdminUser,
    apiAddUserTypes,
    apiGetAdminsActivity,
    apiGetAdminUserInfoById,
    apiGetAllAdminUsers,
    apiGetAllUserTypesPermission,
    apiGetDashboardData,
    apiGetUserTypes,
    apiGetUserTypesList,
    apiSaveUserTypePermission,
    apiUpdateAdminUser,
    apiUpdateStatusAdminUser,
    apiUpdateStatusUserTypes
} from "../../../api";

export const addUserTypes = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiAddUserTypes(data);
        console.log('store', response);
        dispatch({ type: ADD_USER_TYPES_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: ADD_USER_TYPES_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getUserTypes = (page: number, limit: number) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetUserTypes(page, limit);
        console.log('store', response);
        dispatch({ type: GET_USER_TYPES_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_USER_TYPES_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllUserTypes = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetUserTypesList();
        console.log('store', response);
        dispatch({ type: GET_ALL_USER_TYPES_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_USER_TYPES_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const updateUserTypes = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiUpdateStatusUserTypes(data);
        console.log('store', response);
        dispatch({ type: UPDATE_STATUS_USER_TYPES_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: UPDATE_STATUS_USER_TYPES_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const saveUserTypesPermission = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiSaveUserTypePermission(data);
        console.log('store', response);
        dispatch({ type: SAVE_USER_TYPE_PERMISSION_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: SAVE_USER_TYPE_PERMISSION_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllUserTypesPermission = (page: number, limit: number) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAllUserTypesPermission(page, limit);
        console.log('store', response);
        dispatch({ type: GET_ALL_USER_TYPE_PERMISSION_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_USER_TYPE_PERMISSION_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const addAdminUser = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiAddAdminUser(data);
        console.log('store', response);
        dispatch({ type: ADD_NEW_ADMIN_USER_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: ADD_NEW_ADMIN_USER_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAllAdminUser = (page: number, limit: number, search: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAllAdminUsers(page, limit, search);
        console.log('store', response);
        dispatch({ type: GET_ALL_ADMIN_USER_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ALL_ADMIN_USER_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const updateAdminUserStatus = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiUpdateStatusAdminUser(data);
        console.log('store', response);
        dispatch({ type: UPDATE_ADMIN_USER_STATUS_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: UPDATE_ADMIN_USER_STATUS_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const updateAdminUser = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiUpdateAdminUser(data);
        console.log('store', response);
        dispatch({ type: UPDATE_ADMIN_USER_INFO_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: UPDATE_ADMIN_USER_INFO_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAdminUserInfoById = (data: any) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAdminUserInfoById(data);
        console.log('store', response);
        dispatch({ type: GET_ADMIN_USER_INFO_BY_ID_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ADMIN_USER_INFO_BY_ID_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};

export const getAdminsActivities = (page: number, limit: number, search: string) => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetAdminsActivity(page, limit, search);
        console.log('store', response);
        dispatch({ type: GET_ADMIN_ACTIVITY_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_ADMIN_ACTIVITY_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};


export const getDashboardData = () => async (dispatch: Dispatch) => {
    try {
        const response = await apiGetDashboardData();
        console.log('store', response);
        dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: { success: true, data: response } });
        return { status: 200, data: response };
    } catch (error: any) {
        const errorMessage = error.response ? error.response.data.message : 'An error occurred';
        const status = error.response ? error.response.status : 500;
        console.log('errorMessage', errorMessage);
        console.log('error', error);
        dispatch({ type: GET_DASHBOARD_DATA_FAIL, payload: { error: errorMessage } });
        return { status, message: errorMessage };
    }
};
