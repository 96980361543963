import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

const NotFound: React.FC = () => {
    const navigate = useNavigate();

    const handleBackToHome = () => {
        navigate('/');
    };

    return (
        <div className="flex items-center justify-center min-h-screen bg-gray-100 dark:bg-gray-950 p-4">
            <div className="flex flex-col justify-center text-center">
                <h1 className="text-6xl font-bold text-red-600">404</h1>
                <p className="text-2xl text-gray-600 dark:text-white mt-4">Page Not Found</p>
                <p className="text-gray-500 dark:text-white mt-2">Sorry, we couldn't find the page you're looking for.</p>
                <button
                    onClick={handleBackToHome}
                    className="mt-6 text-black-500 flex items-center justify-center hover:text-gray-700 transition-colors"
                >
                    <FiArrowLeft className="mr-2" size={20} />
                    Back to Home
                </button>
            </div>
        </div>
    );
};

export default NotFound;
