import React from 'react';
import { IconType } from 'react-icons';

interface InfoBoxProps {
    icon: IconType;
    label: string;
    value: number | string;
}

const InfoBox: React.FC<InfoBoxProps> = ({ icon: Icon, label, value }) => {
    return (
        <div className="flex flex-col items-center p-4 border border-gray-300 rounded-lg shadow-md m-2 w-[250px]">
            <Icon size={40} className="mb-2" />
            <h2 className="text-xl font-semibold mb-2">{label}</h2>
            <p className="text-lg">{value}</p>
        </div>
    );
};

export default InfoBox;
