import React, {useEffect, useState} from 'react';
import {FaEye, FaEyeSlash} from 'react-icons/fa';
// import COUNTRY_CODES from "../../../helper/Countrycodes";
// import getPhoneLength from "../../../helper/getPhoneNumberLength";
import {useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changePassword, getProfileDetails} from "../../../redux/actions/auth/authActions";
import {IoIosArrowForward} from "react-icons/io";
import {ChangePasswordValidation} from "../../../helper/validation";
import {toast} from "react-toastify";
import Swal from "sweetalert2";
import Cookies from "js-cookie";

const ProfileDetails: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch<any>();
    const { userProfileData } = useSelector((state: any) => state.auth);
    // const [userData, setUserData] = useState({
    //     name: '',
    //     countryCode: '',
    //     phone: '',
    //     dob: '',
    //     address: {
    //         country: '',
    //         state: '',
    //         city: '',
    //         pinCode: '',
    //     },
    // });
    const [passwordData, setPasswordData] = useState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: '',
    });
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [updating, setUpdating] = useState(false);

    // useEffect(() => {
    //     if (userProfileData) {
    //         setUserData({
    //             name: userProfileData?.user.name || 'John Doe',
    //             countryCode: userProfileData?.user.countryCode || '+91',
    //             phone: userProfileData?.user.phone || '',
    //             dob: userProfileData?.user.dob || '',
    //             address: userProfileData?.user.address || {
    //                 country: '',
    //                 state: '',
    //                 city: '',
    //                 pinCode: '',
    //             },
    //         });
    //     }
    // }, [userProfileData]);

    useEffect(() => {
        dispatch(getProfileDetails());
    }, [dispatch]);

    // const handleUpdateDetails = async (event: React.FormEvent) => {
    //     event.preventDefault();
    //
    //     const validationMessage = UpdateProfileDetailValidation(userData);
    //     if (validationMessage) {
    //         toast.error(validationMessage);
    //         return;
    //     }
    //
    //     setUpdating(true);
    //
    //     try {
    //         // Use the correct action for updating profile details
    //         const response = await dispatch(updateProfileDetails(userData));
    //         console.log("Response", response);
    //
    //         // Check response status
    //         if (response.status === 200) {
    //             const getResponse = await dispatch(getProfileDetails());
    //             if (getResponse.status === 200) {
    //                 await Swal.fire('Success', 'Your profile details have been updated successfully.', 'success');
    //             } else {
    //                 toast.error(`Failed to retrieve updated profile details: ${getResponse.message}`);
    //             }
    //         } else {
    //             toast.error(`Failed to update profile details: ${response.message}`);
    //         }
    //     } catch (error) {
    //         console.error('Error submitting request:', error);
    //         toast.error('An error occurred while updating profile details. Please try again.');
    //     } finally {
    //         setUpdating(false);
    //     }
    // };

    const handleChangePassword = async (event: React.FormEvent) => {
        event.preventDefault();

        const validationMessage = ChangePasswordValidation(passwordData);
        if (validationMessage) {
            toast.error(validationMessage);
            return;
        }

        setUpdating(true);

        try {
            const response = await dispatch(changePassword(passwordData));
            console.log("Response", response);

            if (response.status === 200) {
                sessionStorage.removeItem('authToken');
                Cookies.remove('authToken');
                await Swal.fire('Success', 'Password updated successfully.', 'success');
                navigate('/login');
            } else {
                toast.error(`Failed to update password: ${response.message}`);
            }
        } catch (error) {
            console.error('Error submitting request:', error);
            toast.error('An error occurred while changing the password. Please try again.');
        } finally {
            setUpdating(false);
        }
    };

    // const phoneLength = getPhoneLength(userData.countryCode);

    const formatDateTime = (dateString: string) => {
        if (!dateString) return '';
        const date = new Date(dateString);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        const hours = String(date.getHours()).padStart(2, '0');
        const minutes = String(date.getMinutes()).padStart(2, '0');
        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };

    return (
        <div className="min-h-screen bg-gray-100 dark:bg-black p-4">
            <div className="max-w-7xl mx-auto">
                <div className="flex justify-between items-center mb-4">
                    <h1 className="text-3xl font-bold ">Profile Details</h1>
                </div>

                <div>
                    <div className="sm:flex sm:justify-between mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">
                        <p className="font-bold">Phone: {userProfileData?.user.phone}</p>
                        <p className="font-bold">Role: {userProfileData?.user.userType}</p>
                        <p className="font-bold">PinCode: {userProfileData?.user.address.pinCode}</p>
                        <p className="font-bold">Join: {formatDateTime(userProfileData?.user.createdAt)}</p>
                        <p className="flex font-bold">Status: <span
                            className={`ml-2 ${userProfileData?.user.status === 'active' ? 'text-green-600' : 'text-red-600'}`}>{userProfileData?.user.status === 'active' ? 'Active' : 'Blocked'}</span>
                        </p>
                    </div>

                    {/* Update Details */}
                    {/*<div className="mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">*/}
                    {/*    <form onSubmit={handleUpdateDetails}>*/}
                    {/*        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Name</label>*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    value={userData.name}*/}
                    {/*                    onChange={(e) => setUserData({...userData, name: e.target.value})}*/}
                    {/*                    className="w-full p-2 border dark:bg-gray-950 border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Country Code</label>*/}
                    {/*                <select*/}
                    {/*                    name="countryCode"*/}
                    {/*                    value={userData.countryCode}*/}
                    {/*                    onChange={(e) => setUserData({...userData, countryCode: e.target.value})}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                >*/}
                    {/*                    {COUNTRY_CODES.map(({code, country, flag}) => (*/}
                    {/*                        <option key={code} value={code}>{`${code} (${country}) ${flag}`}</option>*/}
                    {/*                    ))}*/}
                    {/*                </select>*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Phone</label>*/}
                    {/*                <input*/}
                    {/*                    type="tel"*/}
                    {/*                    id="phone"*/}
                    {/*                    name="phone"*/}
                    {/*                    value={userData.phone}*/}
                    {/*                    onChange={(e) => setUserData({...userData, phone: e.target.value})}*/}
                    {/*                    required*/}
                    {/*                    minLength={phoneLength}*/}
                    {/*                    maxLength={phoneLength}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                    placeholder="9812805704"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Date of Birth</label>*/}
                    {/*                <input*/}
                    {/*                    type="date"*/}
                    {/*                    value={userData.dob}*/}
                    {/*                    onChange={(e) => setUserData({...userData, dob: e.target.value})}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Country</label>*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    value={userData.address.country}*/}
                    {/*                    onChange={(e) => setUserData({*/}
                    {/*                        ...userData,*/}
                    {/*                        address: {...userData.address, country: e.target.value}*/}
                    {/*                    })}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">State</label>*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    value={userData.address.state}*/}
                    {/*                    onChange={(e) => setUserData({*/}
                    {/*                        ...userData,*/}
                    {/*                        address: {...userData.address, state: e.target.value}*/}
                    {/*                    })}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">City</label>*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    value={userData.address.city}*/}
                    {/*                    onChange={(e) => setUserData({*/}
                    {/*                        ...userData,*/}
                    {/*                        address: {...userData.address, city: e.target.value}*/}
                    {/*                    })}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*            <div>*/}
                    {/*                <label className="block">Pin Code</label>*/}
                    {/*                <input*/}
                    {/*                    type="text"*/}
                    {/*                    value={userData.address.pinCode}*/}
                    {/*                    onChange={(e) => setUserData({*/}
                    {/*                        ...userData,*/}
                    {/*                        address: {...userData.address, pinCode: e.target.value}*/}
                    {/*                    })}*/}
                    {/*                    className="w-full p-2 dark:bg-gray-950 border border-gray-300 rounded"*/}
                    {/*                />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*        <div className="flex justify-end">*/}
                    {/*            <button type="submit" disabled={updating} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">*/}
                    {/*                {updating ? 'Updating...' : 'Update Details' }*/}
                    {/*            </button>*/}
                    {/*        </div>*/}
                    {/*    </form>*/}
                    {/*</div>*/}
                </div>

                {/* 2FA Section */}
                <div className="mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">
                    <h2 className="text-2xl font-semibold mb-4">Two-Factor Authentication</h2>
                    {!userProfileData?.user.twoFactorEnabled ? (
                        <div className="flex flex-col justify-end sm:flex-row gap-4">
                            <button onClick={() => navigate('/authenticator')}
                                    className="px-4 py-2 bg-green-600 text-white rounded">
                                Enable 2FA
                            </button>
                        </div>
                    ) : (
                        <div className="sm:flex sm:justify-between" onClick={() => navigate('/authenticator')}>
                            <p className="mb-4">Authenticator</p>
                            <p className="mb-4">{formatDateTime(userProfileData?.user.twoFactorEnabledDate)}</p>
                            <div className="flex flex-col sm:flex-row gap-4">
                                <IoIosArrowForward
                                    className="mr-2 text-2xl font-bold"/>
                            </div>
                        </div>
                    )}
                </div>


                <div className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                    {/* Change Password */}
                    <div className="mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">
                        <h2 className="text-2xl font-semibold mb-4">Change Password</h2>
                        <form onSubmit={handleChangePassword}>
                            <div className="grid grid-cols-1 gap-4">
                                <div className="relative">
                                    <label className="block">Old Password</label>
                                    <div className="relative">
                                        <input
                                            type={showOldPassword ? "text" : "password"}
                                            value={passwordData.oldPassword}
                                            onChange={(e) => setPasswordData({...passwordData, oldPassword: e.target.value})}
                                            className="w-full p-2 border dark:bg-gray-950 border-gray-300 rounded pr-10"
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                        >
                                            {showOldPassword ? <FaEyeSlash/> : <FaEye/>}
                                        </button>
                                    </div>
                                </div>
                                <div className="relative">
                                    <label className="block">New Password</label>
                                    <div className="relative">
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            value={passwordData.newPassword}
                                            onChange={(e) => setPasswordData({...passwordData, newPassword: e.target.value})}
                                            className="w-full p-2 border dark:bg-gray-950 border-gray-300 rounded pr-10"
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <FaEyeSlash/> : <FaEye/>}
                                        </button>
                                    </div>
                                </div>
                                <div className="relative">
                                    <label className="block">Confirm New Password</label>
                                    <div className="relative">
                                        <input
                                            type={showConfirmPassword ? "text" : "password"}
                                            value={passwordData.confirmPassword}
                                            onChange={(e) => setPasswordData({...passwordData, confirmPassword: e.target.value})}
                                            className="w-full p-2 border dark:bg-gray-950 border-gray-300 rounded pr-10"
                                        />
                                        <button
                                            type="button"
                                            className="absolute inset-y-0 right-0 pr-3 flex items-center "
                                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                        >
                                            {showConfirmPassword ? <FaEyeSlash/> : <FaEye/>}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-end">
                                <button type="submit" disabled={updating} className="mt-4 px-4 py-2 bg-blue-600 text-white rounded">
                                    {updating ? 'Updating...' : 'Update Password'}
                                </button>
                            </div>
                        </form>
                    </div>

                    {/* Activity Details */}
                    <div className="mb-6 p-4 bg-white dark:bg-gray-950 dark:border-2 border-white rounded shadow-sm">
                        <h2 className="text-2xl font-semibold mb-4">Activity Details</h2>
                        <div>
                            {userProfileData?.loginData.map((activity: any, index: number) => (
                                <div key={index}
                                     className="flex justify-between border border-gray-300 p-4 rounded mb-4">
                                    <p>Sign-in successful from {activity.device}</p>
                                    <p>{formatDateTime(activity.createdAt)}</p>
                                    <p className={`ml-2 font-bold ${activity.status === true ? 'text-green-600' : 'text-red-600'}`}>
                                        {activity.status === true ? 'Active' : 'Inactive'}
                                    </p>
                                </div>
                            ))}
                            <div className="flex justify-end">
                                <button onClick={() => navigate('/login-activity')} className="px-4 py-2 bg-blue-600 text-white rounded">
                                    View All
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProfileDetails;
