import React from 'react';

const questions = [
    { id: 1, text: 'How to reset password?' },
    { id: 2, text: 'How to update profile?' },
    { id: 3, text: 'How to contact support?' },
    { id: 4, text: 'Where to find user guide?' },
    { id: 5, text: 'How to configure settings?' },
];

const Questions: React.FC = () => {
    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">Frequently Asked Questions</h1>
            <ul className="space-y-4">
                {questions.map(question => (
                    <li key={question.id} className="bg-white shadow-md rounded-lg p-4 hover:bg-gray-50 transition duration-300">
                        <h2 className="text-xl font-semibold">{question.text}</h2>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default Questions;
