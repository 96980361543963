import React, { useState } from 'react';

const initialTasks = [
    { id: 1, text: 'Complete project documentation', isCompleted: false, date: new Date(2024, 6, 10) },
    { id: 2, text: 'Review code changes', isCompleted: true, date: new Date(2024, 6, 11) },
    { id: 3, text: 'Attend team meeting', isCompleted: false, date: new Date(2024, 6, 12) },
    { id: 4, text: 'Update project roadmap', isCompleted: true, date: new Date(2024, 6, 13) },
    { id: 5, text: 'Test new features', isCompleted: false, date: new Date(2024, 6, 14) },
];

const TaskPage: React.FC = () => {
    const [tasks, setTasks] = useState(initialTasks);

    const toggleTaskCompletion = (taskId: number) => {
        setTasks(tasks.map(task =>
            task.id === taskId ? { ...task, isCompleted: !task.isCompleted } : task
        ));
    };

    const sortedTasks = tasks.sort((a, b) => {
        return a.isCompleted === b.isCompleted ? 0 : a.isCompleted ? 1 : -1;
    });

    return (
        <div className="container mx-auto p-6">
            <h1 className="text-3xl font-bold mb-4">Tasks</h1>
            <ul className="space-y-4">
                {sortedTasks.map(task => (
                    <li key={task.id} className="bg-white shadow-md rounded-lg p-4 hover:bg-gray-50 transition duration-300">
                        <div className="flex justify-between items-center">
                            <span className={`${task.isCompleted ? 'line-through text-gray-400' : ''} text-xl font-semibold`}>{task.text}</span>
                            <button
                                onClick={() => toggleTaskCompletion(task.id)}
                                className="bg-blue-500 text-white px-3 py-1 rounded-md hover:bg-blue-600 transition duration-300"
                            >
                                {task.isCompleted ? 'Undo' : 'Complete'}
                            </button>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default TaskPage;
